import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { PaymentSource } from "@packages/client";
import type { RootState } from "Store/configureStore";

export const getPaymentSource = createAsyncThunk(
  "app/paymentSource",
  async () => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const paymentSource = await client.getPaymentSource();
    return paymentSource;
  }
);

export type PaymentSourceState = Readonly<{
  data: PaymentSource | undefined;
  loading: boolean | "idle";
  errors?: Error | string;
}>;

const initialState: PaymentSourceState = {
  data: undefined,
  loading: "idle",
  errors: ""
};

const paymentSource = createSlice({
  name: "paymentSource",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPaymentSource.pending, state => {
      state.loading = true;
    });

    builder.addCase(getPaymentSource.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });

    builder.addCase(getPaymentSource.rejected, (state, action) => {
      state.errors = action.error.message;
      state.loading = false;
    });
  }
});

export default paymentSource.reducer;

export const paymentSourceSelector = (state: RootState) =>
  state.paymentSource.data;

export const paymentSourceLoadingSelector = (state: RootState) =>
  state.paymentSource.loading;
