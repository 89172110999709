import { createAsyncThunk } from "@reduxjs/toolkit";

import { patchSubscription } from "Api/organizations/subscription/patchSubscription.request";
import logger from "Libs/logger";
import { selectUserProjectById } from "Reducers/app/selectors";
import { getFeatureFlag } from "Reducers/featureFlags";
import { organizationsSelector } from "Reducers/organization";
import {
  UpdateProjectType,
  UpdateProjectReturnPayload,
  gitProjectSelector
} from "Reducers/project/project";

import type { AsyncThunkOptionType } from "Reducers/types";

export const updateProject = createAsyncThunk<
  UpdateProjectReturnPayload,
  UpdateProjectType,
  AsyncThunkOptionType
>(
  "app/project_reducer/update",
  async (
    {
      organizationDescriptionId,
      projectDescriptionId,
      data,
      shouldPreferUpdateThroughSubscription = false
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const projectMe = selectUserProjectById(getState(), {
        projectId: projectDescriptionId
      });

      const project = gitProjectSelector(getState(), {
        organizationId: organizationDescriptionId,
        projectId: projectDescriptionId
      });

      if (!project) {
        return rejectWithValue("project does not exist");
      }

      const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");
      const updateProjectThroughtSubscriptionEnabled = getFeatureFlag(
        "ENABLE_UPDATE_PROJECT_THROUGH_SUBSCRIPTION"
      );
      const organizations = organizationsSelector(getState());
      let projectResult = project;

      if (
        organizationEnabled &&
        updateProjectThroughtSubscriptionEnabled &&
        shouldPreferUpdateThroughSubscription
      ) {
        await patchSubscription({
          organizationId: project.organization_id,
          subscriptionId: project.subscription_id,
          data: { ...data, project_title: (data as { title: string }).title }
        });
      } else {
        projectResult = (await project.update(data)).getEntity();
      }

      return {
        project: projectResult,
        organizations,
        projectMe
      };
    } catch (err) {
      logger(err, {
        action: "project_update",
        organizationDescriptionId,
        projectDescriptionId,
        data
      });

      return rejectWithValue(err);
    }
  }
);
