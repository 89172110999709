import localForage from "localforage";

// eslint-disable-next-line no-restricted-imports
import vendorThemes from "../../vendor_resources/theme.json";

export async function getColorMode() {
  const params = new URLSearchParams(window.location.search);
  const colorModeFromCache = await localForage.getItem("ui_colorscheme");

  const colorMode =
    params.get("theme") || params.get("mode") || colorModeFromCache || "light";

  return colorMode;
}

export function setCurrentTheme(modeName = "light") {
  const html = document.querySelector("html");
  html?.setAttribute("data-theme", modeName);
}

export async function setupTheme() {
  let mode = await getColorMode();

  setCurrentTheme(mode);

  if (mode === "contrast") {
    mode = "light";
  }
}

export const ICON = "icon";
export const INPUT = "input";

/**
 * @param {string|undefined} componentName
 * @param {string} componentType
 * @param {string|undefined} cssProperty
 * @param {string} fallback
 * @returns {string}
 */
function generateColorVariableNames(
  componentName,
  componentType,
  cssProperty,
  fallback
) {
  let variableNames = [`${componentType}-${fallback}`, `${fallback}`];

  if (cssProperty) {
    variableNames.unshift(`${componentType}-${fallback}-${cssProperty}`);
  }

  if (componentName) {
    variableNames.unshift(`${componentName}-${componentType}-${cssProperty}`);
  }

  return variableNames;
}

/**
 * @param {string|undefined} componentName
 * @param {string} componentType
 * @param {string|undefined} cssProperty
 * @param {string} fallback
 * @returns {string}
 */
// DO NOT USE THIS FUNCTION, USE getCSSVarString INSTEAD
// This function is only here for legacy code
export const getLegacyColor = (
  componentName,
  componentType,
  cssProperty,
  fallback
) => {
  const variableNames = generateColorVariableNames(
    componentName,
    componentType,
    cssProperty,
    fallback
  );

  const style = getComputedStyle(document.documentElement);
  const availableVar = variableNames.find(v =>
    style.getPropertyValue(`--${v}`)
  );

  return style.getPropertyValue(`--${availableVar}`);
};

const url =
  typeof window !== "undefined" ? new URL(window.location.href) : null;

const themeJSONString = url?.searchParams?.get("themePreview");

/**
 * @param {string} themeParam
 * @returns {any}
 */
const isValidJson = themeParam => {
  try {
    JSON.parse(themeParam);
  } catch (e) {
    return null;
  }
  return JSON.parse(themeParam);
};

/**
 * @param {any} themeParam
 * @returns {any}
 */
const isValidAssetJson = themeParam => {
  try {
    const paramString = JSON.stringify(themeParam);
    if (paramString !== "") {
      const paramParsed = JSON.parse(paramString);
      return paramParsed;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

const vendorConfigFinal = isValidAssetJson(vendorThemes);
const themesFinal = isValidJson(themeJSONString ?? "");

/**
 * @param {Object.<string, string>=} theme
 * @returns {string}
 */
const parseRulesToCSS = theme => {
  let rules = "";
  if (theme) {
    Object.entries(theme)?.forEach(([k, v]) => {
      rules += `--${k}: ${v};\n`;
    });
  }
  return rules;
};

/**
 * @param {Object.<string, string>=} theme
 * @returns {Object.<string, string>}
 */
const parseThemeRulesToCSS = theme => {
  const rules = { light: "", dark: "", contrast: "" };
  if (Object.keys(theme).length === 0) {
    return null;
  }

  Object.keys(rules).forEach(mode => {
    if (!theme[mode]) return;
    Object.entries(theme[mode])?.forEach(([k, v]) => {
      rules[mode] += `--${k}: ${v};\n`;
    });
  });
  return rules;
};

export const vendorPreviewTheme = parseRulesToCSS({
  ...themesFinal?.variables,
  ...themesFinal?.themes
});

export const vendorAssetTheme = parseThemeRulesToCSS({
  ...vendorConfigFinal
});
