import { OrganizationSubscription } from "@packages/client";

import { apiProxyRequest } from "Api/apiProxyRequest";

type OrganizationSubscriptionPatchPayload = Partial<{
  timezone: string;
  project_title: string;
}>;

export const patchSubscription = ({
  organizationId,
  subscriptionId,
  data
}: {
  organizationId: string;
  subscriptionId: string;
  data: OrganizationSubscriptionPatchPayload;
}) =>
  apiProxyRequest<OrganizationSubscription>(
    `/organizations/${organizationId}/subscriptions/${subscriptionId}`,
    "PATCH",
    data
  );
