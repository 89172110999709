import React from "react";
import { Button } from "..";
import type { AnchorProps, RouterLinkProps } from "..";

export type ButtonLinkComponentProps = (
  | (Omit<RouterLinkProps, "to"> & { to: string })
  | (Omit<AnchorProps, "href"> & { href: string })
) & {
  as?: React.ComponentType<any>;
};

/**
 * This component should be used when the click action will bring the user to a
 * new URL.
 *
 * Based on the `to` or `href` properties will render a React Router DOM link or
 * a regular HTML anchor.
 *
 */
export const Link = React.forwardRef<
  HTMLAnchorElement,
  ButtonLinkComponentProps
>((props, ref) => {
  return <Button ref={ref} variant={props.variant || "link"} {...props} />;
});

Link.displayName = "LinkButton";
