import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSetupCatalogRequest } from "Api/organizations/setupCatalog/getSetupCatalog.request";
import logger from "Libs/logger";

export type GetSetupCatalogThunkArguments = {
  organizationId: string;
};

export const getSetupCatalogThunk = createAsyncThunk(
  "setup/catalog",
  ({ organizationId }: GetSetupCatalogThunkArguments, { rejectWithValue }) => {
    try {
      return getSetupCatalogRequest({ organizationId });
    } catch (error) {
      logger(error, { action: "setup_load_catalog" });

      return rejectWithValue(error);
    }
  }
);
