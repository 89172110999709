import logger from "Libs/logger";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import type { Variable } from "@packages/client";

export type EnvironmentVariableData = {
  id: string;
  name: string;
  value: string;
  is_json: boolean;
  is_enabled: boolean;
  is_inheritable: boolean;
  is_sensitive: boolean;
  visible_build: boolean;
  visible_runtime: boolean;
};

type UpdateEnvironmentVariableThunkPropType = {
  environmentId: string;
  projectId: string;
  organizationId: string;
  variable: Variable;
  data: EnvironmentVariableData;
};

export const updateVariable = createAppAsyncThunk(
  "app/project/environment/variable/update",
  async (
    props: UpdateEnvironmentVariableThunkPropType,
    { rejectWithValue }
  ) => {
    const { environmentId, projectId, variable, data } = props;
    const fields = Object.assign(
      {},
      { projectId, environmentId },
      { variableId: variable.id },
      data
    );

    try {
      const result = await variable.update(fields);
      const newVariable: Variable | undefined = await result?.getEntity();
      return newVariable;
    } catch (err: any) {
      logger(err, {
        environmentId,
        projectId,
        action: "environmentUpdateVariable",
        variable,
        data
      });
      return rejectWithValue({ error: err.message });
    }
  }
);
