import { authenticatedRequest } from "../api";
import { getConfig } from "../config";
import { urlParser } from "../urlParser";

import type { FormattedCost } from "./Cost";
import type { APIObject } from "./Ressource";
import { Ressource } from "./Ressource";

const url = "/organizations/:organizationId/prepayment";

export class OrganizationPrepayment extends Ressource {
  organization_id: string;
  balance: FormattedCost;
  last_updated_at: string | null;
  sufficient: boolean;
  fallback: string;

  constructor(prepayment: APIObject, customUrl?: string) {
    const { api_url } = getConfig();

    super(customUrl ?? `${api_url}${url}`, {}, {}, prepayment);

    this.organization_id = prepayment.organization_id;
    this.balance = prepayment.balance;
    this.last_updated_at = prepayment.last_updated_at;
    this.sufficient = prepayment.sufficient;
    this.fallback = prepayment.fallback;
  }

  static async get(
    params: {
      organizationId: string;
    },
    customUrl?: string
  ): Promise<OrganizationPrepayment | undefined> {
    const { api_url } = getConfig();
    const { organizationId, ...queryParams } = params;

    const parsedUrl = urlParser(customUrl ?? `${api_url}${url}`, {
      organizationId
    });

    return authenticatedRequest(parsedUrl, "GET", queryParams).then(
      ({ prepayment }) => {
        if (typeof prepayment === "undefined") return;
        return new OrganizationPrepayment(prepayment);
      }
    );
  }
}
