import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getEnforceMFAStatusThunk } from "./thunks/getEnforceMFAStatus.thunk";

export type EnforceOrganizationMFAState = {
  data: {
    enforce_mfa: boolean;
    // If the current user can enable the feature following this condition:
    // mfa it's disabled, you have access, and you have MFA verified on your session.
    canEnable: boolean;
    // If the current user can disable the feautre. Follows this condition:
    // mfa is enabled and you have access.
    canDisable: boolean;
  };
  isLoading: boolean;
  error?: unknown;
};

export type EnforceMFAState = {
  data: Record<string, EnforceOrganizationMFAState | undefined>;
};

export const initialState: EnforceMFAState = {
  data: {}
};

const slice = createSlice({
  name: "enforceMFA",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEnforceMFAStatusThunk.pending, (state, action) => {
        setDeep(state, ["data", action.meta.arg, "isLoading"], true);
        setDeep(state, ["data", action.meta.arg, "error"], undefined);
      })
      .addCase(getEnforceMFAStatusThunk.fulfilled, (state, action) => {
        setDeep(state, ["data", action.meta.arg, "isLoading"], false);
        setDeep(state, ["data", action.meta.arg, "data"], {
          enforce_mfa: action.payload.enforce_mfa,
          canEnable: !!action.payload._links.enable,
          canDisable: !!action.payload._links.disable
        });
      })
      .addCase(getEnforceMFAStatusThunk.rejected, (state, action) => {
        setDeep(state, ["data", action.meta.arg, "isLoading"], false);
        setDeep(state, ["data", action.meta.arg, "error"], action.error);
      });
  }
});

export const enforceMFAReducer = slice.reducer;
