import { apiProxyRequest as request } from "Api/apiProxyRequest";
import { Settings } from "Reducers/environment/service/types";

export const getAutoscalingConfiguration = async (
  projectId: string,
  environmentId: string
) =>
  request<{ services: Settings }>(
    `/projects/${projectId}/environments/${environmentId}/autoscaling/settings`,
    "GET"
  );
