/*eslint quote-props: ["error", "always"]*/

const links = {
  "links.website": "https://platform.sh",
  "links.documentation.create_apps":
    "https://docs.platform.sh/create-apps.html",
  "links.documentation": "https://docs.platform.sh",
  "links.terms": "https://platform.sh/trust-center/legal/tos/",
  "links.aup": "https://platform.sh/trust-center/legal/aup/",
  "links.tos": "https://platform.sh/tos/",
  "links.privacy_policy":
    "https://platform.sh/trust-center/privacy/privacy-policy/",
  "integration.forward_logs_to_newrelic":
    "Forward logs from your project to New Relic",
  "integration.forward_logs_to": "Forward logs from your project to {name}",
  "links.log_forwarding_documentation":
    "https://docs.platform.sh/increase-observability/logs/forward-logs.html",
  "links.blackfire.pricing": "https://www.blackfire.io/pricing",
  "links.documentation.deploy.commit":
    "https://docs.platform.sh/get-started/deploy/commit.html",
  "links.documentation.define_routes":
    "https://docs.platform.sh/define-routes.html",
  "links.documentation.teams":
    " https://docs.platform.sh/administration/users.html",
  "links.documentation.access":
    "https://docs.platform.sh/administration/web/configure-project.html#access",
  "links.documentation.admin_backups":
    "https://docs.platform.sh/environments/backup.html#backup-schedule",
  "links.documentation.paused_environment":
    "https://docs.platform.sh/environments.html#paused-environments",
  "links.documentation.active_environment":
    "https://docs.platform.sh/environments.html#environment-status",
  "links.documentation.backups_schedule":
    "https://docs.platform.sh/security/data-retention.html#grid-backups",
  "links.documentation.container_configuration":
    "https://docs.platform.sh/configuration/app-containers.html",
  "links.documentation.CLI_API_token":
    "https://docs.platform.sh/administration/cli/api-tokens.html",
  "links.documentation.availability_incident_handling_procedure":
    "https://docs.platform.sh/dedicated/overview/monitoring.html#availability-incident-handling-procedure",
  "links.documentation.getting_started_CLI":
    "https://docs.platform.sh/gettingstarted/cli.html",
  "links.documentation.CLI":
    "https://docs.platform.sh/development/cli.html#cli-command-line-interface",
  "links.documentation.app.variables":
    "https://docs.platform.sh/create-apps/app-reference/single-runtime-image.html#variables",
  "links.documentation.add_ssh_keys":
    "https://docs.platform.sh/development/ssh/ssh-keys.html#add-ssh-keys",
  "links.documentation.create_new_project":
    "https://docs.platform.sh/gettingstarted/own-code/create-project.html#create-a-new-project",
  "links.documentation.deploy_key":
    "https://docs.platform.sh/administration/web/configure-project.html#deploy-key",
  "links.documentation.dedicated_cluster":
    "https://docs.platform.sh/dedicated/overview.html",
  "links.documentation.dns": "https://docs.platform.sh/golive/steps/dns.html",
  "links.documentation.download_code":
    "https://docs.platform.sh/gettingstarted/local-development/download-code.html",
  "links.documentation.domain": "https://docs.platform.sh/domains/steps.html",
  "links.documentation.subdomain":
    "https://docs.platform.sh/domains/steps/subdomains.html",
  "links.documentation.own_code_project_configuration":
    "https://docs.platform.sh/gettingstarted/own-code/project-configuration.html",
  "links.documentation.getting_started":
    "https://docs.platform.sh/gettingstarted.html",
  "links.documentation.inactive_environment":
    "https://docs.platform.sh/GLOSSARY.html#inactive-environment",
  "links.documentation.paused_crons":
    "https://docs.platform.sh/configuration/app/cron.html#paused-crons",
  "links.documentation.going_live":
    "https://docs.platform.sh/environments/search-engine-visibility.html",
  "links.documentation.environment_search_engine_visibility":
    "https://docs.platform.sh/environments/search-engine-visibility.html",
  "links.documentation.mysql_import_data":
    "https://docs.platform.sh/configuration/services/mysql.html#importing-data",
  "links.documentation.postgres_import_data":
    "https://docs.platform.sh/configuration/services/postgresql.html#importing-data",
  "links.documentation.integrations":
    "https://docs.platform.sh/integrations/overview.html",
  "links.documentation.migrating_tutorial":
    "https://docs.platform.sh/learn/tutorials/migrating.html",
  "links.documentation.set_domain":
    "https://docs.platform.sh/gettingstarted/next-steps/going-live/set-domain.html",
  "links.documentation.pricing":
    "https://docs.platform.sh/administration/pricing.html",
  "links.documentation.routes":
    "https://docs.platform.sh/configuration/routes.html",
  "links.documentation.routes_SSI":
    "https://docs.platform.sh/configuration/routes/ssi.html",
  "links.documentation.services":
    "https://docs.platform.sh/configuration/services.html",
  "links.documentation.SSH_keys":
    "https://docs.platform.sh/development/ssh/ssh-keys.html",
  "links.documentation.variables":
    "https://docs.platform.sh/development/variables.html",
  "links.documentation.variables.run_and_build_time":
    "https://docs.platform.sh/development/variables.html?#variables-available-during-builds-and-at-runtime",
  "links.documentation.yaml_configuration":
    "https://docs.platform.sh/configuration/yaml.html",
  "links.documentation.app.storage_mounts":
    "https://docs.platform.sh/configuration/app/storage.html#mounts",
  "links.documentation.app": "https://docs.platform.sh/configuration/app.html",
  "links.documentation.backups":
    "https://docs.platform.sh/security/backups.html",
  "links.documentation.users":
    "https://docs.platform.sh/administration/users.html",
  "links.documentation.WCAG": "https://platform.sh/wcag/",
  "links.documentation.metric":
    "https://docs.platform.sh/increase-observability/metrics.html",
  "links.documentation.metrics":
    "https://docs.platform.sh/increase-observability/metrics.html",
  "links.documentation.metrics.grid":
    "https://docs.platform.sh/increase-observability/metrics/grid.html",
  "links.documentation.metrics.dedicated":
    "https://docs.platform.sh/increase-observability/metrics/dedicated.html",
  "links.documentation.metrics.dedication":
    "https://docs.platform.sh/increase-observability/metrics/dedicated-generation-3.html",
  "links.documentation.integrations.activity":
    "https://docs.platform.sh/integrations/activity.html",
  "links.documentation.integrations.activity_scripts":
    "https://docs.platform.sh/integrations/activity.html#configuring-scripts",
  "links.documentation.integrations.source":
    "https://docs.platform.sh/integrations/source.html",
  "links.documentation.app.timezone":
    "https://docs.platform.sh/configuration/app/timezone.html",
  "links.documentation.organizations":
    "https://docs.platform.sh/administration/organizations.html#manage-organizations-with-the-cli",
  "links.documentation.redeploy":
    "https://docs.platform.sh/development/troubleshoot.html#force-a-redeploy",
  "links.documentation.SSH":
    "https://docs.platform.sh/development/ssh.html#authenticate-with-ssh-keys",
  "links.documentation.workers":
    "https://docs.platform.sh/configuration/app/workers.html",
  "links.documentation.source_operations":
    "https://docs.platform.sh/configuration/app/source-operations.html",
  "links.documentation.runtime_operations":
    "https://docs.platform.sh/create-apps/runtime-operations.html",
  "links.documentation.live_backups":
    "https://docs.platform.sh/administration/backup-and-restore.html#live-backups",
  "links.documentation.automated_updates":
    "https://docs.platform.sh/create-apps/source-operations.html",
  "links.documentation.trustcenter": "https://platform.sh/trust-center/",
  "links.documentation.configure_project.certificates":
    "https://docs.platform.sh/administration/web/configure-project.html#certificates",
  "links.documentation.environments.default_environment":
    "https://docs.platform.sh/environments/default-environment.html",
  "links.documentation.organizations.manage-your-organization-users":
    "https://docs.platform.sh/administration/organizations.",
  "links.documentation.flexible_resources":
    "https://docs.platform.sh/create-apps/flexible-resources.html",
  "links.documentation.environments.cancel_activity":
    "https://docs.platform.sh/environments/cancel-activity.html",
  "links.documentation.administration.servers":
    "https://docs.platform.sh/administration/servers.html",
  "links.documentation.configure_project":
    "https://docs.platform.sh/administration/web/configure-project.html",
  "links.documentation.resources":
    "https://docs.platform.sh/environments/scalability.html",
  "links.documentation.integrations.github":
    "https://docs.platform.sh/integrations/source/github.html",
  "links.documentation.manage_resources.environment.sync": "#",
  "links.pricing": "https://platform.sh/pricing/",
  "links.pricing.compare": "https://platform.sh/pricing#compare",
  "links.platform.contact": "https://platform.sh/contact",
  "links.chat.channel": "https://chat.platform.sh",
  "links.CLI_download": "https://platform.sh/cli/installer",
  "links.CLI_download.osx_linux": "platformsh/tap/platformsh-cli",
  "links.CLI_download.windows":
    "https://github.com/platformsh/homebrew-tap.git",
  "links.CLI_repository": "https://github.com/platformsh/cli",
  "links.community.tutorial": "https://community.platform.sh/c/tutorial",
  "links.community.questions_answers":
    "https://community.platform.sh/c/questions-answers",
  "links.community.6_launched": "https://next.platform.sh/tabs/6-launched",
  "links.community.requestfeature":
    "https://next.platform.sh/tabs/6-launched/submit-idea",
  "links.community.featureroadmap": "https://next.platform.sh/",
  "links.contact": "https://platform.sh/contact/",
  "links.api.docs": "https://api.platform.sh/docs/",
  "links.observability-suite-features":
    "https://platform.sh/features/observability-suite",
  "links.blackfire.connect": "https://blackfire.io/session/connect/platformsh",
  "links.observability-suite-blackfire":
    "https://docs.platform.sh/increase-observability/integrate-observability/blackfire.html",
  "links.observability-suite-blackfire.integrate":
    "https://docs.platform.sh/increase-observability/integrate-observability/blackfire.html#getting-started",
  "links.blackfire.features": "https://www.blackfire.io/features",
  "links.domains.replacement": "https://docs.platform.sh/domains.html",
  "links.resources.green": "https://docus.platform.sh/resources/green.html",
  "link.application_metrics_increase_observability":
    "https://docs.platform.sh/increase-observability/metrics.html",
  "links.documentation.understanding_metrics":
    "https://docs.platform.sh/increase-observability/metrics.html",
  "links.documentation.usermanagementadddon":
    "https://docs.upsun.com/administration/billing/add-on-subscription.html#standard-user-management-add-on",
  "links.documentation.settings.variables.variable.prefix":
    "https://docs.platform.sh/development/variables.html#variable-prefixes",
  "links.documentation.endpoint":
    "https://search.docs.platform.sh/indexes/platform_docs/search",
  "links.documentation.homepage": "https://docs.platform.sh",
  "links.demo_project_git_url":
    "https://github.com/platformsh/demo-project.git",
  "links.sca.commision":
    "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32018R0389",
  "links.sca.learnMore":
    "https://platform.sh/trust-center/legal/tos/#section-6-fees-and-term"
};

export default links;
