import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import type { Organization } from "@packages/client";

export const loadDiscounts = createAppAsyncThunk(
  "discounts/load",
  async (
    { organization }: { organization: Organization },
    { rejectWithValue }
  ) => {
    try {
      const data = await organization.getDiscounts();

      return data.items;
    } catch (error) {
      rejectWithValue(error as { error: string });
    }
  }
);

type DiscountsState = {
  data: Record<
    string,
    Awaited<ReturnType<Organization["getDiscounts"]>>["items"] | undefined
  >;
};

const initialState: DiscountsState = {
  data: {}
};

const discounts = createSlice({
  name: "discounts",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadDiscounts.fulfilled, (state, { payload, meta }) => {
      state.data[meta.arg.organization.id] = payload;
    });
  }
});

export default discounts.reducer;

export const discountsSelector = (state: RootState) =>
  state.organizationDiscounts;

export const organizationDiscountsSelector = createSelector(
  discountsSelector,
  (_: RootState, { organizationId }: { organizationId?: string }) =>
    organizationId,
  (discounts, organizationId) =>
    organizationId ? discounts.data[organizationId] : undefined
);

export const organizationActiveCommitmentsSelector = createSelector(
  organizationDiscountsSelector,
  data =>
    data?.filter(
      discount => discount.commitment && discount.status === "active"
    )
);

export const organizationHasActiveCommitmentsSelector = createSelector(
  organizationActiveCommitmentsSelector,
  data => (data ?? []).length > 0
);
