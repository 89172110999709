import { createAsyncThunk } from "@reduxjs/toolkit";

import { ACTIVITY_TYPES } from "Constants/constants";
import logger from "Libs/logger";
import {
  isJson,
  getEnvironmentDescriptionId,
  getIntegrationDescriptionId
} from "Libs/utils";
import { loadDeployment } from "Reducers/environment/deployment";
import { orgByProjectIdSelector } from "Reducers/project/project";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Activity } from "@packages/client";

type ActivityAcc = {
  activity: Activity;
  organizationDescriptionId: string;
  projectDescriptionId: string;
  environmentDescriptionId: string;
  integrationDescriptionId?: string;
}[];

export const loadActivitySuccess = createAsyncThunk<
  ActivityAcc,
  { activity: Activity },
  AsyncThunkOptionType
>(
  "app/activity/load",
  ({ activity }, { dispatch, getState, rejectWithValue }) => {
    const { project, environments, state: activityState, type } = activity;

    const projectDescriptionId = project;
    const state = getState();
    const organizationDescriptionId = orgByProjectIdSelector(state, {
      projectId: projectDescriptionId
    });

    const integrationDescriptionId = getIntegrationDescriptionId(
      getState,
      projectDescriptionId,
      activity?.integration
    );

    const reloadDeployment = (environmentId: string) => {
      if (
        type === ACTIVITY_TYPES.ENVIRONMENT.PUSH &&
        activityState === "complete"
      ) {
        dispatch(
          loadDeployment({
            organizationId: organizationDescriptionId,
            projectId: project,
            environmentId,
            settings: {
              hasRedeployed: true
            }
          })
        );
      }
    };

    try {
      return environments.reduce<ActivityAcc>((acc, environmentId) => {
        const environmentDescriptionId = getEnvironmentDescriptionId(
          getState,
          organizationDescriptionId ?? "",
          projectDescriptionId,
          environmentId
        );

        if (!environmentDescriptionId) return acc;

        reloadDeployment(environmentId);
        acc.push({
          activity,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          integrationDescriptionId
        });
        return acc;
      }, []);
    } catch (error: any) {
      if (![404, 403, 400].includes(error.code)) {
        const errorMessage = isJson(error)
          ? error
          : "An error occurred while attempting to load activity.";
        logger(errorMessage, {
          action: "app/activity/load_failure",
          meta: {
            organizationDescriptionId,
            projectDescriptionId
          }
        });
      }
      return rejectWithValue(error);
    }
  }
);
