import React, { useEffect, useState, MouseEvent, useCallback } from "react";
import { CopyIcon, SuccessIcon } from "../../Icons";
import { Button } from "..";
import type { ButtonProps } from "..";
import { copyToClipboard } from "../../__internal/_copyToClipboard";

export type CopyButtonProps = {
  /** onClick event handler attached to the button */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** Event handler triggered once the text has been copied to the clipboard */
  onCopy?: (text: string) => void;
  /** A custom icon can be provided. It will replace the default copy icon */
  icon?: React.ReactNode;
  /** Content to be copied */
  text: string;
  /** Text to replace the `copy` fragment it will be displayed when the user has not interacted with the copy button */
  title?: React.ReactNode;
  /** Wether to display the copy and copied message within the button or not */
  withText?: boolean;
  /** Button variant */
  variant?: ButtonProps["variant"];
  id?: string;
  analyticId?: string;
  /** Copy success text - injected by wrapper component */
  successText?: string;
  /** Copy action text - injected by wrapper component */
  actionText?: string;
};

export const CopyButton = ({
  icon = <CopyIcon />,
  text,
  title = "copy",
  variant,
  onClick = () => void 0,
  onCopy = copyToClipboard,
  withText = true,
  analyticId,
  successText = "",
  actionText = "",
  ...props
}: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [copied]);

  const onClickCopy = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (text) {
        onCopy(text);
        setCopied(true);
      }
      const activeElement = document.activeElement as HTMLButtonElement;
      activeElement?.blur();
      onClick(event);
    },
    [text, onCopy, onClick]
  );

  return (
    <Button
      onClick={onClickCopy}
      title={actionText}
      {...props}
      content={withText ? "mixed" : "icon"}
      variant={variant}
      data-heap-identifier={analyticId}
    >
      {copied && <SuccessIcon />}
      {copied ? null : icon}
      {withText && (copied ? successText : title)}
    </Button>
  );
};
