import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Variable } from "@packages/client";

type DeleteEnvironmentVariableThunkProptype = {
  variable: Variable;
  environmentId: string;
  projectId: string;
  organizationId: string;
};

export const deleteVariable = createAsyncThunk<
  Variable,
  DeleteEnvironmentVariableThunkProptype,
  AsyncThunkOptionType<{ error: string }>
>(
  "app/project/environment/variable/delete",
  async (
    { variable, environmentId, projectId, organizationId },
    { rejectWithValue }
  ) => {
    try {
      await variable.delete();
      return variable;
    } catch (err: any) {
      logger(err, {
        variable,
        environmentId,
        projectId,
        organizationId,
        action: "environmentDeleteVariable"
      });
      return rejectWithValue({ error: err.message });
    }
  }
);
