import {
  createAsyncThunk,
  createSlice,
  SerializedError
} from "@reduxjs/toolkit";

import client from "Libs/platform";

import type { default as Client, Ticket } from "@packages/client";

export interface Error {
  message: string;
  title: string;
}

// Thunk
export const load = createAsyncThunk(
  "tickets/view/load",
  async (ticketId: string | number, { rejectWithValue }) => {
    try {
      const response = await client.getTickets({
        filter: { ticket_id: ticketId }
      });
      return response.tickets[0];
    } catch (error) {
      rejectWithValue({
        message: (error as Error).message || (error as Error).title
      });
    }
  }
);

export const loadAllAttachments = createAsyncThunk(
  "tickets/view/load_all_attachments",
  async (ticketId: string | number, { rejectWithValue }) => {
    try {
      const response = await client.getAllTicketAttachments(ticketId);
      return response;
    } catch (error) {
      rejectWithValue({ error });
    }
  }
);

export const updateTicketStatus = createAsyncThunk(
  "tickets/view/update_status",
  async (
    { ticketId, status }: { ticketId: string | number; status: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.updateTicketStatus(ticketId, status);
      return response;
    } catch (error) {
      rejectWithValue({ error });
    }
  }
);

export type TicketState = {
  isLoading: boolean;
  isLoadingAttachments: boolean;
  attachments?: Awaited<ReturnType<Client["getAllTicketAttachments"]>>;
  attachmentsError?: SerializedError;
  error?: SerializedError;
  content?: Ticket;
};

const initialState: TicketState = {
  isLoading: false,
  isLoadingAttachments: false
};

const tickets = createSlice({
  name: "app/tickets",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadAllAttachments.pending, state => {
        state.attachments = undefined;
        state.isLoadingAttachments = true;
      })
      .addCase(loadAllAttachments.fulfilled, (state, action) => {
        state.attachments = action.payload;
        state.isLoadingAttachments = false;
      })
      .addCase(loadAllAttachments.rejected, (state, action) => {
        state.attachmentsError = action.error;
        state.isLoadingAttachments = false;
      })
      .addCase(load.pending, state => {
        state.error = undefined;
        state.content = undefined;
        state.isLoading = true;
      })
      .addCase(load.fulfilled, (state, action) => {
        state.content = action.payload;
        state.isLoading = false;
      })
      .addCase(load.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      })
      .addCase(updateTicketStatus.fulfilled, (state, action) => {
        state.content = action.payload;
        state.isLoading = false;
      });
  }
});

export default tickets.reducer;
