import * as d3 from "d3";
import moment from "moment";

import { TimeFrameType } from "src/organization/pages/project/environment/metrics/resources/types";
import { parseTimeDuration } from "src/organization/pages/project/environment/metrics/utils/parseDimensions/parseDimensions";

export const margin = {
  top: 7,
  right: 32,
  bottom: 30,
  left: 32,
  height: 230
};

export const timeFormats = {
  tooltip: d3.timeFormat("%H:%M:%S"),
  tooltipLong: d3.timeFormat("%H:%M %b %d %Y"),
  seconds: d3.timeFormat("%H:%M"),
  minutes: d3.timeFormat("%H:%M"),
  hours: d3.timeFormat("%H:%M"),
  days: d3.timeFormat("%b %d"),
  weeks: d3.timeFormat("%b %d"),
  months: d3.timeFormat("%b %d")
};

const durationStringToRange = (durationText: string) => {
  const duration = parseTimeDuration(durationText);
  return {
    start: moment().subtract(duration.range, duration.type).toDate(),
    end: moment().toDate()
  };
};

export const timeframeToRange = (timeframe?: TimeFrameType) => {
  const { from, to, id, start, end } = timeframe || {};
  if (from && to) return { start: new Date(from), end: new Date(to) };
  if (start && end) return { start: new Date(start), end: new Date(end) };
  if (id && typeof id === "string" && id.length > 1)
    return durationStringToRange(id);
  return { start: new Date(), end: new Date() };
};

/**
 * Compares two timeframes to determine if they represent the same time period
 * @param t1 - First timeframe to compare
 * @param t2 - Second timeframe to compare
 * @returns Boolean indicating whether the timeframes are equal
 */
export const isEqualTimeframe = (t1: TimeFrameType, t2: TimeFrameType) => {
  const hashTimeframe = ({ start, end }) => btoa(`${start}-${end}`);
  return (
    hashTimeframe(timeframeToRange(t1)) === hashTimeframe(timeframeToRange(t2))
  );
};

export const getQueryInterval = (
  selectedTimeframe: TimeFrameType,
  maxPoints = null
) => {
  const range = timeframeToRange(selectedTimeframe);
  const rangeMinute = moment(new Date(range.end)).diff(
    new Date(range.start),
    "minutes"
  );

  const intervals = [
    { minutes: 15, unit: "1m" },
    { minutes: 60, unit: "1m" },
    { minutes: 480, unit: "5m" },
    { minutes: 1440, unit: "10m" },
    { minutes: 2880, unit: "30m" },
    { minutes: 10080, unit: "1h" },
    { minutes: 21600, unit: "2h" },
    { minutes: 43200, unit: "4h" },
    { minutes: 86400, unit: "8h" },
    { minutes: 129600, unit: "12h" },
    { minutes: 259200, unit: "24h" },
    { minutes: 525600, unit: "48h" }
  ];

  if (maxPoints) {
    const getInterval = (minutes: number, intvals, maxPoints = 200) => {
      let i = 0;
      while (minutes / intvals[i].minutes >= maxPoints) {
        i++;
      }
      return `${parseTimeDuration(intvals[i].unit).seconds}s`;
    };
    return `${getInterval(rangeMinute, intervals, maxPoints)}s`;
  }

  for (const interval of intervals) {
    if (rangeMinute <= interval.minutes) {
      return `${parseTimeDuration(interval.unit).seconds}s`;
    }
  }
  return `${parseTimeDuration(intervals[0].unit).seconds}s`;
};

type TimeInterval =
  | "seconds"
  | "minutes"
  | "hours"
  | "days"
  | "weeks"
  | "months";

export const getRangeFormat = ({ start, end }) => {
  const rangeDuration = moment.duration(moment(end).diff(moment(start)));
  const intervals: Record<TimeInterval, moment.Duration> = {
    seconds: moment.duration(3, "minutes"),
    minutes: moment.duration(8, "hours"),
    hours: moment.duration(2, "days"),
    days: moment.duration(7, "days"),
    weeks: moment.duration(4, "weeks"),
    months: moment.duration(2, "months")
  };
  for (const [interval, duration] of Object.entries(intervals).reverse()) {
    if (rangeDuration.as("seconds") >= duration.as("seconds")) {
      return interval;
    }
  }
  return "minutes";
};
