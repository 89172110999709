import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAutoscalingConfiguration } from "Api/organizations/projects/getAutoscalingConfiguration.request";
import { setAutoscalingConfiguration } from "Api/organizations/projects/setAutoscalingConfiguration.request";
import { getServiceIcon } from "Components/ServiceIcon/helper";
import { AsyncThunkOptionType } from "Reducers/types";

import { ErrorResponse } from "./types";

import { ServiceConfig, ServiceState, Settings } from "./";

import type { Deployment } from "@packages/client";

const addClass = (list = {}, newClass) => {
  return Object.keys(list).reduce((current, key) => {
    const icon = getServiceIcon(list[key]).iconName;
    current[key] = { name: key, icon, class: newClass, ...list[key] };
    return current;
  }, {});
};

export const setAutoscalingConfigurationThunk = createAsyncThunk<
  Record<string, ServiceConfig>,
  { projectId: string; environmentId: string; settings: Settings },
  AsyncThunkOptionType
>(
  "app/environment/service/setAutoscalingConfiguration",
  async ({ projectId, environmentId, settings }, { rejectWithValue }) => {
    try {
      const { services } = await setAutoscalingConfiguration(
        projectId,
        environmentId,
        settings
      );

      return services;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAutoscalingConfigurationThunk = createAsyncThunk<
  Settings,
  { projectId: string; environmentId: string },
  AsyncThunkOptionType
>(
  "app/environment/service/getAutoscalingConfiguration",
  async ({ projectId, environmentId }, { rejectWithValue }) => {
    try {
      const { services } = await getAutoscalingConfiguration(
        projectId,
        environmentId
      );

      return services;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState: ServiceState = {
  data: {},
  autoscalingLoading: false,
  autoscalingError: null,
  autoscalingConfiguration: { services: {} }
};

const serviceReducer = createSlice({
  name: "app/environment/service",
  initialState,
  reducers: {
    updateServices: (
      state,
      {
        payload
      }: PayloadAction<{
        organizationDescriptionId?: string;
        projectDescriptionId?: string;
        environmentDescriptionId?: string;
        deploymentId?: string;
        deployment: Deployment;
      }>
    ) => {
      const {
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId,
        deploymentId,
        deployment
      } = payload;

      if (
        organizationDescriptionId &&
        projectDescriptionId &&
        environmentDescriptionId &&
        deploymentId
      ) {
        const organizedDeploymentContent = {
          routes: deployment.routes,
          ...addClass(deployment.services, "service"),
          ...addClass(deployment.webapps, "app"),
          workers: addClass(Object.values(deployment.workers), "worker")
        };

        const data = {
          ...state.data,
          [organizationDescriptionId]: {
            ...state.data?.[organizationDescriptionId],
            [projectDescriptionId]: {
              ...state.data?.[organizationDescriptionId]?.[
                projectDescriptionId
              ],
              [environmentDescriptionId]: {
                ...state.data?.[organizationDescriptionId]?.[
                  projectDescriptionId
                ]?.[environmentDescriptionId],
                [deploymentId]: {
                  ...state.data?.[organizationDescriptionId]?.[
                    projectDescriptionId
                  ]?.[environmentDescriptionId]?.[deploymentId],
                  ...organizedDeploymentContent
                }
              }
            }
          }
        };

        state.data = data;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(setAutoscalingConfigurationThunk.pending, state => {
        state.autoscalingLoading = true;
        state.autoscalingError = null;
      })
      .addCase(setAutoscalingConfigurationThunk.fulfilled, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingConfiguration = action.payload;
      })
      .addCase(setAutoscalingConfigurationThunk.rejected, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingError = action?.payload as ErrorResponse;
      })
      .addCase(getAutoscalingConfigurationThunk.pending, state => {
        state.autoscalingLoading = true;
        state.autoscalingError = null;
      })
      .addCase(getAutoscalingConfigurationThunk.fulfilled, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingConfiguration = action.payload;
      })
      .addCase(getAutoscalingConfigurationThunk.rejected, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingError = action?.payload as ErrorResponse;
      });
  }
});

export default serviceReducer.reducer;
export const { updateServices } = serviceReducer.actions;
