import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client, { entities, request } from "Libs/platform";
import { hasHtml } from "Libs/utils";
import { selectUserProjectById } from "Reducers/app/selectors";
import { selectFeatureOrganizationsEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import {
  LOAD_ORGANIZATION_SUCCESS,
  organizationSelector,
  organizationsSelector
} from "Reducers/organization";
import {
  loadOneProjectHelper,
  subscribe,
  LoadOneProjectType
} from "Reducers/project/project";

import type { Project } from "@packages/client";
import type { AsyncThunkOptionType } from "Reducers/types";

export const loadOneProject = createAsyncThunk<
  undefined,
  LoadOneProjectType,
  AsyncThunkOptionType
>(
  "app/project_reducer/load_one_project",
  async (
    { projectId, organizationId, loadActivitiesAndSubscribe = true },
    { dispatch, getState, rejectWithValue }
  ) => {
    let project: Project | undefined = undefined;
    const organizationEnabled = selectFeatureOrganizationsEnabled(getState());
    try {
      // Get the project id from the project description id in the
      // Project loaded from /me
      let projectMe = selectUserProjectById(getState(), {
        projectId
      });

      if (!projectMe) {
        projectMe = (await request(
          `${client.getConfig().api_url}/locate/${projectId}`,
          "GET"
        )) as Project;

        project = await entities.Project.get(
          { id: projectId },
          projectMe.endpoint
        );

        if (!project) {
          return rejectWithValue("Project not found");
        }
      }

      if (!project) {
        project = await client.getProject(projectMe.id);

        if (!project) {
          return rejectWithValue("Project not found");
        }
      }

      if (organizationEnabled) {
        let organization = organizationSelector(getState(), {
          organizationId: projectMe.organization_id
        });

        if (!organization) {
          // Load the organization
          organization = await client.getOrganization(
            projectMe.organization_id
          );
          dispatch({
            type: LOAD_ORGANIZATION_SUCCESS,
            payload: organization
          });
        }
      }

      const organizations = organizationsSelector(getState());

      dispatch(
        loadOneProjectHelper({
          project,
          projectMe,
          organizations
        })
      );

      if (loadActivitiesAndSubscribe) {
        dispatch(subscribe({ project, organizationId }));
      }
    } catch (err) {
      if (
        ![404, 403, 400].includes((err as { code: number })?.code) &&
        !hasHtml(err as string)
      ) {
        logger(err, {
          action: "project_load",
          projectId
        });
      }
      return rejectWithValue(err);
    }
  }
);
