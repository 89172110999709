import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { entities } from "Libs/platform";

import type { Activity } from "@packages/client";

type ActivitiesState = {
  activeActivity?: Activity;
  selectedActivity?: Activity;
};

const initialState: ActivitiesState = {};

const activitiesSlice = createSlice({
  name: "activitiesSlice",
  initialState,
  reducers: {
    setActiveActivity: (state, action: PayloadAction<Activity | undefined>) => {
      state.activeActivity = action.payload
        ? new entities.Activity(action.payload, "")
        : undefined;
    },
    setSelectedActivity: (
      state,
      action: PayloadAction<Activity | undefined>
    ) => {
      state.selectedActivity = action.payload
        ? new entities.Activity(action.payload, "")
        : undefined;
    }
  }
});

export const { setActiveActivity, setSelectedActivity } =
  activitiesSlice.actions;

export default activitiesSlice.reducer;
