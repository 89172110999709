import {
  getMember,
  resolveMemberLinks
} from "Api/organizations/member/getMember.request";
import { selectCurrentUserUUID } from "Reducers/app/selectors";
import { organizationSelector } from "Reducers/organization";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import { loggedInMemberDataSelector } from "../loggedInMember.selectors";

export const loadLoggedInMember = createAppAsyncThunk(
  "organizations/loadLoggedInMember",
  async (organizationId: string | undefined, { getState }) => {
    const state = getState();
    const data = loggedInMemberDataSelector(state);

    const organization = organizationSelector(state, {
      organizationId
    });
    const canRequestMembers = !!organization?.hasLink("members");

    // Prevents reloading the organization member information if is
    // already on the store.
    if (
      !organizationId ||
      (data && data?.member.organization_id === organizationId)
    ) {
      return data;
    }

    const id = selectCurrentUserUUID(state);
    if (!id || !canRequestMembers) {
      return;
    }

    const member = await getMember(organizationId, id);
    const api = resolveMemberLinks(member);
    const user = await api.user();
    return { member, user: user[member.id] };
  }
);
