import { ACTIVITY_TYPES } from "Constants/constants";
import { RootState } from "Store/configureStore";

export type ActivityParams = {
  activityId: string;
  organizationId: string;
  projectId: string;
};

export type ProjectParams = {
  organizationId: string;
  projectId: string;
};

export type IntegrationParams = {
  organizationId: string;
  projectId: string;
  integrationId: string;
};

export type EnvironmentParams = {
  organizationId: string;
  projectId: string;
  environmentId: string;
};

type Status =
  | "in_progress"
  | "pending"
  | "complete"
  | "cancelled"
  | "scheduled"
  | "staged";

type Result = "failure" | "success";

const sortByCreationDate = (
  item1: { created_at: string },
  item2: { created_at: string }
) =>
  new Date(item2.created_at).getTime() - new Date(item1.created_at).getTime();

const selectAllProjectActivities = (
  state: RootState,
  { organizationId, projectId }: ProjectParams
) => {
  return Object.fromEntries(
    Object.entries(
      state.activity?.byProject?.data?.[organizationId]?.[projectId] ?? {}
    ).sort(([, value1], [, value2]) => sortByCreationDate(value1, value2))
  );
};

const selectProjectActivitiesByStatus =
  (status: Status | Status[]) => (state: RootState, params: ProjectParams) => {
    const allActivities = selectAllProjectActivities(state, params);
    return Object.values(allActivities)
      .filter(activity =>
        typeof status === "string"
          ? activity.state === status
          : (status as string[]).includes(activity.state)
      )
      .sort((activity1, activity2) => sortByCreationDate(activity1, activity2));
  };

const selectProjectActivitiesByResult =
  (result: Result | Result[]) => (state: RootState, params: ProjectParams) => {
    const allActivities = selectAllProjectActivities(state, params);
    return Object.values(allActivities)
      .filter(activity =>
        typeof result === "string"
          ? activity.result === result
          : (result as string[]).includes(activity.result)
      )
      .sort((activity1, activity2) => sortByCreationDate(activity1, activity2));
  };

const selectInProgressProjectActivities =
  selectProjectActivitiesByStatus("in_progress");
const selectStagedProjectActivities = selectProjectActivitiesByStatus("staged");

const selectPendingProjectActivities = selectProjectActivitiesByStatus([
  "pending",
  "scheduled"
]);

const selectCompletedProjectActivities = selectProjectActivitiesByStatus([
  "complete",
  "cancelled"
]);

const selectFailedProjectActivities =
  selectProjectActivitiesByResult("failure");

const selectProjectHasMoreActivities = (state: RootState) =>
  state.activity?.byProject?.hasMore;

const selectProjectActivityLoadingState = (state: RootState) =>
  state.activity?.byProject?.loading;

const selectProjectActivityLoadingMoreState = (state: RootState) =>
  state.activity?.byProject?.loadingMore;

export const selectProjectActivitiesByType =
  (types: Array<string>) => (state: RootState, params: ProjectParams) => {
    const allActivities = selectAllProjectActivities(state, params);
    return Object.values(allActivities)
      .filter(activity => types.includes(activity.type))
      .sort((activity1, activity2) => sortByCreationDate(activity1, activity2));
  };

export const selectProjectActivityById = (
  state: RootState,
  params: ActivityParams
) => {
  const allActivities = selectAllProjectActivities(state, params);
  return allActivities?.[params.activityId];
};

const selectAllEnvironmentActivities = (
  state: RootState,
  { organizationId, projectId, environmentId }: EnvironmentParams
) => {
  return Object.fromEntries(
    Object.entries(
      state.activity?.byEnvironment?.data?.[organizationId]?.[projectId]?.[
        environmentId
      ] ?? {}
    ).sort(([, value1], [, value2]) => sortByCreationDate(value1, value2))
  );
};

export const getActivityFilters = (state: RootState, context: string) =>
  state.activity?.filters?.[context];

const selectEnvironmentActivitiesByStatus =
  (status: Status | Status[]) =>
  (state: RootState, params: EnvironmentParams) => {
    const activities = selectAllEnvironmentActivities(state, params);
    return Object.values(activities)
      .filter(activity =>
        typeof status === "string"
          ? activity.state === status
          : (status as string[]).includes(activity.state)
      )
      .sort((activity1, activity2) => sortByCreationDate(activity1, activity2));
  };

const selectEnvironmentActivitiesByResult =
  (result: Result | Result[]) =>
  (state: RootState, params: EnvironmentParams) => {
    const activities = selectAllEnvironmentActivities(state, params);
    return Object.values(activities)
      .filter(activity =>
        typeof result === "string"
          ? activity.result === result
          : (result as string[]).includes(activity.result)
      )
      .sort((activity1, activity2) => sortByCreationDate(activity1, activity2));
  };

const selectInProgressEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("in_progress");
const selectStagedEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("staged");

const selectPendingEnvironmentActivities = selectEnvironmentActivitiesByStatus([
  "pending",
  "scheduled"
]);

const selectCompletedEnvironmentActivities =
  selectEnvironmentActivitiesByStatus(["complete", "cancelled"]);

const selectFailedEnvironmentActivities =
  selectEnvironmentActivitiesByResult("failure");

const selectEnvironmentActivityLoadingState = (state: RootState) =>
  state.activity?.byEnvironment?.loading;

const selectEnvironmentActivityLoadingMoreState = (state: RootState) =>
  state.activity?.byEnvironment?.loadingMore;

const selectEnvironmentHasMoreActivities = (state: RootState) =>
  state.activity?.byEnvironment?.hasMore;

const selectAllIntegrationActivities = (
  state: RootState,
  { organizationId, projectId, integrationId }: IntegrationParams
) => {
  return Object.fromEntries(
    Object.entries(
      state.activity?.byIntegration?.data?.[organizationId]?.[projectId]?.[
        integrationId
      ] ?? {}
    ).sort(([, value1], [, value2]) => sortByCreationDate(value1, value2))
  );
};

const selectIntegrationActivitiesByStatus =
  (status: Status | Status[]) =>
  (state: RootState, params: IntegrationParams) => {
    const activities = selectAllIntegrationActivities(state, params);
    return Object.values(activities)
      .filter(activity =>
        typeof status === "string"
          ? activity.state === status
          : status.includes(activity.state as Status)
      )
      .sort((activity1, activity2) => sortByCreationDate(activity1, activity2));
  };

const selectIntegrationActivitiesByResult =
  (result: Result | Result[]) =>
  (state: RootState, params: IntegrationParams) => {
    const activities = selectAllIntegrationActivities(state, params);
    return Object.values(activities)
      .filter(activity =>
        typeof result === "string"
          ? activity.result === result
          : result.includes(activity.result as Result)
      )
      .sort((activity1, activity2) => sortByCreationDate(activity1, activity2));
  };

export const getActivityTab = (state: RootState) =>
  state.activity?.filters?.tab || "recent";

const selectInProgressIntegrationActivities =
  selectIntegrationActivitiesByStatus("in_progress");
const selectStagedIntegrationActivities =
  selectIntegrationActivitiesByStatus("staged");

const selectPendingIntegrationActivities = selectIntegrationActivitiesByStatus([
  "pending",
  "scheduled"
]);

const selectCompletedIntegrationActivities =
  selectIntegrationActivitiesByStatus(["complete", "cancelled"]);

const selectFailedIntegrationActivities =
  selectIntegrationActivitiesByResult("failure");

const selectIntegrationActivityLoadingState = (state: RootState) =>
  state.activity?.byIntegration?.loading;

const selectIntegrationActivityLoadingMoreState = (state: RootState) =>
  state.activity?.byIntegration?.loadingMore;

const selectIntegrationHasMoreActivities = (state: RootState) =>
  state.activity?.byIntegration?.hasMore;

export type Context =
  | "environment"
  | "integration"
  | `integration:${string}`
  | `integration.${string}`
  | "project";

export const getSelectors = (context: Context, params: any) => {
  const selectors = {
    environment: {
      all: (state: RootState) => selectAllEnvironmentActivities(state, params),
      inProgress: (state: RootState) =>
        selectInProgressEnvironmentActivities(state, params),
      pending: (state: RootState) =>
        selectPendingEnvironmentActivities(state, params),
      staged: (state: RootState) =>
        selectStagedEnvironmentActivities(state, params),
      completed: (state: RootState) =>
        selectCompletedEnvironmentActivities(state, params),
      failed: (state: RootState) =>
        selectFailedEnvironmentActivities(state, params),
      isLoading: (state: RootState) =>
        selectEnvironmentActivityLoadingState(state),
      isLoadingMore: (state: RootState) =>
        selectEnvironmentActivityLoadingMoreState(state),
      hasMore: (state: RootState) => selectEnvironmentHasMoreActivities(state)
    },
    integration: {
      all: (state: RootState) => selectAllIntegrationActivities(state, params),
      inProgress: (state: RootState) =>
        selectInProgressIntegrationActivities(state, params),
      pending: (state: RootState) =>
        selectPendingIntegrationActivities(state, params),
      staged: (state: RootState) =>
        selectStagedIntegrationActivities(state, params),
      completed: (state: RootState) =>
        selectCompletedIntegrationActivities(state, params),
      failed: (state: RootState) =>
        selectFailedIntegrationActivities(state, params),
      isLoading: (state: RootState) =>
        selectIntegrationActivityLoadingState(state),
      isLoadingMore: (state: RootState) =>
        selectIntegrationActivityLoadingMoreState(state),
      hasMore: (state: RootState) => selectIntegrationHasMoreActivities(state)
    },
    project: {
      all: (state: RootState) => selectAllProjectActivities(state, params),
      inProgress: (state: RootState) =>
        selectInProgressProjectActivities(state, params),
      pending: (state: RootState) =>
        selectPendingProjectActivities(state, params),
      staged: (state: RootState) =>
        selectStagedProjectActivities(state, params),
      completed: (state: RootState) =>
        selectCompletedProjectActivities(state, params),
      failed: (state: RootState) =>
        selectFailedProjectActivities(state, params),
      isLoading: (state: RootState) => selectProjectActivityLoadingState(state),
      isLoadingMore: (state: RootState) =>
        selectProjectActivityLoadingMoreState(state),
      hasMore: (state: RootState) => selectProjectHasMoreActivities(state)
    }
  };

  // context integration like 'integration.gitlab'
  const kind = (
    context.startsWith("integration") ? "integration" : context
  ) as keyof typeof selectors;
  return selectors[kind] || selectors["project"];
};

export const selectBackupActivities = (
  state: RootState,
  params: EnvironmentParams
) => {
  const environmentActivities = selectAllEnvironmentActivities(state, params);
  return Object.values(environmentActivities)
    .filter(elt =>
      [
        ACTIVITY_TYPES.ENVIRONMENT.BACKUP as string,
        ACTIVITY_TYPES.ENVIRONMENT.RESTORE as string,
        ACTIVITY_TYPES.ENVIRONMENT.BACKUP_DELETE as string
      ].includes(elt.type)
    )
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
};
