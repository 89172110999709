import React from "react";

import { Link as BaseLink, ButtonLinkComponentProps } from "@packages/ds";
import { Link as RouterLink } from "react-router-dom";

export type LinkProps = ButtonLinkComponentProps;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  if ("to" in props) {
    return <BaseLink ref={ref} {...props} as={RouterLink} />;
  }
  return <BaseLink ref={ref} {...props} />;
});

Link.displayName = "Link";

export { Link as default };
