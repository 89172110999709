import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Result, Variable, Environment } from "@packages/client";

type EnvironmentVariableData = {
  id: string;
  name: string;
  value: string;
  is_json: boolean;
  is_enabled: boolean;
  is_inheritable: boolean;
  is_sensitive: boolean;
  visible_build: boolean;
  visible_runtime: boolean;
};

type AddEnvironmentVariableThunkPropType = {
  environment: Environment;
  environmentId: string;
  projectId: string;
  organizationId: string;
  data: EnvironmentVariableData;
};

export const addVariable = createAsyncThunk<
  Variable,
  AddEnvironmentVariableThunkPropType,
  AsyncThunkOptionType<{ error: string }>
>(
  "app/project/environment/variable/add",
  async ({ data, environment }, { rejectWithValue }) => {
    try {
      const result: Result = await environment.setVariable(
        data.name,
        data.value,
        data.is_json,
        data.is_enabled,
        data.is_inheritable,
        data.is_sensitive,
        data.visible_build,
        data.visible_runtime
      );

      if ((result.data && result.data.code / 100) === 4) {
        return rejectWithValue({ error: JSON.stringify(result) });
      }

      const variable: Variable = await result.getEntity();
      return variable;
    } catch (err: any) {
      logger(err, {
        environment,
        action: "environmentAddVariable"
      });
      return rejectWithValue({ error: err?.detail?.name || err.message });
    }
  }
);
