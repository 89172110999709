import { createSelector } from "@reduxjs/toolkit";

import type { RootState } from "Store/configureStore";

export const selectAnnouncementsState = (state: RootState) =>
  state.announcements;

export const announcementsSelector = createSelector(
  selectAnnouncementsState,
  announcementState => announcementState.announcements
);

export const hasUnreadAnnouncementsSelector = createSelector(
  announcementsSelector,
  announcements => !!announcements.find(announcement => !announcement.read)
);

export const announcementsErrorSelector = createSelector(
  selectAnnouncementsState,
  announcementState => announcementState.error
);
