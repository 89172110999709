import Client from "@packages/client";

import config from "Constants/api_config";

type Release = (v: boolean) => void;

let release: Release | undefined = undefined;
export const setRelease = (callback: Release) => (release = callback);
export const getRelease = () => release;

type OnHoldCallback = (response: Response, release: Release) => void;
let onHold: OnHoldCallback;

export const setOnHold = (callback: OnHoldCallback) => {
  onHold = callback;
};

const customConfig = {
  ...config,
  hold401Responses: (response: Response) => {
    return new Promise<boolean>(resolve => {
      setRelease(resolve);
      if (onHold) onHold(response, resolve);
    });
  }
};

let client = new Client(customConfig);

export function reinitializeClient() {
  client = new Client(customConfig);
}

export { entities, request } from "@packages/client";

export default client;
