import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { environmentSelector, loadEnvironments } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";

import { BranchEnvironmentActionPropType } from "../";

import type { Activity } from "@packages/client";

export const branch = createAsyncThunk<
  Activity,
  BranchEnvironmentActionPropType,
  AsyncThunkOptionType
>(
  "Environment/Branch/Load",
  async (
    { options, organizationId, environmentId, projectId, resourcesInit },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const environment = environmentSelector(getState(), {
        organizationId,
        projectId,
        environmentId
      });

      if (!environment) {
        return rejectWithValue({ message: "Environment not found" });
      }

      const branch = await environment.branch(
        options.branchName,
        options.type,
        resourcesInit
      );

      // Reload the environments for the benefit of components that need the newest environment state
      dispatch(loadEnvironments({ projectId, organizationId }));
      return branch;
    } catch (err) {
      logger(err as Error, {
        action: "branch",
        payload: branch,
        options,
        meta: {
          organizationId,
          environmentId,
          projectId
        }
      });
      return rejectWithValue(err);
    }
  }
);
