import { createAsyncThunk } from "@reduxjs/toolkit";

import { transformEnvironmentsForAPI } from "Reducers/access/access.utils";
import { AsyncThunkOptionType } from "Reducers/types";

import type {
  ConsoleAccessRole,
  Invitation,
  InvitationEnvironmentType
} from "@packages/client";

export interface AddInvitationCallback {
  email: string;
  force?: boolean;
  projectId: string;
  adminRights: boolean;
  environmentTypes: {
    type: InvitationEnvironmentType;
    role: ConsoleAccessRole;
  }[];
  organizationId: string;
  invitation?: Invitation;
}

// Invite a user to a project
export const addInvitationThunk = createAsyncThunk<
  Invitation,
  AddInvitationCallback,
  AsyncThunkOptionType<{ errors: any; resendInvitation: boolean }>
>(
  "app/invitation/create",
  async (
    { email, force, projectId, adminRights, environmentTypes },
    { rejectWithValue }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    try {
      const result = await client.createInvitationWithEnvironmentTypes(
        email,
        projectId,
        adminRights ? "admin" : "viewer",
        transformEnvironmentsForAPI(environmentTypes),
        force
      );
      return new platformLib.entities.Invitation({
        ...result.data,
        projectId
      });
    } catch (err) {
      if (typeof err === "string") {
        let errors = JSON.parse(err);
        let resendInvitation = false;

        if (
          errors.error ===
          "A pending invitation already exists for this email address."
        ) {
          resendInvitation = true;
          errors = {};
        }

        if (errors.detail?.errors?.length) {
          errors = errors.detail?.errors[0];
        }
        return rejectWithValue({ errors, resendInvitation });
      }

      return rejectWithValue({
        errors: "unknown error when creating a new invitation",
        resendInvitation: false
      });
    }
  }
);
