import config from "Constants/api_config";
import { request } from "Libs/platform";
import { getFeatureFlag } from "Reducers/featureFlags";

import type { SetupOptions } from "Api/types/SetupOptions";

export type GetSetupOptionsRequestArguments =
  | {
      organizationId?: string;
      template?: string;
    }
  | undefined;

export type GetSetupOptionsRequestParams = {
  environments: number;
  storage: number;
  user_licenses: number;
  options_url?: string;
  options_custom?: {
    initialize: {
      repository?: string;
      profile?: string;
    };
  };
};

export const getSetupOptionsRequestParams = (options?: {
  template?: string;
  options_custom?: any;
}): GetSetupOptionsRequestParams => {
  return {
    environments: 3,
    storage: 5120,
    user_licenses: 1,
    ...options
  };
};

export const getSetupOptionsRequest = ({
  template,
  organizationId,
  ...others
}: GetSetupOptionsRequestArguments = {}): Promise<SetupOptions> => {
  const optionsParams = getSetupOptionsRequestParams({
    template,
    ...others
  });

  const organizationEnabled = getFeatureFlag("ENABLE_ORGANIZATION");

  if (organizationId && organizationEnabled) {
    return request(
      `${config.api_url}/organizations/${organizationId}/setup/options`,
      "GET",
      optionsParams
    );
  }

  return request(`${config.api_url}/setup/options`, "POST", optionsParams);
};
