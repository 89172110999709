import { createAsyncThunk } from "@reduxjs/toolkit";
import localForage from "localforage";

import logger from "Libs/logger";
import client from "Libs/platform";
import { organizationsSelector } from "Reducers/organization";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Organization, Project } from "@packages/client";

const updateDeletedProjectsCache = async (
  projectIds: string[],
  deletedProjectIdsCache: string[]
) => {
  const projectsWithDeletionInProgress: string[] =
    deletedProjectIdsCache.filter(id => projectIds.includes(id));
  await localForage.setItem(
    "deletedProjectIds",
    projectsWithDeletionInProgress
  );
};

export const loadAllProjects = createAsyncThunk<
  {
    projects: Project[];
    organizations: Record<string, Organization | undefined>;
  },
  undefined,
  AsyncThunkOptionType
>(
  "app/project_reducer/load_all_projects",
  async (_, { rejectWithValue, getState }) => {
    try {
      const projects = (await client.getProjects()) || [];
      const projectIds = projects.map(project => project.id);

      const deletedProjectIds =
        (await localForage.getItem<string[]>("deletedProjectIds")) || [];

      const availableProjects = projects.filter(
        project => !deletedProjectIds.includes(project.id)
      );

      const organizations = organizationsSelector(getState());

      updateDeletedProjectsCache(projectIds, deletedProjectIds);

      return {
        projects: availableProjects,
        organizations
      };
    } catch (err) {
      logger(err, {
        action: "projects_load"
      });

      return rejectWithValue(err);
    }
  }
);
