import { createSlice, SerializedError } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import {
  mergeUserAccessWithRefUser,
  UserAccessWithUserInformation
} from "./normalizeInput";
import { getUserAccessByUserIdThunk } from "./thunks/getUserAccessByUserId.thunk";
import { listUserAccessesByProjectIdThunk } from "./thunks/listUserAccessesByProjectId.thunk";

export type UserAccessesState = {
  data: Array<UserAccessWithUserInformation>;
  isLoading: boolean;
  next?: string;
  error?: SerializedError;
};

export type UserAccessState = {
  data?: UserAccessWithUserInformation;
  isLoading: boolean;
  error?: SerializedError;
};

export type UserAccessesSliceState = {
  byProjectId: Record<string, UserAccessesState | undefined>;
  byUserId: Record<string, UserAccessState | undefined>;
};

export const initialState: UserAccessesSliceState = {
  byProjectId: {},
  byUserId: {}
};

const projectAccesses = createSlice({
  name: "projectAccesses",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listUserAccessesByProjectIdThunk.pending, (state, action) =>
      setDeep(
        state,
        ["byProjectId", action.meta.arg.projectId, "isLoading"],
        true
      )
    );
    builder.addCase(
      listUserAccessesByProjectIdThunk.fulfilled,
      (state, action) => {
        const projectId = action.meta.arg.projectId;
        const { accesses, userData } = action.payload;
        setDeep(state, ["byProjectId", projectId, "isLoading"], false);
        setDeep(
          state,
          ["byProjectId", projectId, "next"],
          accesses._links.next?.href
        );
        const data = Object.values(userData)
          .map(user => {
            const access = accesses.items.find(
              element => element.user_id === user.id
            );
            return mergeUserAccessWithRefUser(user, access);
          })
          .filter(value => !!value);
        setDeep(state, ["byProjectId", projectId, "data"], data);
      }
    );
    builder.addCase(
      listUserAccessesByProjectIdThunk.rejected,
      (state, action) =>
        setDeep(
          state,
          ["byProjectId", action.meta.arg.projectId, "error"],
          action.error
        )
    );

    builder.addCase(getUserAccessByUserIdThunk.pending, (state, action) => {
      const userId = action.meta.arg.userId;
      setDeep(state, ["byUserId", userId, "isLoading"], true);
      setDeep(state, ["byUserId", userId, "error"], undefined);
    });
    builder.addCase(getUserAccessByUserIdThunk.fulfilled, (state, action) => {
      const userId = action.meta.arg.userId;
      setDeep(state, ["byUserId", userId, "isLoading"], false);
      setDeep(
        state,
        ["byUserId", userId, "data"],
        mergeUserAccessWithRefUser(
          action.payload.user[userId],
          action.payload.access
        )
      );
    });
    builder.addCase(getUserAccessByUserIdThunk.rejected, (state, action) => {
      setDeep(
        state,
        ["byUserId", action.meta.arg.userId, "error"],
        action.error
      );
    });
  }
});

export const userAccessReducer = projectAccesses.reducer;
