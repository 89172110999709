import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Domain, Project } from "@packages/client";

export const getDomainsThunk = createAsyncThunk<
  Domain[] | undefined,
  { project: Project },
  AsyncThunkOptionType<{ error: Error }>
>("app/project/domains/get", async ({ project }, { rejectWithValue }) => {
  try {
    const domains = await project.getDomains();
    return domains;
  } catch (err) {
    if (err instanceof Error) {
      logger(err.message, {
        action: "get_domains",
        meta: {
          projectId: project?.id
        }
      });
      return rejectWithValue({ error: err });
    }
  }
});
