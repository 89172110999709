import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { setUserAttributesDrift } from "Reducers/app";
import { meIdSelector } from "Reducers/app/selectors";
import { setThemeFromProfile } from "Reducers/app/theme";

import type { AccountsProfile, AuthUser } from "@packages/client";
import type { AsyncThunkOptionType } from "Reducers/types";

export const loadUserProfile = createAsyncThunk<
  AccountsProfile & AuthUser,
  string | undefined,
  AsyncThunkOptionType
>(
  "app/profile/load",
  async (userId, { rejectWithValue, dispatch, getState }) => {
    // load a user by id or load the current user
    const id = userId || meIdSelector(getState());
    if (!id) {
      return rejectWithValue("No id found");
    }
    try {
      const [accountsProfile, authUser] = await Promise.all([
        client.getUserProfile(id),
        client.getUser(id)
      ]);
      setUserAttributesDrift({ user: authUser });

      const profile = {
        ...accountsProfile,
        ...authUser
      } as AccountsProfile & AuthUser;

      // At the moment, the accounts profile picture should take precedence over
      // the auth user picture. This is because the accounts profile picture is
      // the one that is used in the UI to update and delete. Auth user picture
      // is synced with the accounts profile picture and may take a moment to
      // update.
      profile.picture = accountsProfile.picture;
      // last_name and first_name can be undefined when the user creates the
      // account for the first time because they got invited by email.
      profile.display_name = [authUser.first_name, authUser.last_name].join(
        " "
      );
      profile.username = authUser.username;

      dispatch(setThemeFromProfile(profile));
      return profile;
    } catch (error) {
      if (error instanceof Error)
        logger(error, {
          action: "LOAD_PROFILE_FAILURE"
        });

      return rejectWithValue(error);
    }
  }
);
