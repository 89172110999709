import styled, { css as styledCSS } from "styled-components";

import { Link } from "../Link";

export const Icon = styled.span`
  display: inline-flex;
  margin: 4px;
  svg {
    fill: inherit;
  }
`;

export interface TextProps {
  hasIcon: boolean;
}

export const Text = styled.span<TextProps>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;

  transition-duration: 0.25s;
  transition-property: border-color, box-shadow, color;
  font-weight: 400;
  color: inherit;
  background-color: transparent;
  position: relative;

  :after {
    transition-duration: 0.25s;
    transition-property: background-color;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: transparent;
  }

  ${({ hasIcon }) =>
    !hasIcon &&
    styledCSS`
      padding-left: 8px;
    `}
`;

export const Layout = styled(Link)`
  padding-top: 2px;
  padding-right: 12px;
  padding-bottom: 2px;
  padding-left: 2px;
  height: 32px;

  // Align left
  margin-left: -8px;
  margin-bottom: 16px;

  color: var(--mode-text-neutral-default-weak);

  /* anchor style resets */
  text-decoration: none;
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  outline: none;
  border: 1px solid transparent;
  border-radius: 2px;

  :after {
    content: none;
  }

  svg [fill] {
    transition-duration: 0.25s;
    transition-property: fill;
    fill: var(--mode-vector-neutral-strong);
  }

  :focus {
    border-color: var(--mode-border-primary-strong);
    box-shadow:
      0px 0px 2px var(--mode-border-primary-strong),
      inset 0 0 2px var(--mode-border-primary-strong);
  }

  :hover {
    color: var(--mode-text-primary-default);
    svg [fill] {
      fill: var(--mode-vector-primary-default);
    }
  }

  :hover:not(:focus) ${Text}:after {
    background-color: var(--mode-text-primary-default);
  }

  :hover svg [fill] {
    fill: var(--mode-vector-primary-default);
  }

  :active {
    border-color: transparent;
    box-shadow: none;
    color: var(--mode-text-primary-strong);
  }

  :active ${Text}:after {
    background-color: var(--mode-border-primary-strong);
  }

  :active svg [fill] {
    fill: var(--mode-vector-primary-strong);
  }
`;
