import { createAsyncThunk } from "@reduxjs/toolkit";

import type { Project } from "@packages/client";

interface GetProjectAccessesAction {
  project: Project;
  organizationId: string;
}

export const getProjectAccessesThunk = createAsyncThunk(
  "app/project/access/get_project_accesses",
  async ({ project }: GetProjectAccessesAction) => {
    if (!project) return [];

    const accesses = await project.getUsers();
    return accesses;
  }
);
