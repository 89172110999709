// In this module we track  all the redux actions that trigger a push
// of data to google analytics
import localForage from "localforage";

import { hasSafeRole } from "Libs/utils";
import { getMe } from "Reducers/app";
import { getFeatureFlag } from "Reducers/featureFlags";
import { CREATE_ORGANIZATION_SUCCESS } from "Reducers/organization";
import { loadUserProfile } from "Reducers/profile";
import {
  goToWizardStep,
  prevWizardStep,
  toggleProjectWizard,
  nextWizardStep
} from "Reducers/project/wizard";
import { addSubscription } from "Reducers/subscription";
import { saveSetupOptionsConfig } from "src/reducers/setupOptions/setupOptions.slice";

import { selectWizardStepId, getWizardStepFromStore } from "./selectors";

// Qualifyes a user based on parameters that marketing gives us
// Right now we only distinguish between platform employee and regular
// users, since with the introduction of organizations, the way we
// track trials has to change (marketing is still deciding how)
function getUserType(user) {
  if (user.roles && hasSafeRole(user.roles)) {
    return "Platformer";
  }

  // This no longer works, trials are now attached to organizations
  if (user.trial) {
    return "Trial User";
  }

  return "Customer";
}

// Here we map events that trigger some push of data to Google Tag Manager
// As a key put the name of the redux action that we want to act as a
// trigger of a push to the data layer, the value is a function that receives
// the action and the store (if needed) and returns a GTM event object
export const GTMEvents = {
  [getMe.fulfilled]: action => ({
    event: "userLoaded",
    userType: getUserType(action.payload),
    userId: action?.payload?.id
  }),
  [addSubscription.fulfilled.type]: () => ({
    event: "free trial started"
  }),
  [toggleProjectWizard.fulfilled.type]: action => ({
    event: action.payload ? "Wizard - Opened" : "Wizard - Closed"
  }),
  [saveSetupOptionsConfig().type]: action => {
    // We reuse this action during the various steps of the project creation
    // so depending on the payload we will send a different analytics event
    let trackableEvent = {};

    if (action?.payload?.template) {
      trackableEvent = {
        event: "Project - Template Selected",
        template: action?.payload?.template
      };
    }

    if (action?.payload?.region) {
      trackableEvent = {
        event: "Project - Region Selected",
        region: action?.payload?.region?.id
      };
    }

    return trackableEvent;
  },
  [goToWizardStep().type]: (action, store) => ({
    event: "Wizard - Jumped to Step",
    // steps are an array in the store, so they start at index 0
    // but in the action we pass the step number that starts at
    // index 1, hence the -1
    step: selectWizardStepId(store, action.payload?.step - 1)
  }),
  [prevWizardStep().type]: (_action, store) => ({
    event: "Wizard - Step Viewed",
    step: getWizardStepFromStore(store, "back")
  }),
  [nextWizardStep().type]: (_action, store) => ({
    event: "Wizard - Step Viewed",
    step: getWizardStepFromStore(store)
  }),
  [CREATE_ORGANIZATION_SUCCESS]: (action, store) => {
    const organizationState = store.getState().organization;
    const isFirstOrganization =
      Object.keys(organizationState.data).length === 0;
    if (isFirstOrganization) {
      return {
        event: "firstorgcreated",
        value: "yes"
      };
    }
  },
  [loadUserProfile.fulfilled]: async ({ payload }) => {
    const DELAY = 5 * 60 * 1000; // 5mins
    if (new Date() - new Date(payload.created_at) < DELAY) {
      const isTriggered = await localForage.getItem("new_user_event_triggered");
      if (isTriggered !== true) {
        await localForage.setItem("new_user_event_triggered", true);
        return {
          event: "new user account created",
          enhanced_conversion_data: {
            email: payload.email
          }
        };
      }
    }
    return null;
  }
};

async function createGTMEvent(action, store) {
  const eventHandler = GTMEvents[action.type];
  if (!eventHandler) {
    return null;
  }

  const result = eventHandler(action, store);

  // Check if the result is a promise before awaiting it
  if (result instanceof Promise) {
    return await result;
  }

  return result;
}

export default async function googleTagManagerTracker(action, store) {
  const GTMEvent = await createGTMEvent(action, store);

  if (GTMEvent !== null) {
    window?.dataLayer?.push(GTMEvent);
  }
}
