import React from "react";

import { AddButton as BaseAddButton, type AddButtonProps } from "@packages/ds";
import { FormattedMessage } from "react-intl";

const AddButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  AddButtonProps
>((props, ref) => (
  <BaseAddButton ref={ref} {...props}>
    {props.children || <FormattedMessage id="add" />}
  </BaseAddButton>
));

AddButton.displayName = "AddButton";

export default AddButton;
