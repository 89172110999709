import client from "Libs/platform";
import { AppDispatch } from "Store/configureStore";

import type { TicketPriority } from "@packages/client";

export interface PrioritiesState {
  isLoading: boolean;
  priorities?: TicketPriority[];
  error?: unknown;
}

const initialState: PrioritiesState = {
  isLoading: false
};

// Actions
const LOAD_START = "tickets/priorities/load_start";
const LOAD_SUCCESS = "tickets/priorities/load_success";
const LOAD_FAILURE = "tickets/priorities/load_failure";

// Action creators
export const loadStart = () => ({ type: LOAD_START });
export const loadFailure = (error?: unknown) => ({ type: LOAD_FAILURE, error });
export const loadSuccess = (priorities: TicketPriority[]) => ({
  type: LOAD_SUCCESS,
  payload: priorities
});

// Thunks
export const load =
  (subscriptionId: string, categoryName: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(loadStart());
    try {
      const priorities = await client.getTicketPriorities(
        subscriptionId,
        categoryName
      );
      dispatch(loadSuccess(priorities));
    } catch (error) {
      if (error instanceof Object) {
        dispatch(loadFailure(error));
      }
    }
  };

interface PrioritiesAction {
  type: string;
  payload?: TicketPriority[];
  error?: unknown;
}

export const reducer = (state = initialState, action?: PrioritiesAction) => {
  switch (action?.type) {
    case LOAD_START:
      return { ...state, isLoading: true };
    case LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        priorities: action.payload || []
      };
    case LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error:
          action.error ||
          new Error("There was an error loading the priorities.")
      };
    default:
      return state;
  }
};

export default reducer;
