import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { AsyncThunkOptionType } from "Reducers/types";

import type {
  ConsoleAccessRole,
  EnvironmentAccessRole,
  ProjectAccess
} from "@packages/client";

function isDeletedEnvironmentTypeAccess(
  access: ConsoleAccessRole
): access is "no-access" {
  return access === "no-access";
}

export const updateEnvironmentTypeAccessThunk = createAsyncThunk<
  ProjectAccess | undefined,
  {
    projectId: string;
    environmentTypeId: string;
    accessId: string;
    role: EnvironmentAccessRole;
  },
  AsyncThunkOptionType
>(
  "app/project/environment_type/update_access",
  async (
    { projectId, environmentTypeId, accessId, role },
    { rejectWithValue }
  ) => {
    try {
      if (isDeletedEnvironmentTypeAccess(role)) {
        await client.deleteEnvironmentTypeAccess({
          projectId,
          environmentTypeId,
          accessId
        });
      } else {
        const updatedAccess = await client.updateEnvironmentTypeAccess({
          projectId,
          environmentTypeId,
          accessId,
          role
        });

        return updatedAccess;
      }
    } catch (err) {
      logger(err);
      return rejectWithValue(err);
    }
  }
);
