import {
  createAsyncThunk,
  createSlice,
  createSelector
} from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { environmentSelector } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Activity } from "@packages/client";
import type { RootState } from "Store/configureStore";

type ParamsType = {
  [x: string]: any;
};
type MergeEnvironmentActionPropType = {
  organizationId: string;
  projectId: string;
  environmentId: string;
  body?: ParamsType;
};

export type EnvironmentBranchState = {
  loading: boolean;
  errors?: string;
  data: {
    [organizationId: string]: {
      [projectId: string]: {
        [environmentId: string]: Activity;
      };
    };
  };
};

export const merge = createAsyncThunk<
  Activity,
  MergeEnvironmentActionPropType,
  AsyncThunkOptionType
>(
  "Environment/Merge",
  async (
    { organizationId, projectId, environmentId, body },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment = environmentSelector(getState(), {
        organizationId,
        projectId,
        environmentId
      });

      if (!environment) return rejectWithValue("Environment not found");

      const merge = await environment.merge(body);

      return merge;
    } catch (err: any) {
      if (![404, 403].includes(err.code))
        logger(err, {
          action: "merge",
          payload: merge,
          meta: {
            organizationId,
            projectId,
            environmentId
          }
        });
      return rejectWithValue({ ...err, message: err.message });
    }
  }
);

const environmentBranchSlice = createSlice({
  name: "merge",
  initialState: {
    loading: false,
    errors: undefined,
    data: {}
  } as Partial<EnvironmentBranchState>,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(merge.pending, state => {
        state.loading = true;
        state.errors = undefined;
      })
      .addCase(merge.fulfilled, (state, { meta, payload }) => {
        state.loading = false;
        state.errors = undefined;
        setDeep(
          state,
          [
            "data",
            meta.arg.organizationId,
            meta.arg.projectId,
            meta.arg.environmentId
          ],
          payload
        );
      })
      .addCase(merge.rejected, (state, { error }) => {
        state.errors = error.message;
        state.loading = false;
      });
  }
});

export default environmentBranchSlice.reducer;

export const mergeStateSelector = (state: RootState) => state.merge;

export const mergeIsLoadingSelector = createSelector(
  mergeStateSelector,
  mergeState => mergeState.loading
);

export const mergeErrorsSelector = createSelector(
  mergeStateSelector,
  mergeState => mergeState.errors
);
