import React from "react";

import {
  SecondaryLink as BaseSecondaryLink,
  type SecondaryLinkProps
} from "@packages/ds";
import { Link as RouterLink } from "react-router-dom";

const SecondaryLink = React.forwardRef<HTMLAnchorElement, SecondaryLinkProps>(
  (props, ref) => {
    if ("to" in props) {
      return <BaseSecondaryLink ref={ref} {...props} as={RouterLink} />;
    }
    return <BaseSecondaryLink ref={ref} {...props} />;
  }
);

SecondaryLink.displayName = "SecondaryLink";

export { SecondaryLink as default };
export type { SecondaryLinkProps };
