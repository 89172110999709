import { apiProxyRequest as request } from "Api/apiProxyRequest";
import { Settings } from "Reducers/environment/service/types";

export const setAutoscalingConfiguration = async (
  projectId: string,
  environmentId: string,
  settings: Settings
) =>
  request<Settings>(
    `/projects/${projectId}/environments/${environmentId}/autoscaling/settings`,
    "PATCH",
    settings
  );
