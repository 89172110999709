import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { AsyncThunkOptionType } from "Reducers/types";

import { setActivityFilterNames, getActivityTypes } from "../activity.slice";
import { LoadIntegrationActivities } from "../types";

import type { Activity } from "@packages/client";

export const loadIntegrationActivities = createAsyncThunk<
  {
    activities: Activity[];
    hasMore: boolean;
    organizationDescriptionId: string;
    projectDescriptionId: string;
    integrationDescriptionId: string;
    integrationActivity: string[];
  },
  LoadIntegrationActivities,
  AsyncThunkOptionType
>(
  "app/activity/integration/load",
  async (
    {
      organizationDescriptionId,
      projectDescriptionId,
      integrationDescriptionId,
      filterNames,
      context
    },
    { dispatch, rejectWithValue }
  ) => {
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    // Don't make any request when no filter is selected
    if (!filters.length) {
      return {
        activities: [],
        hasMore: false,
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId,
        integrationActivity: []
      };
    }
    try {
      const encodedIntegrationId = encodeURIComponent(integrationDescriptionId);
      const activities = await client.getIntegrationActivities(
        projectDescriptionId,
        encodedIntegrationId,
        filters
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      const activitiesForIntegration = activities.map(activity => activity.id);

      return {
        activities,
        hasMore,
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId,
        integrationActivity: activitiesForIntegration
      };
    } catch (err: any) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: "integration_load_activities",
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId
          }
        });
      }
      return rejectWithValue(err);
    }
  }
);
