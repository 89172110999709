import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { loadOneProject, updateProject } from "Reducers/project/project";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Domain } from "@packages/client";

export const deleteDomainThunk = createAsyncThunk<
  Domain | undefined,
  {
    domain: Domain;
    defaultDomain?: Domain;
    projectId: string;
    organizationId: string;
  },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/domain/delete",
  async (
    { domain, defaultDomain, projectId, organizationId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let newDefault;

      if (defaultDomain) {
        await dispatch(
          updateProject({
            organizationDescriptionId: organizationId,
            projectDescriptionId: projectId,
            data: { default_domain: defaultDomain.id }
          })
        );

        const result = await defaultDomain.update({ is_default: true });
        newDefault = result.getEntity();
      }

      await domain.delete();

      await dispatch(
        loadOneProject({
          projectId,
          organizationId
        })
      );

      return newDefault;
    } catch (err) {
      if (err instanceof Error) {
        logger(
          { errMessage: err.message, domainId: domain.id },
          { action: "projectDeleteDomain" }
        );

        return rejectWithValue({ error: err });
      }
    }
  }
);
