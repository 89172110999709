import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";

import { loadComments } from "../";

import type { Comment } from "@packages/client";
import type { AsyncThunkOptionType } from "Reducers/types";

export const sendComment = createAsyncThunk<
  Comment,
  Comment,
  AsyncThunkOptionType<{ errors: unknown }>
>(
  "tickets/comments/send_comment",
  async (comment, { rejectWithValue, dispatch }) => {
    try {
      const commentResult = (await client.sendComment(
        comment
      )) as unknown as Comment;

      dispatch(loadComments({ ticketId: comment.ticket_id }));
      return commentResult;
    } catch (err: unknown) {
      logger(err, {
        action: "TICKETS_COMMENTS_SEND",
        meta: {
          comment
        }
      });
      return rejectWithValue({ errors: err });
    }
  }
);
