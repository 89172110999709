import { ACTIVITY_TYPES } from "Constants/constants";

const activityTypes = {
  git: { label: "git", types: [ACTIVITY_TYPES.ENVIRONMENT.PUSH] },
  merge: { label: "merge", types: [ACTIVITY_TYPES.ENVIRONMENT.MERGE] },
  sync: { label: "sync", types: [ACTIVITY_TYPES.ENVIRONMENT.SYNCHRONIZE] },
  branch: { label: "branch", types: [ACTIVITY_TYPES.ENVIRONMENT.BRANCH] },
  crons: { label: "crons", types: [ACTIVITY_TYPES.ENVIRONMENT.CRON] },
  backup: {
    label: "backup",
    types: [
      ACTIVITY_TYPES.ENVIRONMENT.BACKUP,
      ACTIVITY_TYPES.ENVIRONMENT.BACKUP_DELETE,
      ACTIVITY_TYPES.ENVIRONMENT.RESTORE
    ]
  },
  redeploy: { label: "redeploy", types: [ACTIVITY_TYPES.ENVIRONMENT.REDEPLOY] },
  resource_allocation_filter: {
    label: "resource_allocation_filter",
    types: [
      ACTIVITY_TYPES.ENVIRONMENT.RESOURCES.UPDATE,
      ACTIVITY_TYPES.ENVIRONMENT.ALERT
    ]
  },
  certificate: {
    label: "certificate",
    types: [ACTIVITY_TYPES.ENVIRONMENT.CERTIFICATE_RENEWAL]
  },
  system: {
    label: "system",
    types: [
      ACTIVITY_TYPES.PROJECT.CREATE,
      ACTIVITY_TYPES.PROJECT.RESUME,
      ACTIVITY_TYPES.PROJECT.SUSPEND,
      ACTIVITY_TYPES.PROJECT.MODIFY_TITLE,
      ACTIVITY_TYPES.PROJECT.CLEAR_BUILD_CACHE,
      ACTIVITY_TYPES.ENVIRONMENT.ROUTE.CREATE,
      ACTIVITY_TYPES.ENVIRONMENT.ROUTE.DELETE,
      ACTIVITY_TYPES.ENVIRONMENT.ROUTE.UPDATE,
      ACTIVITY_TYPES.ENVIRONMENT.ACCESS.ADD,
      ACTIVITY_TYPES.ENVIRONMENT.ACCESS.REMOVE,
      ACTIVITY_TYPES.ENVIRONMENT.DELETE,
      ACTIVITY_TYPES.ENVIRONMENT.INITIALIZE,
      ACTIVITY_TYPES.ENVIRONMENT.ACTIVATE,
      ACTIVITY_TYPES.ENVIRONMENT.DEACTIVATE,
      ACTIVITY_TYPES.ENVIRONMENT.MERGE_PR,
      ACTIVITY_TYPES.ENVIRONMENT.PAUSE,
      ACTIVITY_TYPES.ENVIRONMENT.RESUME,
      ACTIVITY_TYPES.ENVIRONMENT.UPDATE.SMTP,
      ACTIVITY_TYPES.ENVIRONMENT.UPDATE.HTTP_ACCESS,
      ACTIVITY_TYPES.ENVIRONMENT.SUBSCRIPTION.UPDATE,
      ACTIVITY_TYPES.ENVIRONMENT.VARIABLE.DELETE,
      ACTIVITY_TYPES.ENVIRONMENT.VARIABLE.UPDATE,
      ACTIVITY_TYPES.ENVIRONMENT.VARIABLE.CREATE
    ]
  },
  source_operation: {
    label: "source_operation",
    types: [ACTIVITY_TYPES.ENVIRONMENT.SOURCE_OPERATION]
  },
  runtime_operation: {
    label: "runtime_operation",
    types: [ACTIVITY_TYPES.ENVIRONMENT.OPERATION]
  },
  operations: {
    label: "all_operations",
    types: [
      ACTIVITY_TYPES.ENVIRONMENT.SOURCE_OPERATION,
      ACTIVITY_TYPES.ENVIRONMENT.OPERATION
    ]
  },
  domain: {
    label: "filter.domain",
    types: [
      ACTIVITY_TYPES.ENVIRONMENT.DOMAIN.CREATE,
      ACTIVITY_TYPES.ENVIRONMENT.DOMAIN.DELETE,
      ACTIVITY_TYPES.ENVIRONMENT.DOMAIN.UPDATE,
      ACTIVITY_TYPES.PROJECT.DOMAIN.CREATE,
      ACTIVITY_TYPES.PROJECT.DOMAIN.DELETE,
      ACTIVITY_TYPES.PROJECT.DOMAIN.UPDATE
    ]
  },
  integrations: {
    label: "filter.integration",
    types: [
      ACTIVITY_TYPES.INTEGRATION.GITLAB.REGISTER_HOOKS,
      ACTIVITY_TYPES.INTEGRATION.GITHUB.REGISTER_HOOKS,
      ACTIVITY_TYPES.INTEGRATION.WEBHOOK,
      ACTIVITY_TYPES.INTEGRATION.SCRIPT,
      ACTIVITY_TYPES.INTEGRATION.HEALTH.SLACK,
      ACTIVITY_TYPES.INTEGRATION.HEALTH.PAGERDUTY,
      ACTIVITY_TYPES.INTEGRATION.HEALTH.EMAIL,
      ACTIVITY_TYPES.INTEGRATION.HEALTH.WEBHOOK,
      ACTIVITY_TYPES.INTEGRATION.GITLAB.FETCH,
      ACTIVITY_TYPES.INTEGRATION.GITHUB.FETCH,
      ACTIVITY_TYPES.INTEGRATION.BITBUCKET.SERVER.REGISTER_HOOKS,
      ACTIVITY_TYPES.INTEGRATION.BITBUCKET.SERVER.FETCH,
      ACTIVITY_TYPES.INTEGRATION.BITBUCKET.FETCH,
      ACTIVITY_TYPES.INTEGRATION.BITBUCKET.REGISTER_HOOKS,
      ACTIVITY_TYPES.INTEGRATION.BLACKFIRE.WEBHOOK
    ]
  },
  upgrade: {
    label: "filter.upgrade",
    types: [ACTIVITY_TYPES.MAINTENANCE.UPGRADE]
  }
} as const;

type ActivityType = typeof activityTypes;

type ActivityTypeLabels =
  | ActivityType[keyof ActivityType]["label"]
  | "show_all";
type ActivityTypeValues =
  | ActivityType[keyof ActivityType]["types"][number]
  | "all_type";

export type ActivityFilterOptions = {
  value: ActivityTypeValues;
  label: ActivityTypeLabels;
}[];

export default activityTypes;
