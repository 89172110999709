import * as React from "react";

import { Link, LinkProps as RRDLinkProps } from "react-router-dom";
import { StyledComponentProps } from "styled-components";

import * as S from "./Button.styles";
import { LayoutProps, Variant, Content } from "./Button.types";

const getCapitalized = (
  capitalized: boolean | undefined | string
): "true" | "false" => {
  if (typeof capitalized === "boolean") {
    return capitalized.toString() as "true" | "false";
  }
  if (capitalized === "true") return "true";

  return "false";
};

type OnlyFirst<First, Second> = First & {
  [K in keyof Omit<Second, keyof First>]?: never;
};

type MergeTypes<
  TypesArray extends any[],
  Result = unknown
> = TypesArray extends [infer Head, ...infer Rest]
  ? MergeTypes<Rest, Result & Head>
  : Result;

type OneOf<
  TypesArray extends any[],
  Result = never,
  AllProperties = MergeTypes<TypesArray>
> = TypesArray extends [infer Head, ...infer Rest]
  ? OneOf<Rest, Result | OnlyFirst<Head, AllProperties>, AllProperties>
  : Result;

export type ButtonProps = StyledComponentProps<
  "button",
  any,
  LayoutProps,
  never
> & {
  analyticId?: string;
};

export type AnchorProps = StyledComponentProps<"a", any, LayoutProps, never> & {
  href: string;
  analyticId?: string;
};

export type RouterLinkProps = StyledComponentProps<
  typeof Link,
  any,
  RRDLinkProps & LayoutProps,
  never
> & {
  analyticId?: string;
};

export type PolymorphicProps = OneOf<
  [ButtonProps, AnchorProps, RouterLinkProps]
>;

type PolymorphicButton = {
  (props: PolymorphicProps): React.JSX.Element;
  displayName?: string;
};

export const isAnchor = (props: PolymorphicProps): props is AnchorProps =>
  !!props.href;

export const isReactRouterLink = (
  props: PolymorphicProps
): props is RouterLinkProps => !!props.to;

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  PolymorphicProps
>((props, ref) => {
  const defaults = {
    variant: props.variant || Variant.primary,
    content: props.content || Content.text,
    capitalized: getCapitalized(props.capitalized)
  };

  if (isReactRouterLink(props)) {
    const { analyticId, ...restProps } = props;
    return (
      <S.Layout
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        as={Link}
        {...restProps}
        {...defaults}
        data-heap-identifier={analyticId}
      />
    );
  }

  if (isAnchor(props)) {
    const { analyticId, ...restProps } = props;
    return (
      <S.Layout
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        as="a"
        target="_blank"
        rel="noopener"
        {...restProps}
        {...defaults}
        data-heap-identifier={analyticId}
      />
    );
  }

  const { analyticId, ...restProps } = props;
  return (
    <S.Layout
      ref={ref as React.ForwardedRef<HTMLButtonElement>}
      {...restProps}
      {...defaults}
      data-heap-identifier={analyticId}
    />
  );
}) as PolymorphicButton;

Button.displayName = "Button";
