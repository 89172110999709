import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { hasHtml } from "Libs/utils";
import { RootState } from "Store/configureStore";

import type { SshKey } from "@packages/client";

export const loadSshKeys = createAsyncThunk(
  "app/users/sshkeys/load",
  async () => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const keys = await client.getSshKeys();
    return keys;
  }
);

type AddSshKeyThunkArgType = {
  sshKey: { value: string; title: string };
};
type AddSshKeyThunkReturnType = object;
export const addSshKey = createAsyncThunk<
  AddSshKeyThunkReturnType,
  AddSshKeyThunkArgType
>("app/users/sshKeys/add", async ({ sshKey }, { rejectWithValue }) => {
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const key = await client.addSshKey(sshKey.value, sshKey.title);
    return key;
  } catch (err: any) {
    if (![404, 403, 400].includes(err.code) && !hasHtml(err)) {
      logger(err, {
        action: "user_add_sshkey"
      });
    }
    return rejectWithValue({ errors: err });
  }
});
interface DeleteSshKey {
  id: string;
}
export const deleteSshKey = createAsyncThunk(
  "app/users/sshKeys/delete",
  async ({ id }: DeleteSshKey, { getState, rejectWithValue }) => {
    try {
      const sshKey = (getState() as RootState).userSshKey.data[id];

      if (!sshKey) {
        throw Error("Ssh key not found");
      }
      await sshKey.delete();
      return sshKey;
    } catch (err: any) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "user_delete_sshkey"
        });
      }

      return rejectWithValue({ errors: err });
    }
  }
);

export type SshKeyState = Readonly<{
  data: any;
  loading: boolean;
  status?: "added" | "deleted" | "idle" | "pending" | "rejected" | "updated";
  editedLine?: boolean;
  error?: any;
}>;

const initialState: SshKeyState = { data: {}, loading: false, status: "idle" };

const sshKeys = createSlice({
  name: "sshkeys",
  initialState,
  reducers: {
    editLine(state, action) {
      return { ...state, editedLine: action.payload.index };
    },
    cancelAddSshKey(state) {
      return { ...state, editedLine: false, error: {} };
    }
  },
  extraReducers: builder => {
    builder
      // GET
      .addCase(loadSshKeys.pending, state => {
        delete state.error;
        state.loading = true;
      })
      .addCase(loadSshKeys.fulfilled, (state, action) => {
        state.data = action.payload.reduce(
          (list, key) => {
            if (key.key_id) {
              setDeep(list, [key.key_id], key);
            }
            return list;
          },
          {} as {
            [sshKeyId: string]: SshKey | undefined;
          }
        );

        state.loading = false;
      })
      .addCase(loadSshKeys.rejected, (state, action) => {
        const { errors } = action.payload as { errors: string };

        state.error = errors;
        state.loading = false;
      })

      // ADD
      .addCase(addSshKey.pending, state => {
        delete state.error;
        state.loading = true;
        state.status = "pending";
      })
      .addCase(addSshKey.fulfilled, (state, action) => {
        const { key_id } = action.payload as { key_id: string };

        setDeep(state, ["data", key_id], action.payload);
        state.editedLine = false;
        state.loading = false;
        state.status = "added";
      })
      .addCase(addSshKey.rejected, (state, action) => {
        const { errors } = action.payload as { errors: string };
        state.error = errors;
        state.loading = false;
        state.status = "rejected";
      })

      // DELETE
      .addCase(deleteSshKey.pending, state => {
        delete state.error;
        state.loading = true;
      })
      .addCase(deleteSshKey.fulfilled, (state, action) => {
        delete state.data?.[action.payload.key_id];
        state.loading = false;
      })
      .addCase(deleteSshKey.rejected, (state, action) => {
        const { errors } = action.payload as { errors: string };
        state.error = errors;
      });
  }
});

export const { editLine, cancelAddSshKey } = sshKeys.actions;
export default sshKeys.reducer;
