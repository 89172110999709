import React from "react";

import {
  CopyButton as BaseCopyButton,
  type CopyButtonProps as BaseCopyButtonProps
} from "@packages/ds";
import { useIntl } from "react-intl";

export type CopyButtonProps = Omit<
  BaseCopyButtonProps,
  "successText" | "actionText"
>;

const CopyButton = (props: CopyButtonProps) => {
  const intl = useIntl();

  return (
    <BaseCopyButton
      {...props}
      aria-label="copy-button"
      successText={intl.formatMessage({ id: "copied" })}
      actionText={intl.formatMessage({ id: "copy" })}
      title={props.title || intl.formatMessage({ id: "copy" })}
    />
  );
};

export default CopyButton;
