import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { AsyncThunkOptionType } from "Reducers/types";

import type { EnvironmentAccessRole, ProjectAccess } from "@packages/client";

export const createEnvironmentTypeAccessThunk = createAsyncThunk<
  ProjectAccess,
  {
    projectId: string;
    environmentTypeId: string;
    email?: string;
    role: EnvironmentAccessRole;
    user?: string;
  },
  AsyncThunkOptionType
>(
  "app/projectEnvironmentType/add_access",
  async (
    { projectId, environmentTypeId, email, role, user },
    { rejectWithValue }
  ) => {
    try {
      const newAccess = await client.createEnvironmentTypeAccess({
        projectId,
        environmentTypeId,
        email,
        role,
        user
      });

      return newAccess;
    } catch (err) {
      logger(err);
      return rejectWithValue(err);
    }
  }
);
