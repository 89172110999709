import config from "console_config";
import apiConfig from "Constants/api_config";
import { request } from "Libs/platform";

import type { SetupCatalog } from "Api/types/SetupCatalog";

export type GetSetupCatalogRequestArguments = {
  organizationId: string;
};

export const getSetupCatalogRequest = ({
  organizationId
}: GetSetupCatalogRequestArguments): Promise<SetupCatalog[]> => {
  const vendor = config.CUSTOM_CONSOLE_ID
    ? `?vendor=${config.CUSTOM_CONSOLE_ID}`
    : "";

  return request(
    `${apiConfig.api_url}/organizations/${organizationId}/setup/catalog${vendor}`,
    "GET"
  );
};
