import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import StreamWorker from "Libs/stream.worker?worker";

import type { Activity } from "@packages/client";

export const loadLogFromActivity = createAsyncThunk(
  "app/activity/stream",

  async (
    {
      activity,
      retryNumber = 0
    }: {
      activity: Activity;
      retryNumber?: number;
    },
    { dispatch }
  ) => {
    const worker = new StreamWorker();

    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const accessToken = await client.getAccessToken();

    worker.postMessage({
      url: `${activity.getLink("log")}?max_items=0&max_delay=1000`,
      accessToken,
      maxBatchNumber: 1000
    });
    worker.onmessage = async e => {
      const { data } = e;

      if (!data.success) {
        if (
          retryNumber < 3 &&
          (data.error === 401 || data.error === "Failed to fetch")
        ) {
          // Re-authenticate
          await client.reAuthenticate();

          // Retry
          dispatch(
            loadLogFromActivity({ activity, retryNumber: ++retryNumber })
          );
          return;
        }

        logger(
          {
            errorMessage: data.error,
            activityId: activity.id
          },
          {
            action: "loadLogFromActivity"
          }
        );
        return;
      }

      dispatch(
        streamFinished({
          activityId: activity.id,
          stream: data.fragmentSet,
          done: data.done
        })
      );
    };
  }
);

export type Log = {
  _id?: number;
  seal: boolean;
  data: {
    message: string;
    timestamp: Date | string | number;
  };
};

type LogState = {
  [activityId: string]:
    | {
        loading?: boolean;
        status?: "pending" | "fulfilled" | "rejected";
        log?: Log[];
        streamEnded?: boolean;
        errors?: unknown;
      }
    | undefined;
};

const initialState: LogState = {};

const logs = createSlice({
  name: "logs",
  initialState,
  reducers: {
    streamFinished(state, { payload }) {
      const { activityId, done, stream } = payload;
      setDeep(state, [activityId, "loading"], false);
      setDeep(state, [activityId, "status"], "fulfilled");
      setDeep(state, [activityId, "streamEnded"], done);
      setDeep(state, [activityId, "log"], stream);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadLogFromActivity.pending, (state, action) => {
        const { activity } = action.meta.arg;
        setDeep(state, [activity.id, "status"], "pending");
        setDeep(state, [activity.id, "loading"], true);
      })
      .addCase(loadLogFromActivity.rejected, (state, action) => {
        const { payload, meta } = action;
        const activity = meta.arg.activity;
        setDeep(state, [activity.id, "status"], "rejected");
        setDeep(state, [activity.id, "loading"], false);
        setDeep(state, [activity.id, "errors"], payload);
      });
  }
});

export const { streamFinished } = logs.actions;
export default logs.reducer;
