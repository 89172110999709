import { RefUser } from "Api/ref/User.types";
import { ProjectUserAccess } from "Api/userAccess/projectUserAccess.types";

import type { Invitation } from "@packages/client";
import type { EnvironmentTypeAccess } from "Api/organizations/member/projectAccess/ProjectAccess.types";

/** Contains both user information and access information. */
export type UserAccessWithUserInformation = {
  id: string;
  permissions: Array<EnvironmentTypeAccess>;
  email: string;
  name: string;
  picture: string;
};

export const mergeUserAccessWithRefUser = (
  user: RefUser,
  access?: ProjectUserAccess
): UserAccessWithUserInformation | undefined =>
  access && {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    id: user.id,
    picture: user.picture,
    permissions: access.permissions
  };

export const normalizeProjectAccessInvitation = (invitation: Invitation) => ({
  name: "",
  email: invitation.email,
  id: invitation.id,
  permissions:
    invitation.environments?.map(
      permission => `${permission.type || permission.id}:${permission.role}`
    ) ?? []
});
