import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import { RootState } from "Store/configureStore";

import type { Activity } from "@packages/client";

export interface RuntimeOperationsStateType {
  loading: boolean;
  error?: unknown;
}

type RuntimeOperationsPropType = {
  service: string;
  operation: string;
  environmentId: string;
  projectId: string;
  deploymentId: string;
  onRuntimeOperationCompleted: (activity: Activity) => void;
};

export const runRuntimeOperation = createAsyncThunk(
  "app/project/environment/runtimeOps/run",
  async (
    {
      service,
      operation,
      environmentId,
      projectId,
      deploymentId,
      onRuntimeOperationCompleted
    }: RuntimeOperationsPropType,
    { rejectWithValue }
  ) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const result = await client.runRuntimeOperationDeployment({
        service,
        operation,
        environmentId,
        projectId,
        deploymentId
      });
      onRuntimeOperationCompleted?.(result);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const runtimeOperations = createSlice({
  name: "runtimeOperations",
  initialState: {
    loading: false,
    error: null
  } as Partial<RuntimeOperationsStateType>,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(runRuntimeOperation.pending, state => {
        state.loading = true;
        delete state.error;
      })
      .addCase(runRuntimeOperation.fulfilled, state => {
        state.loading = false;
        delete state.error;
      })
      .addCase(runRuntimeOperation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const selectIsLoadingRuntimeOperation = createSelector(
  (state: RootState) => state.runtimeOperations,
  runtimeOperations => runtimeOperations.loading
);

export const selectRuntimeOperationError = createSelector(
  (state: RootState) => state.runtimeOperations,
  runtimeOperations => runtimeOperations.error
);

export default runtimeOperations.reducer;
