import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Environment, EnvironmentDomain, Project } from "@packages/client";

export const getEnvironmentsDomainsThunk = createAsyncThunk<
  EnvironmentDomain[] | undefined,
  { project: Project; environments: Environment[] },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/environments/domains/get",
  async ({ project, environments }, { rejectWithValue }) => {
    try {
      const values: any = [];

      for (const env of environments || []) {
        const encodedEnvironmentId = encodeURIComponent(env?.id);

        const environmentDomains =
          await project.getEnvironmentDomains(encodedEnvironmentId);

        values.push({ environmentDomains, environmentId: env?.id });
      }

      return values?.flat();
    } catch (err) {
      if (err instanceof Error) {
        logger(err.message, {
          action: "get_environments_domains",
          meta: {
            projectId: project?.id
          }
        });
        return rejectWithValue({ error: err });
      }
    }
  }
);
