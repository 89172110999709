import { createAsyncThunk } from "@reduxjs/toolkit";

import type { Invitation } from "@packages/client";

interface DeleteInvitationCallback {
  invitation: Invitation;
  projectId: string;
  organizationId: string;
}

export const deleteInvitationThunk = createAsyncThunk(
  "app/invitation/delete",
  async ({ invitation }: DeleteInvitationCallback) => {
    await invitation.delete();

    return invitation;
  }
);
