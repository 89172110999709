import { createSlice } from "@reduxjs/toolkit";

import { addEnvironmentDomainThunk } from "./thunks/addEnvironmentDomain.thunk";
import { deleteEnvironmentDomainThunk } from "./thunks/deleteEnvironmentDomain.thunk";
import { getEnvironmentDomainsThunk } from "./thunks/getEnvironmentDomains.thunk";
import { getEnvironmentsDomainsThunk } from "./thunks/getEnvironmentsDomains.thunk";

import type { EnvironmentDomain } from "@packages/client";

type EnvironmentDomainSettingsState = Readonly<{
  [projectId: string]: {
    loading: boolean;
    status?: "added" | "deleted" | "pending" | "rejected" | "updated";
    errors?: any;
    data?: EnvironmentDomain[];
  };
}>;

const initialState: EnvironmentDomainSettingsState = {};

const environmentDomainsSlice = createSlice({
  name: "app/project/environment/domains",
  initialState,
  reducers: {
    resetStatus(state, { payload }) {
      const { projectId } = payload;
      state[projectId] = {
        ...state[projectId],
        ...{ errors: undefined, status: undefined }
      };
    }
  },
  extraReducers: builder => {
    // Get Environment Domains
    builder
      .addCase(getEnvironmentDomainsThunk.pending, (state, { meta }) => {
        const { project } = meta.arg;
        state[project.id] = {
          ...state[project.id],
          ...{ errors: undefined, loading: true, status: undefined }
        };
      })
      .addCase(
        getEnvironmentDomainsThunk.fulfilled,
        (state, { meta, payload }) => {
          const { project } = meta.arg;
          state[project.id] = {
            ...state[project.id],
            ...{ loading: false, data: payload }
          };
        }
      )
      .addCase(
        getEnvironmentDomainsThunk.rejected,
        (state, { meta, payload }) => {
          const { project } = meta.arg;
          state[project.id] = {
            ...state[project.id],
            ...{
              loading: false,
              errors: payload?.error
            }
          };
        }
      );

    // Get Environments Domains
    builder
      .addCase(getEnvironmentsDomainsThunk.pending, (state, { meta }) => {
        const { project } = meta.arg;
        state[project.id] = {
          ...state[project.id],
          ...{ errors: undefined, loading: true, status: undefined }
        };
      })
      .addCase(
        getEnvironmentsDomainsThunk.fulfilled,
        (state, { meta, payload }) => {
          const { project } = meta.arg;
          state[project.id] = {
            ...state[project.id],
            ...{ loading: false, data: payload }
          };
        }
      )
      .addCase(
        getEnvironmentsDomainsThunk.rejected,
        (state, { meta, payload }) => {
          const { project } = meta.arg;
          state[project.id] = {
            ...state[project.id],
            ...{
              loading: false,
              errors: payload?.error
            }
          };
        }
      );

    // Add Domain
    builder
      .addCase(addEnvironmentDomainThunk.pending, (state, { meta }) => {
        const { project } = meta.arg;
        state[project.id] = { ...state[project.id], ...{ status: "pending" } };
      })
      .addCase(
        addEnvironmentDomainThunk.fulfilled,
        (state, { meta, payload }) => {
          const { project } = meta.arg;
          const environmentDomains = state[project.id]?.data;
          if (payload) environmentDomains?.push(payload);
          state[project.id] = {
            ...state[project.id],
            ...{
              status: "added",
              data: environmentDomains
            }
          };
        }
      )
      .addCase(
        addEnvironmentDomainThunk.rejected,
        (state, { meta, payload }) => {
          const { project } = meta.arg;
          state[project.id] = {
            ...state[project.id],
            ...{ status: "rejected", errors: payload?.error }
          };
        }
      );

    // Delete Domain
    builder
      .addCase(deleteEnvironmentDomainThunk.pending, (state, { meta }) => {
        const { projectId } = meta.arg;
        state[projectId] = { ...state[projectId], ...{ status: "pending" } };
      })
      .addCase(
        deleteEnvironmentDomainThunk.fulfilled,
        (state, { meta, payload }) => {
          const { environmentDomain, projectId } = meta.arg;
          state[projectId] = {
            ...state[projectId],
            ...{
              status: "deleted",
              data: state[projectId]?.data
                ?.filter(elt => elt.id !== environmentDomain?.id)
                .map(elt => (elt.id === payload?.id ? payload : elt))
            }
          };
        }
      )
      .addCase(
        deleteEnvironmentDomainThunk.rejected,
        (state, { meta, payload }) => {
          const { projectId } = meta.arg;
          state[projectId] = {
            ...state[projectId],
            ...{
              status: "rejected",
              errors: payload?.error
            }
          };
        }
      );
  }
});

export const { resetStatus } = environmentDomainsSlice.actions;
export const environmentDomainsReducer = environmentDomainsSlice.reducer;
