import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";

import {
  flagReadAnnouncements,
  fetchAnnouncements,
  getReadAnnouncements
} from "../";

import type { Announcement } from "../";
import type { AsyncThunkOptionType } from "Reducers/types";

export const getAnnouncements = createAsyncThunk<
  Announcement[],
  undefined,
  AsyncThunkOptionType
>("announcements/get_announcements", async (_, { rejectWithValue }) => {
  try {
    const announcements = flagReadAnnouncements(
      ...(await Promise.all([fetchAnnouncements(), getReadAnnouncements()]))
    );

    return announcements;
  } catch (error) {
    logger(error, {
      action: "ANNOUNCEMENTS_GET_ALL"
    });
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue(error);
    }
  }
});
