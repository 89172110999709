import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { EnvironmentDomain } from "@packages/client";

export const deleteEnvironmentDomainThunk = createAsyncThunk<
  EnvironmentDomain | undefined,
  {
    environmentDomain: EnvironmentDomain;
    projectId: string;
    environmentId: string;
  },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/environment/domain/delete",
  async ({ environmentDomain }, { rejectWithValue }) => {
    try {
      await environmentDomain.delete();
    } catch (err) {
      if (err instanceof Error) {
        logger(
          {
            errMessage: err.message,
            environmentDomainId: environmentDomain.id
          },
          { action: "projectDeleteEnvironmentDomain" }
        );

        return rejectWithValue({ error: err });
      }
    }
  }
);
