import React from "react";

import { createBrowserRouter, RouteObject } from "react-router-dom";

export const routes = [
  {
    path: "/*",
    Component: React.lazy(() => import("./root/pages/Page")),
    children: [
      {
        path: "organization/*",
        lazy: () => import("./organization/pages/createOrganization")
      },
      {
        path: "support",
        lazy: () =>
          import("./tickets/common/components/supportRedirect/SupportRedirect")
      },
      {
        path: "-/users/*",
        children: [
          {
            path: ":username/settings/*",
            lazy: () => import("./root/pages/users/settings/Routes"),
            children: [
              {
                path: "tokens",
                lazy: () => import("./root/pages/users/settings/apiTokens/Page")
              },
              {
                path: "ssh-keys",
                lazy: () => import("./root/pages/users/settings/sshKeys/Page")
              },
              {
                path: "security/*",
                lazy: () =>
                  import("./root/pages/users/settings/security/Routes"),
                children: [
                  {
                    path: "setup",
                    lazy: () =>
                      import(
                        "./root/pages/users/settings/security/pages/setup/Page"
                      )
                  },
                  {
                    path: "",
                    lazy: () =>
                      import(
                        "./root/pages/users/settings/security/pages/manage/Page"
                      )
                  }
                ]
              },
              {
                path: "accounts",
                lazy: () => import("./root/pages/users/settings/accounts/Page")
              },
              {
                path: "",
                lazy: () =>
                  import("./root/pages/users/settings/BasicSettings/Routes")
              }
            ]
          },
          {
            path: ":username/tickets/*",
            lazy: () => import("./tickets/pages/Routes")
          },
          {
            path: ":username/billing/*",
            lazy: () => import("./root/pages/users/BillingRedirect")
          },
          {
            path: ":username",
            lazy: () => import("./root/pages/users/SettingsRedirect")
          }
        ]
      },
      {
        path: "users/*",
        lazy: () => import("./root/pages/users/UsersRedirect")
      },
      {
        path: "-/app/:appId",
        lazy: () => import("./root/pages/pluginApps/Routes")
      },
      {
        path: "-/add-plugin",
        lazy: () => import("./root/pages/pluginApps/addPlugin/Page")
      },
      {
        path: "-/logout",
        lazy: () => import("./root/pages/logout/Page")
      },
      {
        path: "-/post-logout",
        lazy: () => import("./root/pages/postLogout/Page")
      },
      {
        path: "billing",
        lazy: () => import("./billing/BillingRedirect")
      },
      {
        path: "-/billing",
        lazy: () => import("./billing/BillingRedirect")
      },
      {
        path: "-/organizations/:id/wait",
        lazy: () =>
          import(
            "./organization/pages/organizationProjects/containers/InvitationWaiting"
          )
      },
      {
        path: "projects/create-project",
        lazy: () => import("./organization/pages/CreateProjectRedirect")
      },
      {
        path: ":organizationId/*",
        lazy: () => import("./organization/pages/Routes")
      },
      {
        path: "",
        lazy: () => import("./organization/pages/projects/Routes")
      }
    ]
  }
] as const;

export default createBrowserRouter(routes as unknown as RouteObject[]);
