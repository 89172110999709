import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";

import { getProjectAccessesThunk } from "./thunks/getProjectAccesses.thunk";

import type { ProjectAccess } from "@packages/client";

type State = {
  data: Record<string, Record<string, Array<ProjectAccess>>>;
};

const initialState: State = {
  data: {}
};

const projectAccesses = createSlice({
  name: "projectAccesses",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getProjectAccessesThunk.fulfilled, (state, action) => {
      const { organizationId, project } = action.meta.arg;
      setDeep(state, ["data", organizationId, project.id], action.payload);
    });
  }
});

export default projectAccesses.reducer;
