import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { isJson } from "Libs/utils";
import { RootState } from "Store/configureStore";

import type { ConnectedAccount } from "@packages/client";

export type AuthProviders = {
  connectUrl: string;
  imagePath: string;
  imagePathDark: string;
  label: string;
  loginUrl: string;
  name: string;
  signupUrl: string;
};

interface ConnectedAccountsState {
  loading: boolean | string; // string is the name of the provider being requested to delete
  error?: string;
  data: {
    [provider: string]: ConnectedAccount | undefined;
  };
}

interface APIError {
  code: number;
}

function isAPIError(err: unknown): err is APIError {
  return (err as APIError).code !== undefined;
}

export const loadConnectedAccounts = createAsyncThunk(
  "app/users/load_connected_account",
  async (userId: string, { rejectWithValue }) => {
    try {
      const connectedAccounts = await client.getConnectedAccounts(userId);
      return connectedAccounts;
    } catch (err: unknown) {
      const errorMessageForUser =
        "An error occurred while attempting to load connected account.";
      if (isAPIError(err) && ![404, 403].includes(err.code)) {
        const errorMessage = isJson(err) ? err : errorMessageForUser;
        logger(errorMessage, {
          action: "user_load_connected_account_success"
        });
      }
      return rejectWithValue(errorMessageForUser);
    }
  }
);

export const deleteConnectedAccount = createAsyncThunk(
  "app/users/delete_connected_account",
  async (provider: string, { getState, rejectWithValue }) => {
    try {
      const connectedAccount = selectConnectedAccountDetails(
        getState() as RootState,
        provider
      );

      if (!provider || !connectedAccount) {
        throw new Error("Connected account not found for that provider.");
      }

      await connectedAccount.delete();
      return provider;
    } catch (err: unknown) {
      if (isAPIError(err) && ![404, 403, 400].includes(err.code)) {
        logger(err, { action: "user_delete_connected_account_failure" });
      }
      rejectWithValue(`Connected account ${provider}not found.`);
    }
  }
);

const connectedAccounts = createSlice({
  name: "connectedAccounts",
  initialState: {
    loading: false,
    error: "",
    data: {}
  } as Partial<ConnectedAccountsState>,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadConnectedAccounts.pending, state => {
        state.loading = true;
      })
      .addCase(loadConnectedAccounts.rejected, state => {
        state.loading = false;
        state.error =
          "An error occurred while attempting to load connected accounts";
      })
      .addCase(loadConnectedAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.reduce((acc, provider) => {
          acc[provider.provider_type] = provider;
          return acc;
        }, {});
      })
      .addCase(deleteConnectedAccount.pending, (state, action) => {
        state.loading = action.payload;
      })
      .addCase(deleteConnectedAccount.rejected, state => {
        state.loading = false;
        state.error =
          "An error occurred while attempting to delete the connected account";
      })
      .addCase(deleteConnectedAccount.fulfilled, (state, action) => {
        state.loading = false;
        if (state.data && action.payload) {
          state.data[action.payload] = undefined;
        }
      });
  }
});

export const selectConnectedAccounts = (state: RootState) =>
  state.connectedAccounts;

export const selectConnectedAccountThatIsLoading = createSelector(
  selectConnectedAccounts,
  connectedAccounts => connectedAccounts.loading
);

export const selectConnectedAccountsData = createSelector(
  selectConnectedAccounts,
  connectedAccounts => connectedAccounts.data
);

export const selectConnectedAccountDetails = createSelector(
  [selectConnectedAccountsData, (_: RootState, provider: string) => provider],
  (connectedAccountsData, provider) => {
    return connectedAccountsData?.[provider];
  }
);

export default connectedAccounts.reducer;
