import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { entities } from "Libs/platform";
import { normalize } from "Libs/utils";

import type { ApiToken } from "@packages/client";
import type { AsyncThunkOptionType } from "Reducers/types";

export type ApiTokenParams = {
  tokenId?: string;
  username: string;
  name?: string;
  userId?: string;
};

export const getApiTokens = createAsyncThunk<
  Record<string, ApiToken>,
  ApiTokenParams,
  AsyncThunkOptionType
>("apiToken/list", async ({ userId }) => {
  const tokens = await entities.ApiToken.query({
    userId
  });
  return normalize(tokens, "id");
});

export const addApiToken = createAsyncThunk<
  ApiToken,
  ApiTokenParams,
  AsyncThunkOptionType
>("apiToken/add", async ({ name, userId }) => {
  const newApiToken = new entities.ApiToken({ name }, { userId });
  const responseFromSavingToken = await newApiToken
    .save()
    .catch((err: string) => {
      const message = JSON.parse(err).message;
      logger(
        {
          message
        },
        {
          action: "addApiToken",
          meta: {
            userId
          }
        }
      );
      throw new Error(message);
    });

  const finalApiToken = new entities.ApiToken(responseFromSavingToken.data, {
    userId
  });
  return finalApiToken;
});

export const deleteApiToken = createAsyncThunk<
  ApiToken | undefined,
  ApiTokenParams,
  AsyncThunkOptionType
>("apiToken/delete", async ({ tokenId, username, userId }, { getState }) => {
  if (!tokenId) return;

  const tokenToDelete = getState().apiToken.data?.[username]?.[tokenId];

  if (!tokenToDelete) return;

  await tokenToDelete.delete({ userId }).catch((err: string) => {
    const errMessage = JSON.parse(err);
    logger("deleteApiToken", { errMessage, userId });
    throw new Error(errMessage.error);
  });

  return tokenToDelete;
});

type ApiTokenState = {
  canAddNew?: boolean;
  currentlySavingToken?: boolean;
  data?: {
    [username: string]: { [tokenId: string]: ApiToken } | undefined;
  };
  errors?: unknown;
  loading?: boolean;
  newApiToken?: ApiToken;
};

const initialState: ApiTokenState = {};

const apiToken = createSlice({
  name: "apiToken",
  initialState,
  reducers: {
    expandNewApiTokenForm(state) {
      state.canAddNew = true;
    },
    cancelAddNew(state) {
      state.canAddNew = false;
      state.errors = null;
    },
    closeTokenBanner(state) {
      delete state.newApiToken;
    }
  },
  extraReducers: builder => {
    builder.addCase(getApiTokens.pending, state => {
      state.loading = true;
    });

    builder.addCase(getApiTokens.fulfilled, (state, action) => {
      const { username } = action.meta.arg;

      setDeep(state, ["data", username], action.payload);
      state.loading = false;
    });

    builder.addCase(getApiTokens.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    });

    builder.addCase(addApiToken.pending, state => {
      state.currentlySavingToken = true;
      delete state.errors;
    }),
      builder.addCase(addApiToken.rejected, (state, action) => {
        state.currentlySavingToken = false;
        state.errors = action.error.message;
        state.loading = false;
      }),
      builder.addCase(addApiToken.fulfilled, (state, action) => {
        const { username } = action.meta.arg;
        const { id } = action.payload;

        setDeep(state, ["data", username, id], action.payload);
        state.newApiToken = action.payload;
        state.currentlySavingToken = false;
        state.canAddNew = false;
      }),
      builder.addCase(deleteApiToken.pending, state => {
        state.loading = true;
      }),
      builder.addCase(deleteApiToken.fulfilled, (state, action) => {
        if (!action.payload) return;

        const { username } = action.meta.arg;
        const { id } = action.payload;

        delete state.data?.[username]?.[id];
        state.loading = false;
      }),
      builder.addCase(deleteApiToken.rejected, (state, action) => {
        state.currentlySavingToken = false;
        state.errors = action.error.message;
        state.loading = false;
      });
  }
});

export const { expandNewApiTokenForm, cancelAddNew, closeTokenBanner } =
  apiToken.actions;

export default apiToken.reducer;
