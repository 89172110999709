import {
  createAsyncThunk,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";

import { request } from "Libs/platform";
import { getRegionLabel } from "Libs/utils";
import { gitProjectSelector, projectSelector } from "Reducers/project/project";
import { subscriptionSelector } from "Reducers/subscription";
import config from "src/constants/api_config";

import {
  RegionState,
  SetTokenAction,
  RegionAction,
  SelectorParams
} from "./types";

import type { Region } from "@packages/client";
import type { RootState } from "Store/configureStore";

export const getRegions = createAsyncThunk("app/region", async () => {
  const getAllRegions = async (
    regionsUrl = `${config.api_url}/regions`,
    resultList: Region[] = []
  ): Promise<Region[]> => {
    const result: {
      regions: Region[];
      count: number;
      _links: { [key: string]: { href: string } };
    } = await request(regionsUrl, "GET");

    const allRegions = [...resultList, ...(result.regions ?? [])];
    if (result?._links.next?.href) {
      return await getAllRegions(result._links.next.href, allRegions);
    }
    return allRegions;
  };

  const regions = await getAllRegions();
  return {
    regions
  };
});

const region = createSlice({
  name: "app/region",
  initialState: {
    data: {},
    byZone: {},
    errors: null,
    loading: false
  } as RegionState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRegions.pending, state => {
        state.loading = true;
      })
      .addCase(getRegions.fulfilled, (state, action: RegionAction) => {
        const regions = action.payload.regions;

        state.data = regions.reduce(
          (acc: { [key: string]: Region }, region: Region) => {
            acc[region.id] = region;
            return acc;
          },
          {}
        );

        state.byZone = regions
          .filter((region: Region) => region.available == true) // Double equals because some available keys are strings, and some aren't.
          .reduce((acc: { [key: string]: Region[] }, region: Region) => {
            if (!acc[region.zone]) {
              acc[region.zone] = [region];
              return acc;
            }

            acc[region.zone].push(region);
            return acc;
          }, {});

        state.loading = false;
        state.errors = null;
      })
      .addCase(getRegions.rejected, (state, action: SetTokenAction) => {
        state.loading = false;
        state.errors =
          action.error.message || "Got an error retrieving regions";
      });
  }
});

export default region.reducer;

const selectSelf = (state: RootState) => {
  return state.region;
};

const getParams = (_: RootState, params: SelectorParams) => params;

export const regionsLoadingSelector = createSelector(
  selectSelf,
  region => region?.loading
);

export const regionsSelector = createSelector(
  selectSelf,
  region => region?.data
);

export const regionsByZoneSelector = createSelector(
  selectSelf,
  region => region?.byZone || {}
);

export const selectFormattedRegions = createSelector(
  regionsByZoneSelector,
  regions =>
    Object.values(regions)
      .flat()
      .sort((a, b) => a.label.localeCompare(b.label))
);

export const regionSelector = createSelector(
  selectSelf,
  getParams,
  (region, { regionId }) => (regionId ? region?.data?.[regionId] : undefined)
);

export const regionLabelSelector = createSelector(
  (state: RootState) => state,
  (
    _: unknown,
    params: {
      organizationId: string;
      projectId: string;
      fallbackLabel?: string;
    }
  ) => params,
  (state, params) => {
    const project =
      gitProjectSelector(state, params) || projectSelector(state, params);
    const subscription = subscriptionSelector(state, {
      organizationId: params.organizationId,
      projectId: params.projectId,
      id: project?.subscription_id
    });
    return getRegionLabel(
      project?.region_label ||
        subscription?.project_region_label ||
        params.fallbackLabel
    );
  }
);

type ListOption = {
  label: string;
  value: string;
  options: Partial<Region & { label: string }>[];
};

export const getRegionListOptions = ({
  regionOptions,
  regions
}: {
  regionOptions: string[];
  regions: Record<string, Region[]>;
}) => {
  const restrictedRegions = regionOptions;
  const list: ListOption[] = [];
  Object.keys(regions).forEach(zone => {
    const optionsForZone = (
      restrictedRegions.length === 0
        ? regions[zone]
        : regions[zone].filter(region => {
            // The ID of the region for British Council was not done the same as others.
            // Moving forward, all regions have the ID of their fully qualified URL. For now
            // it is easier to hard code this one exception than change the ID for a whole region.
            return (
              (region.id === "bc" &&
                restrictedRegions.indexOf("bc.platform.sh") !== -1) ||
              restrictedRegions.indexOf(region.id) !== -1
            );
          })
    )
      .map(elt => {
        const label = elt.provider?.name
          ? `${elt.label} - ${elt.provider?.name}`
          : elt.label;
        return { ...elt, label };
      })
      .filter(elt => !elt.private);

    list.push({
      label: zone === "" ? "Other" : zone,
      value: zone === "" ? "Other" : zone,
      options: optionsForZone
    });
  });
  return list;
};

const GREEN_CARBON_INTENSITY = 100;
const DEFAULT_CARBON_INTENSITY = 1000;

export const isGreenRegion = (region?: Region) =>
  (region?.environmental_impact?.carbon_intensity || DEFAULT_CARBON_INTENSITY) <
  GREEN_CARBON_INTENSITY;
