import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import client from "Libs/platform";
import { AsyncThunkOptionType } from "Reducers/types";

import type { EnvironmentType } from "@packages/client";

export const getEnvironmentTypeAccessesThunk = createAsyncThunk<
  EnvironmentType[],
  {
    projectId: string;
  },
  AsyncThunkOptionType
>(
  "app/projectEnvironmentTypes/load_environment_types",
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const environmentTypes =
        await client.getProjectEnvironmentTypesWithAccesses(projectId);
      return environmentTypes;
    } catch (err) {
      logger(err);
      return rejectWithValue(err);
    }
  }
);
