import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getProject, getProjects, ProjectsState } from "./index";

const initialState: ProjectsState = {
  data: {},
  organizationsProvisioningProject: [],
  loading: null,
  error: null
};

const organizationProjectsReducer = createSlice({
  name: "app/organization/projects",
  initialState,
  reducers: {
    startProvisioningPulling: (
      state,
      action: PayloadAction<{ organizationId: string }>
    ) => ({
      ...state,
      organizationsProvisioningProject: [
        ...state.organizationsProvisioningProject,
        action.payload.organizationId
      ]
    }),
    endProvisioningPulling: (
      state,
      action: PayloadAction<{ organizationId }>
    ) => ({
      ...state,
      organizationsProvisioningProject:
        state.organizationsProvisioningProject.filter(
          id => id !== action.payload.organizationId
        )
    })
  },
  extraReducers: builder => {
    builder
      // GET PROJECTS
      .addCase(getProjects.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProjects.fulfilled, (state, { meta, payload }) => {
        const { organizationId } = meta.arg;
        const normalizedPayload = Object.fromEntries(
          payload.items.map(project => [[project.id], project])
        );

        state.data[organizationId] = {
          ...state.data[organizationId],
          ...normalizedPayload
        };

        state.loading = false;
      })
      .addCase(getProjects.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // GET PROJECT
      .addCase(getProject.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProject.fulfilled, (state, { meta, payload }) => {
        const { organizationId, projectId } = meta.arg;

        state.data[organizationId][projectId] = {
          ...state.data[organizationId][projectId],
          ...payload
        };
        state.loading = false;
      })
      .addCase(getProject.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  }
});

export const { startProvisioningPulling, endProvisioningPulling } =
  organizationProjectsReducer.actions;

export default organizationProjectsReducer.reducer;
