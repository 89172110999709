import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { AddVariableDataType } from "../types";
import type { Project, ProjectLevelVariable } from "@packages/client";

export const addVariable = createAsyncThunk<
  ProjectLevelVariable | undefined,
  {
    organizationId: string;
    project: Project;
    data: AddVariableDataType;
  },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/variable/add",
  async ({ project, data }, { rejectWithValue }) => {
    if (!project) return;

    try {
      const result = await project.setVariable(
        data.name,
        data.value,
        data.is_json,
        data.is_sensitive,
        data.visible_build,
        data.visible_runtime
      );
      if (result?.code / 100 === 4) {
        return rejectWithValue({ error: result });
      }

      const variable: ProjectLevelVariable = await result?.getEntity();
      return variable;
    } catch (err) {
      const error = err as Error;

      logger(
        { errMessage: error?.message, projectId: project.id },
        {
          action: "projectAddVariable"
        }
      );

      return rejectWithValue({ error: error });
    }
  }
);
