import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";

import type { EnvironmentBackup } from "@packages/client";

interface ProjectProps {
  environmentId: string;
  projectId: string;
}

export const getAllBackups = createAsyncThunk(
  "backupsStored/get_backups",
  async ({ projectId, environmentId }: ProjectProps, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const backups = await client.getEnvironmentBackups(
        projectId,
        encodeURIComponent(environmentId)
      );
      const backupsManual: EnvironmentBackup[] = [];
      const backupsAutomated: EnvironmentBackup[] = [];

      for (const backup of backups) {
        if (!backup.automated) {
          backupsManual.push(backup);
        } else {
          backupsAutomated.push(backup);
        }
      }

      return { backupsManual, backupsAutomated };
    } catch (error) {
      logger(error, {
        action: "BACKUPS_STORED_GET_BACKUPS",
        meta: {
          projectId,
          environmentId
        }
      });
      return rejectWithValue(error);
    }
  }
);
