import { createSlice } from "@reduxjs/toolkit";

import client from "Libs/platform";
import { loadUserProfile } from "Reducers/profile";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import type { TwoFactorAuthentication } from "@packages/client";
import type { RootState } from "Store/configureStore";

type SecurityState = {
  setup: {
    data: TwoFactorAuthentication | null;
    error: string | null;
    isLoading: boolean;
  };
  recoveryCodes: {
    data: string[] | null;
    error: unknown;
    isLoading: boolean;
  };
};

export const initialState: SecurityState = {
  setup: {
    data: null,
    error: null,
    isLoading: false
  },
  recoveryCodes: {
    data: null,
    error: null,
    isLoading: false
  }
};

export const getErrorMessage = (error: unknown) => {
  let message: string | undefined;
  if (typeof error === "string") {
    message = JSON.parse(error).error;
  } else if (typeof error === "object") {
    message = ((error && "error" in error && error.error) ||
      (error && "message" in error && error.message) ||
      error) as string | undefined;
  }
  return message;
};

export const setup = createAppAsyncThunk(
  "security/setup",
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      const tfaInfo = await client.getTFA(userId);

      return tfaInfo;
    } catch (error) {
      return rejectWithValue({ error: getErrorMessage(error) ?? "" });
    }
  }
);

export const enroll = createAppAsyncThunk(
  "security/enroll",
  async (
    {
      userId,
      secret,
      passcode
    }: { userId: string; secret: string; passcode: string },
    { rejectWithValue }
  ) => {
    try {
      const { recovery_codes } = (await client.enrollTFA(
        userId,
        secret,
        passcode
      )) as { recovery_codes: string[] };

      return recovery_codes;
    } catch (error) {
      rejectWithValue({ error: getErrorMessage(error) ?? "" });
    }
  }
);

export const resetRecoveryCodes = createAppAsyncThunk(
  "security/resetRecoveryCodes",
  async ({ userId }: { userId: string }, { rejectWithValue }) => {
    try {
      const { recovery_codes } = (await client.resetRecoveryCodes(userId)) as {
        recovery_codes: string[];
      };

      return recovery_codes;
    } catch (error) {
      rejectWithValue({
        error:
          ((error as { error?: string }).error ||
            (error as { message?: string }).message ||
            (error as string | undefined)) ??
          ""
      });
    }
  }
);

export const disableTFA = createAppAsyncThunk(
  "security/disableTFA",
  async ({ userId }: { userId: string }, { dispatch, rejectWithValue }) => {
    try {
      await client.disableTFA(userId);
      dispatch(loadUserProfile());
    } catch (error) {
      rejectWithValue({
        error: error as string
      });
    }
  }
);

const security = createSlice({
  name: "security",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setup.pending, state => {
        state.setup.isLoading = true;
        state.setup.error = null;
      })
      .addCase(setup.fulfilled, (state, { payload }) => {
        state.setup.data = payload!;
        state.setup.isLoading = false;
      })
      .addCase(setup.rejected, (state, { payload }) => {
        state.setup.error = payload?.error || null;
        state.setup.isLoading = false;
      })
      .addCase(enroll.pending, state => {
        state.recoveryCodes.isLoading = true;
        state.recoveryCodes.error = null;
      })
      .addCase(enroll.fulfilled, (state, { payload }) => {
        state.recoveryCodes.data = payload!;
        state.recoveryCodes.isLoading = false;
      })
      .addCase(enroll.rejected, (state, { payload }) => {
        state.recoveryCodes.error = payload;
        state.recoveryCodes.isLoading = false;
      })
      .addCase(resetRecoveryCodes.pending, state => {
        state.recoveryCodes.isLoading = true;
        state.recoveryCodes.error = null;
      })
      .addCase(resetRecoveryCodes.fulfilled, (state, { payload }) => {
        state.recoveryCodes.data = payload!;
        state.recoveryCodes.isLoading = false;
      })
      .addCase(resetRecoveryCodes.rejected, (state, { payload }) => {
        state.recoveryCodes.error = payload;
        state.recoveryCodes.isLoading = false;
      })
      .addCase(disableTFA.fulfilled, state => {
        state.recoveryCodes.data = null;
        state.setup.data = null;
      });
  }
});

export const selectRecoveryCodes = (state: RootState) =>
  state.security.recoveryCodes.data;

export const selectIsLoadingRecoveryCodes = (state: RootState) =>
  state.security.recoveryCodes.isLoading;

export const selectRecoveryCodesError = (state: RootState) =>
  state.security.recoveryCodes.error as string;

export const selectSetupData = (state: RootState) => state.security.setup.data;

export const selectIsLoadingSetup = (state: RootState) =>
  state.security.setup.isLoading;

export const selectSetupError = (state: RootState) =>
  state.security.setup.error;

export default security.reducer;
