import { createAsyncThunk } from "@reduxjs/toolkit";

import { AsyncThunkOptionType } from "Reducers/types";

import type { RequestOptions, Environment, Project } from "@packages/client";

type EnvironmentOptionsType = {
  repository: string;
  profile: string;
  files: [
    {
      mode: string;
      path: string;
      contents: string;
    }
  ];
};

type GetInitializeEnvironmentThunkPropType = {
  project: Project;
  title: string;
  environmentOptions: EnvironmentOptionsType;
  environment: Environment;
};

export const initializeVariable = createAsyncThunk<
  any,
  GetInitializeEnvironmentThunkPropType,
  AsyncThunkOptionType<{ error: string }>
>(
  "app/project/environment/variables",
  async (
    { project, title, environmentOptions, environment },
    { rejectWithValue }
  ) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      if (project && title) {
        const variables = await client.initializeEnvironment(
          project?.id || "",
          environmentOptions as RequestOptions,
          environment?.name
        );
        return variables;
      }
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);
