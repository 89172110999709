import moment from "moment";

const KEY_VALUE_SEPARATOR = "=";
const PAIR_SEPARATOR = "/";

let cache: Record<string, Record<string, string>> = {};

/**
 * Deletes the whole cache
 */
export const clearCache = () => {
  cache = {};
};

function decodeMixedURL(urlString: string) {
  let decodedURL: string;
  try {
    decodedURL = decodeURIComponent(urlString);
  } catch {
    const parts = urlString.split("/");
    const decodedParts = parts.map(part => {
      return part.replace(/%[0-9A-Fa-f]{2}/g, match => {
        return String.fromCharCode(parseInt(match.substring(1), 16));
      });
    });
    decodedURL = decodedParts.join("/");
  }
  return decodedURL;
}

/**
 * Given a dimension string, transform it into a hash
 * This function will cache the results by default
 *
 * dimesionString format => `hostname=id-123/key=value`
 * { hostname: "id-123", key: "value" }
 */
const parseDimensions = (dimensionString?: string) => {
  if (typeof dimensionString !== "string") {
    return;
  }

  if (cache[dimensionString]) {
    return cache[dimensionString];
  }

  const dimensions = dimensionString.split(PAIR_SEPARATOR);
  const dimensionsObject: Record<string, string> = {};

  for (let i = 0; i < dimensions.length; i++) {
    const dimension = dimensions[i];
    if (dimension) {
      const [key, value] = dimension.split(KEY_VALUE_SEPARATOR);
      if (key) {
        dimensionsObject[key] = decodeMixedURL(value);
      }
    }
  }

  cache[dimensionString] = dimensionsObject;

  return dimensionsObject;
};

/**
 * parse a string representation of time duration
 * @param timeUnit string of time duration
 * @param fallbackMinute fallback number in minute if timeunit is invalid
 * @returns
 */
export const parseTimeDuration = (
  timeUnit: string = "",
  fallbackMinute: number = 15
) => {
  let seconds = 0;
  const durationRegex = /(-?\d+)([smhdwMy]{1})/g;
  let durationMatch = durationRegex.exec(timeUnit);
  const unitValues: {
    unit: "s" | "m" | "h" | "d" | "w" | "M" | "y";
    value: number;
  }[] = [];

  while (durationMatch) {
    const unit = durationMatch[2] as "s" | "m" | "h" | "d" | "w" | "M" | "y";
    const value = parseInt(durationMatch[1]);

    unitValues.push({ unit, value });
    const duration = moment.duration(value, unit);
    seconds += duration.asSeconds();
    durationMatch = durationRegex.exec(timeUnit);
  }

  const durationOrder = {
    s: { priority: 1, label: "second" },
    m: { priority: 2, label: "minute" },
    h: { priority: 3, label: "hour" },
    d: { priority: 4, label: "day" },
    w: { priority: 5, label: "week" },
    M: { priority: 6, label: "month" },
    y: { priority: 7, label: "year" }
  } as const;

  const convertToWord = (data: typeof unitValues) => {
    const sorted = data.sort((a, b) => {
      const diff =
        moment.duration(b.value, b.unit).asMilliseconds() -
        moment.duration(a.value, a.unit).asMilliseconds();
      return diff;
    });
    const pluralise = sorted.map(({ unit, value }) => {
      // Pluralise if unit is greater than 1
      return value > 1
        ? { label: `${durationOrder[unit].label}s`, value }
        : { label: durationOrder[unit].label, value };
    });

    return pluralise
      .reduce((word, next) => word.concat(`${next.value} ${next.label} `), "")
      .trim();
  };

  if (!seconds) {
    return {
      seconds: moment.duration(fallbackMinute, "m").asSeconds(),
      durationText: convertToWord([{ unit: "m", value: fallbackMinute }]),
      type: "minute" as const,
      range: fallbackMinute,
      typePlural: "minutes"
    };
  }

  /* If more than one time duration unit, we can't tell the type so lets treat as seconds
   * Single Unit E.g 1h, 2d, 5d, 1m, 30d,
   * Not single unit E.g 1M2d, 2y5M2d
   */

  const isOneUnit = unitValues.length == 1;

  const type = isOneUnit ? durationOrder[unitValues[0].unit].label : "second";
  const range = isOneUnit ? unitValues[0].value : seconds;

  const pluraliseType = ({ range, type }: { range: number; type: string }) =>
    range > 1 ? `${type}s` : type;

  return {
    seconds,
    durationText: convertToWord(unitValues),
    type,
    range,
    typePlural: pluraliseType({ type, range })
  };
};

export default parseDimensions;
