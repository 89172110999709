import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  SerializedError
} from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import localForage from "localforage";

import { SUBSCRIPTION_PLAN } from "Constants/constants";
import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { entities } from "Libs/platform";
import { isJson, getOrganizationId, normalize } from "Libs/utils";
import { AsyncThunkOptionType } from "Reducers/types";
import { RootState } from "Store/configureStore";

import { loadOrganization } from "..";

import type {
  APIObject,
  OrganizationSubscription,
  CursoredResult,
  Subscription
} from "@packages/client";

export const loadSubscriptions = createAsyncThunk<
  CursoredResult<OrganizationSubscription>,
  {
    organizationId: string;
    search?: Record<string, any>;
  },
  AsyncThunkOptionType
>(
  "app/subscriptions",
  async (
    { organizationId: organizationDescriptionId, search = {} },
    { getState, rejectWithValue }
  ) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const organizationId = getOrganizationId(
        getState,
        organizationDescriptionId
      );

      if (!organizationId) {
        return rejectWithValue({
          errors: `Unable to load the organization ${organizationDescriptionId}`
        });
      }

      const results = await client.getOrganizationSubscriptions(
        organizationId,
        {
          filter: {
            status: {
              value: ["active", "suspended"],
              operator: "IN"
            }
          },
          ...search
        }
      );

      return results;
    } catch (error) {
      if (isJson(error)) {
        const jsonError = JSON.parse(error);
        if (jsonError.error === "insufficient_user_authentication") {
          jsonError.status = 401;
        }
        return rejectWithValue({ errors: jsonError });
      }
      logger(
        {
          errMessage: (error as { message: string }).message
        },
        {
          action: "app/subscriptions"
        }
      );
      return rejectWithValue({ errors: (error as { detail: string }).detail });
    }
  }
);

export const loadNextSubscriptionsPage = createAsyncThunk<
  CursoredResult<OrganizationSubscription>,
  { organizationId: string },
  AsyncThunkOptionType
>(
  "app/subscriptions/next",
  async ({ organizationId: organizationDescriptionId }, { getState }) => {
    const linkManager =
      getState().organizationSubscription.links?.[organizationDescriptionId];

    const result = await linkManager!.next();

    return result;
  }
);

export const loadSubscription = createAsyncThunk<
  OrganizationSubscription,
  { organizationId: string; id: string },
  AsyncThunkOptionType
>(
  "app/subscription",
  async ({ organizationId, id }, { getState, rejectWithValue, dispatch }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    let orgId = getOrganizationId(getState, organizationId);

    if (!orgId) {
      await dispatch(loadOrganization(organizationId));
      orgId = getOrganizationId(getState, organizationId);
    }

    if (!orgId) {
      return rejectWithValue({
        errors: `Unable to load the organization ${organizationId}`
      });
    }

    const subscription = await client.getOrganizationSubscription(orgId, id);
    const query = new URLSearchParams(window.location.search);
    const trialProjectCreated = query.get("trialProjectCreated");
    if (trialProjectCreated) {
      const newDate = new Date(trialProjectCreated);
      subscription.created_at = newDate.toISOString();
    }

    return subscription;
  },
  {
    condition: ({ organizationId, id }) => !!organizationId && !!id
  }
);

export const updateSubscription = createAsyncThunk<
  OrganizationSubscription,
  {
    updates: APIObject;
    subscription: OrganizationSubscription;
    organizationId: string;
  },
  AsyncThunkOptionType
>(
  "app/subscriptions/update",
  async ({ updates, subscription }, { rejectWithValue }) => {
    try {
      const response = await subscription?.update?.({ ...updates });
      const updatedSubscription = new entities.OrganizationSubscription({
        ...response?.data,
        organizationId: response?.data.organization_id
      });

      return updatedSubscription;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "app/subscriptions/delete",
  async ({
    settings = {},
    subscription
  }: {
    settings?: { redirect?: string };
    subscription: OrganizationSubscription | Subscription;
    organizationId: string;
  }) => {
    const { redirect } = settings;

    await subscription.delete();

    const deletedProjectIds =
      (await localForage.getItem<string[]>("deletedProjectIds")) || [];
    const updatedDeletedProjectIds = deletedProjectIds.concat([
      subscription.project_id
    ]);
    await localForage.setItem("deletedProjectIds", updatedDeletedProjectIds);

    if (redirect) {
      window.location.replace(`${window.location.origin}${redirect}`);
    }

    return subscription;
  }
);

const setError = (
  state: WritableDraft<OrganizationSubscriptionState>,
  action: PayloadAction<unknown, string, unknown, SerializedError>
) => {
  let message = action.error.message;
  if (isJson(action.error.message)) {
    const errors = JSON.parse(action.error.message);
    message = errors.detail;
    if (errors?.detail?.errors?.length) message = errors.detail.errors[0];
  }

  state.errors = message;
  state.loadingList = false;
  state.loading = false;
};

type OrganizationSubscriptionState = {
  data: Record<string, Record<string, OrganizationSubscription | undefined>>;
  loading: "idle" | boolean;
  loadingList?: boolean;
  loadingNextList?: boolean;
  subscriptionsErrors?: Record<string, SerializedError>;
  list?: Record<string, string[]>;
  links?: Record<
    string,
    ReturnType<CursoredResult<OrganizationSubscription>["getLinksManager"]>
  >;
  status?: "idle";
  errors?: unknown;
  deleted?: unknown;
  projectIdBySubscriptionId?: Record<string, string>;
};

const initialState: OrganizationSubscriptionState = {
  data: {},
  loading: "idle"
};

const subscriptions = createSlice({
  name: "organizationSubscription",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadSubscriptions.pending, state => {
        state.loadingList = true;
        state.subscriptionsErrors = undefined;
      })
      .addCase(loadNextSubscriptionsPage.pending, state => {
        state.loadingNextList = true;
      })
      .addCase(loadSubscription.pending, state => {
        state.loading = true;
      })
      .addCase(updateSubscription.pending, state => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(deleteSubscription.pending, state => {
        state.loading = true;
      })
      .addCase(loadNextSubscriptionsPage.fulfilled, (state, action) => {
        const { organizationId } = action.meta.arg;

        setDeep(state, ["data", organizationId], {
          ...state.data[organizationId],
          ...normalize(action.payload.items, "id")
        });

        state.list = state.list || {};
        state.list[organizationId].push(...action.payload.items.map(i => i.id));

        setDeep(
          state,
          ["links", organizationId],
          action.payload.getLinksManager()
        );

        state.loadingNextList = false;
        state.status = "idle";
      })
      .addCase(loadSubscriptions.fulfilled, (state, action) => {
        const { organizationId } = action.meta.arg;

        setDeep(
          state,
          ["data", organizationId],
          normalize(action.payload.items, "id")
        );

        setDeep(
          state,
          ["list", organizationId],
          action.payload.items.map(i => i.id)
        );

        setDeep(
          state,
          ["links", organizationId],
          action.payload.getLinksManager()
        );

        state.loadingList = false;
        state.status = "idle";
      })
      .addCase(loadSubscription.fulfilled, (state, action) => {
        const { organizationId } = action.meta.arg;

        setDeep(
          state,
          ["data", organizationId, action.payload.id],
          action.payload
        );

        state.loading = false;

        setDeep(
          state,
          ["projectIdBySubscriptionId", action.payload.id],
          action.payload.project_id
        );
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        const { organizationId } = action.meta.arg;
        setDeep(
          state,
          ["data", organizationId, action.payload.id],
          action.payload
        );

        state.loading = false;
        state.errors = null;
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        const { organizationId } = action.meta.arg;

        state.deleted = action.payload;
        state.loading = false;
        state.data[organizationId][action.payload.project_id] = undefined;
      })
      .addCase(loadSubscriptions.rejected, (state, action) => {
        const { organizationId } = action.meta.arg;
        const error = action.payload?.errors || action.error;

        if (!state.subscriptionsErrors) {
          state.subscriptionsErrors = {};
        }

        setDeep(state, ["subscriptionsErrors", organizationId], error);
        state.loadingList = false;
      })
      .addCase(loadSubscription.rejected, (state, action) =>
        setError(state, action)
      )
      .addCase(loadNextSubscriptionsPage.rejected, (state, action) =>
        setError(state, action)
      )
      .addCase(updateSubscription.rejected, (state, action) => {
        state.errors = action.payload;
        state.loading = false;
      })
      .addCase(deleteSubscription.rejected, () =>
        location.replace(location.origin)
      );
  }
});

export default subscriptions.reducer;

export const selectOrganizationSubscriptions = createSelector(
  (state: RootState, props: { organizationId: string }) =>
    state.organizationSubscription.list?.[props.organizationId],
  (state: RootState, props: { organizationId: string }) =>
    state.organizationSubscription.data?.[props.organizationId],
  (subscriptionList, subscriptionData) =>
    subscriptionList?.map(id => subscriptionData?.[id.toString()]) ?? []
);

export const selectSubscriptionDetails = (
  state: RootState,
  props: { organizationId: string; subscriptionId?: string }
) => {
  if (typeof props.subscriptionId === "undefined") return;
  return state?.organizationSubscription?.data?.[props.organizationId]?.[
    props.subscriptionId
  ];
};

export const loadingListSelector = (state: RootState) =>
  state?.organizationSubscription?.loadingList || false;

export const loadingNextListSelector = (state: RootState) =>
  state?.organizationSubscription?.loadingNextList || false;

export const loadingSelector = (state: RootState) =>
  state?.organizationSubscription?.loading || false;

export const errorsSelector = (state: RootState) =>
  state?.organizationSubscription?.errors;

export const subscriptionsLoadingErrorsSelector = (
  state: RootState,
  props: { organizationId: string }
) =>
  state?.organizationSubscription?.subscriptionsErrors?.[props.organizationId];

export const subscriptionLinksManagerSelector = (
  state: RootState,
  props: { organizationId: string }
) => state?.organizationSubscription?.links?.[props.organizationId];

export const subscriptionPlanSortCallback = (prev: string, next: string) => {
  /**
   * Comparator callback to sorts subscription plan by priorizing the order
   * as defined in SUBSCRIPTION_PLAN constant
   */
  let prevIndex = SUBSCRIPTION_PLAN.indexOf(prev);
  let nextIndex = SUBSCRIPTION_PLAN.indexOf(next);

  // If plan is not on the sorted list, put at the end of the list
  prevIndex = prevIndex > -1 ? prevIndex : SUBSCRIPTION_PLAN.length;
  nextIndex = nextIndex > -1 ? nextIndex : SUBSCRIPTION_PLAN.length;

  return prevIndex > nextIndex ? 1 : -1;
};

export const subscriptionPlanOptionsSelector = createSelector(
  (
    state: RootState,
    {
      organizationId,
      subscriptionId
    }: { organizationId: string; subscriptionId: string }
  ) => selectSubscriptionDetails(state, { organizationId, subscriptionId }),
  subscription =>
    Object.keys(subscription?.project_options?.plan_titles ?? {})
      .sort(subscriptionPlanSortCallback)
      .map(value => ({
        value,
        label: subscription?.project_options?.plan_titles?.[value]
      }))
);

export const subscriptionsPlanFilterOptionsSelector = createSelector(
  (state: RootState, { organizationId }: { organizationId: string }) =>
    selectOrganizationSubscriptions(state, { organizationId }),
  subscriptions => {
    const plans = subscriptions.reduce<Record<string, string>>((plans, sub) => {
      return { ...plans, ...sub?.project_options?.plan_titles };
    }, {});
    const types = Object.keys(plans)
      .sort(subscriptionPlanSortCallback)
      .map(value => ({
        value,
        label: plans[value]
      }));
    types.unshift({ value: "all_type", label: "All plans" });
    return types;
  }
);

export const selectFormattedSubscriptionPlanOptions = createSelector(
  selectOrganizationSubscriptions,
  subscriptions =>
    Object.entries(
      subscriptions
        .map(subscription => subscription?.project_options?.plan_titles)
        .reduce<
          Record<string, string>
        >((acc, value) => ({ ...acc, ...value }), {})
    )
      .map(([key, value]) => ({ label: value, id: key }))
      .sort((a, b) => subscriptionPlanSortCallback(a.id, b.id))
);

export const selectFormattedPlans = createSelector(
  (state: RootState) => state.organizationSubscription,
  subscriptions => {
    return Array.from(
      new Set(
        Object.values(subscriptions.data)
          .map(subscriptions => Object.values(subscriptions))
          .flat()
          .map(subscription => subscription?.project_options?.plan_titles)
          .map(titles => Object.values(titles ?? {}))
          .flat()
      )
    ).sort((a, b) =>
      /^\d/.test(a) || /^\d/.test(b) ? -1 : a.localeCompare(b)
    );
  }
);
