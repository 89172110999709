import { IntlShape } from "react-intl";

import config from "console_config";

import { CONFIG_DIR_NAME } from "../documentationUrls";

import { WizardStep } from "./wizardStepTypes";

const getDefaultTemplateWizardSteps = (
  intl: IntlShape,
  isReducedWizardEnabled: boolean
): WizardStep[] => {
  const copyCodeDescription = () => {
    if (intl.messages["setup.cli.install.code"]) {
      return [
        {
          label: intl.formatMessage({ id: "setup.cli.install.label" }),
          code: [intl.formatMessage({ id: "setup.cli.install.code" })]
        }
      ];
    } else {
      return [
        {
          label: intl.formatMessage({ id: "setup.cli.install.unix.label" }),
          code: [intl.formatMessage({ id: "setup.cli.install.unix.code" })]
        },
        {
          label: intl.formatMessage({
            id: "setup.cli.install.windows.label"
          }),
          code: [intl.formatMessage({ id: "setup.cli.install.windows.code" })]
        }
      ];
    }
  };

  return [
    {
      id: "cli",
      label: "Download the CLI",
      title: "Download the CLI",
      subtitle: "(Command Line Interface)",
      required: false,
      bodyText: `<p>To install the CLI, use the command for either OSX or Windows as shown.</p><p>For more info about our CLI check out our <a href=${intl.formatMessage(
        { id: "links.documentation.CLI" }
      )} target="_blank" rel="noopener noreferrer">documentation</a> or take a look at our <a href=${intl.formatMessage(
        { id: "links.CLI_repository" }
      )} target="_blank" rel="noopener noreferrer">CLI source code</a>.</p>`,
      copyCode: copyCodeDescription()
    },
    {
      id: "download",
      label: "Download your project",
      title: "Download your project to start using it",
      required: true,
      bodyText: `<p>Use the ${intl.formatMessage({
        id: "CLI_command_name"
      })} get CLI command to download your project template so you can start modifying it.</p><p><br /></p><p>Visit our <a href=${intl.formatMessage(
        { id: "links.documentation.download_code" }
      )} target="_blank" rel="noopener noreferrer">docs</a> for more information.</p>`,
      copyCode: [
        {
          label: "CLI command",
          code: [
            `${intl.formatMessage({
              id: "CLI_command_name"
            })} get <project ID>`
          ]
        }
      ]
    },
    {
      id: "configure",
      label: "Configure your project",
      title: "Customize your infrastructure",
      required: true,
      bodyText: isReducedWizardEnabled
        ? `<p> If your project has not been initialized by the ${config.CUSTOM_APP_NAME} team, please use the Symfony recipe, described in the <a href=${intl.formatMessage(
            {
              id: "links.documentation.wizard"
            }
          )} target="_blank" rel="noopener noreferrer"> ${config.CUSTOM_APP_NAME} documentation</a> to bootstrap your project.</p>
      <br><p>Follow the official <a href=${intl.formatMessage({
        id: "links.documentation.wizard"
      })} target="_blank" rel="noopener noreferrer"> ${config.CUSTOM_APP_NAME} documentation</a> for everything specific to ${config.CUSTOM_APP_NAME}.</p>
      <br>
      <p> You can also use the <a href="https://docs.platform.sh/" target="_blank" rel="noopener noreferrer"> Platform .sh documentation</a>  for topics
      that are not covered by the <a href=${intl.formatMessage({
        id: "links.documentation.wizard"
      })} target="_blank" rel="noopener noreferrer"> ${config.CUSTOM_APP_NAME} documentation.</a></p>`
        : `<p>Customize the configuration files included in your template to change your project infrastructure.</p><p>Your required files:</p><p><a href=${intl.formatMessage(
            {
              id: "links.documentation.container_configuration"
            }
          )} target="_blank" rel="noopener noreferrer"><code>${intl.formatMessage(
            {
              id: "app_config_file"
            }
          )}</code></a><br><a href=${intl.formatMessage({
            id: "links.documentation.services"
          })} target="_blank" rel="noopener noreferrer"><code>${intl.formatMessage(
            {
              id: "app_services_file_path"
            }
          )}</code></a><br><a href=${intl.formatMessage({
            id: "links.documentation.routes"
          })} target="_blank" rel="noopener noreferrer"><code>${intl.formatMessage(
            {
              id: "app_routes_file_path"
            }
          )}</code></a></p><p></p><p>See our <a href=${intl.formatMessage({
            id: "links.documentation.own_code_project_configuration"
          })} target="_blank" rel="noopener noreferrer">documentation</a> for more information.</p>`,
      copyCode: [
        {
          label: "Application code structure",
          code: isReducedWizardEnabled
            ? [
                `├── ${CONFIG_DIR_NAME}`,
                "│   ├── applications.yaml",
                "│   ├── services.yaml",
                "│   └── routes.yaml",
                "└── < application code >"
              ]
            : [
                `├── ${CONFIG_DIR_NAME}`,
                "│   ├── routes.yaml",
                "│   └── services.yaml",
                `├── ${intl.formatMessage({
                  id: "app_config_file"
                })}`,
                "└── < application code >"
              ],
          noCopy: true
        }
      ]
    }
  ];
};

export default getDefaultTemplateWizardSteps;
