import { QUERY_PREVIOUS_PAGE } from "Constants/constants";

import type { UrlKeysType } from "./types";

export const urlKeys: UrlKeysType = {
  auth: {
    path: "",
    subpath: {
      emailConfirmationError: {
        path: "email-confirmation-error"
      },
      register: {
        path: "register",
        subpath: {
          error: {
            path: "error"
          },
          form: {
            path: "form",
            subpath: {
              step: {
                params: ["step"],
                path: ":step"
              }
            }
          }
        }
      },
      resetPassword: {
        path: "reset-password",
        subpath: {
          linkError: {
            path: "link-error"
          },
          new: {
            path: "new"
          },
          success: {
            path: "success"
          },
          checkInbox: {
            path: "check-inbox"
          }
        }
      },
      tfa: { path: "tfa" },
      recover: { path: "recover" },
      sso: {
        path: "sso",
        subpath: {
          forgotPasswordError: {
            path: "forgot-password-error"
          },
          signup: {
            path: "signup"
          },
          tfa: {
            path: "tfa"
          },
          recover: {
            path: "recover"
          }
        }
      },
      notFound: { path: "not-found" },
      error: { path: "error" },
      invitationError: { path: "invitation-error" },
      internalServerError: { path: "internal-server-error" },
      emailConfirmation: { path: "email-confirmation" },
      consent: { path: "consent" },
      country: { path: "country" }
    }
  },
  newOrganization: {
    path: "organization/new",
    subpath: {
      first: {
        path: "first"
      }
    }
  },
  organization: {
    params: ["organizationId"],
    path: ":organizationId",
    subpath: {
      subscribe: {
        path: "-/subscribe",
        subpath: {
          billingDetails: {
            path: "billing-details"
          }
        }
      },
      billing: {
        path: "-/billing",
        subpath: {
          invoices: {
            path: "history",
            subpath: {
              invoice: {
                params: ["orderId"],
                path: ":orderId"
              }
            }
          },
          vouchers: {
            path: "vouchers",
            subpath: {
              add: {
                path: "add"
              }
            }
          },
          plan: {
            path: "plan",
            subpath: {
              subscription: {
                params: ["subscriptionId"],
                path: ":subscriptionId"
              }
            }
          },
          details: {
            path: "details"
          },
          sca: {
            params: ["orderId"],
            path: "sca/:orderId"
          }
        }
      },
      createProject: {
        path: "create-project",
        subpath: {
          details: {
            path: "details"
          },
          verify: {
            path: "verify"
          },
          setup: {
            params: ["flowType", "projectId"],
            path: "setup/:flowType/:projectId"
          },
          repository: {
            path: "repository"
          },
          template: {
            path: "template"
          },
          info: {
            path: "info"
          },
          building: {
            path: "building"
          },
          type: {
            path: "type"
          }
        }
      },
      project: {
        params: ["projectId"],
        path: ":projectId",
        subpath: {
          invitation: {
            path: "-/wait"
          },
          app: {
            params: ["appId"],
            path: "-/app/:appId"
          },
          log: {
            params: ["activityId"],
            path: "-/log/:activityId"
          },
          usageBasedBilling: {
            params: ["subscriptionId"],
            path: "subscriptions/:subscriptionId/-/billing"
          },
          backupSchedules: {
            params: ["environmentType"],
            path: "backup-schedules/:environmentType"
          },
          settings: {
            path: "-/settings",
            subpath: {
              access: {
                path: "access",
                subpath: {
                  new: {
                    path: "new"
                  },
                  invitation: {
                    params: ["invitationId"],
                    path: "invitations/:invitationId"
                  },
                  user: {
                    params: ["userId"],
                    path: "users/:userId"
                  }
                }
              },
              certificates: {
                path: "certificates",
                subpath: {
                  add: {
                    path: "add"
                  }
                }
              },
              domains: {
                path: "domains",
                subpath: {
                  new: {
                    path: "-/new"
                  },
                  domain: {
                    params: ["domainId"],
                    path: ":domainId"
                  }
                }
              },
              deployKey: {
                path: "deploykey"
              },
              integrations: {
                path: "integrations",
                subpath: {
                  setup: {
                    path: "setup",
                    subpath: {
                      configure: {
                        params: ["type"],
                        path: ":type/configure"
                      }
                    }
                  },
                  integration: {
                    params: ["integrationId"],
                    path: ":integrationId",
                    subpath: {
                      log: {
                        params: ["activityId"],
                        path: "log/:activityId"
                      },
                      configure: {
                        path: "configure"
                      }
                    }
                  }
                }
              },
              variables: {
                path: "variables",
                subpath: {
                  new: {
                    path: "-/new"
                  },
                  variable: {
                    params: ["variableId"],
                    path: ":variableId"
                  }
                }
              },
              resources: {
                path: "resources"
              },
              backupSchedules: {
                path: "backup-schedules"
              }
            }
          },
          environment: {
            params: ["environmentId"],
            path: ":environmentId",
            subpath: {
              configureAutoscaling: {
                params: ["location", "queryParams"],
                path: `autoscaling${QUERY_PREVIOUS_PAGE}:location:queryParams`
              },
              configureResources: {
                params: ["location", "queryParams"],
                path: `resources${QUERY_PREVIOUS_PAGE}:location:queryParams`
              },
              metrics: {
                path: "metrics"
              },
              httpTraffic: {
                path: "http-traffic"
              },
              profiling: {
                path: "profiling"
              },
              backups: {
                path: "backups",
                subpath: {
                  history: {
                    path: "history"
                  }
                }
              },
              observabilitySandbox: {
                path: "observability-sandbox"
              },
              actions: {
                path: "actions",
                subpath: {
                  sync: {
                    path: "sync"
                  },
                  merge: {
                    path: "merge"
                  },
                  branch: {
                    path: "branch"
                  },
                  redeploy: {
                    path: "redeploy"
                  },
                  resume: {
                    path: "resume"
                  },
                  resumeNow: {
                    path: "resume-now"
                  }
                }
              },
              routes: {
                path: "routes"
              },
              services: {
                path: "services",
                subpath: {
                  appName: {
                    params: ["appName"],
                    path: ":appName",
                    subpath: {
                      processes: {
                        path: "processes"
                      },
                      configuration: {
                        path: "configuration"
                      }
                    }
                  }
                }
              },
              app: {
                params: ["appId"],
                path: "-/app/:appId"
              },
              log: {
                params: ["activityId"],
                path: "log/:activityId"
              },
              settings: {
                path: "settings",
                subpath: {
                  variables: {
                    path: "variables",
                    subpath: {
                      variable: {
                        params: ["variableId"],
                        path: ":variableId"
                      },
                      new: {
                        path: "-/new"
                      }
                    }
                  },
                  domains: {
                    path: "domains",
                    subpath: {
                      domain: {
                        params: ["environmentDomainId"],
                        path: ":environmentDomainId"
                      },
                      add: {
                        path: "-/add"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      settings: {
        path: "-/settings",
        subpath: {
          users: {
            path: "users",
            subpath: {
              id: {
                params: ["id"],
                path: ":id"
              }
            }
          },
          security: {
            path: "security"
          },
          teams: {
            path: "teams",
            subpath: {
              team: {
                params: ["teamId"],
                path: ":teamId"
              },
              create: {
                path: "-/create"
              }
            }
          }
        }
      }
    }
  },
  users: {
    params: ["username"],
    path: "-/users/:username",
    subpath: {
      profile: {
        path: "settings",
        subpath: {
          tokens: {
            path: "tokens"
          },
          security: {
            path: "security",
            subpath: {
              setup: {
                path: "setup"
              }
            }
          },
          accounts: {
            path: "accounts"
          },
          sshKeys: {
            path: "ssh-keys"
          }
        }
      },
      tickets: {
        path: "tickets",
        subpath: {
          ticket: {
            params: ["ticketId"],
            path: ":ticketId"
          },
          open: {
            path: "open"
          }
        }
      },
      billing: {
        path: "billing"
      }
    }
  },
  accountUrl: {
    params: ["accountUrl"],
    path: ":accountUrl",
    subpath: {
      support: {
        path: "support"
      },
      user: {
        path: "user",
        subpath: {
          orders: {
            path: "orders"
          }
        }
      }
    }
  },
  root: {
    path: "-",
    subpath: {
      organizationInvitation: {
        params: ["id"],
        path: "organizations/:id/wait"
      },
      app: {
        params: ["appId"],
        path: "app/:appId"
      },
      addPlugin: {
        path: "add-plugin"
      },
      logout: {
        path: "logout"
      },
      postLogout: {
        path: "post-logout"
      }
    }
  }
};
