import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  updateAnnouncementHandler,
  getAnnouncements,
  markAnnouncementAsRead
} from ".";

import type { AnnouncementsState } from ".";

const defaultError = "Unable to load announcements";

const initialState: AnnouncementsState = {
  announcements: []
};

const announcementsReducer = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    displayAnnouncement: (
      state,
      action: PayloadAction<{
        id: number;
        props?: Partial<AnnouncementsState["announcements"]>;
      }>
    ) => {
      state.announcements = updateAnnouncementHandler(
        state,
        action.payload.id,
        { active: true }
      );
    },
    hideAnnouncement: (
      state,
      action: PayloadAction<{
        id: number;
        props?: Partial<AnnouncementsState["announcements"]>;
      }>
    ) => {
      state.announcements = updateAnnouncementHandler(
        state,
        action.payload.id,
        { active: false }
      );
    }
  },
  extraReducers: builder => {
    builder
      // GET ANNOUNCEMENTS
      .addCase(getAnnouncements.pending, state => {
        state.error = undefined;
      })
      .addCase(getAnnouncements.fulfilled, (state, { payload }) => {
        state.announcements = payload;
      })
      .addCase(getAnnouncements.rejected, (state, { payload }) => {
        state.error = payload ?? defaultError;
      })
      // MARK AS READ
      .addCase(markAnnouncementAsRead.pending, state => {
        state.error = undefined;
      })
      .addCase(markAnnouncementAsRead.fulfilled, (state, { payload }) => {
        state.announcements = payload;
      })
      .addCase(markAnnouncementAsRead.rejected, (state, { payload }) => {
        state.error = payload ?? defaultError;
      });
  }
});

export const { displayAnnouncement, hideAnnouncement } =
  announcementsReducer.actions;

export default announcementsReducer.reducer;
