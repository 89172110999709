import { createSlice } from "@reduxjs/toolkit";

import activityTypes from "Constants/activityTypes";
import { ACTIVITY_CONTEXT } from "Constants/constants";
import { setDeep } from "Libs/objectAccess";
import { normalize } from "Libs/utils";

import {
  loadProjectActivities,
  loadEnvironmentActivities,
  loadIntegrationActivities,
  loadActivitySuccess,
  loadMoreEnvironmentActivities,
  loadMoreProjectActivities,
  loadMoreIntegrationActivities
} from "./thunks";
import { UpdateSubscriptionActivity, ActivityState } from "./types";

export const ActivityTabName = {
  Recent: "recent",
  All: "all"
};

const updateSubscriptionActivity = ({
  state,
  activityPath,
  activity,
  context
}: UpdateSubscriptionActivity) => {
  const filterNames = state?.filters?.[context] || [];
  const filters = getActivityTypes(filterNames);
  if (filters.length && !filters.includes(activity.type)) return state;
  setDeep(state, activityPath, activity);
  return state;
};

export const getActivityTypes = (typeNames: string[]) =>
  typeNames.reduce<string[]>((activityParams, type) => {
    const param = (
      activityTypes as Record<
        string,
        (typeof activityTypes)[keyof typeof activityTypes]
      >
    )[type];
    return param ? activityParams.concat(param.types) : activityParams;
  }, []);

const initialState: ActivityState = {
  filters: {
    tab: [ActivityTabName.All]
  },
  byProject: {
    loading: false,
    hasMore: false,
    loadingMore: false,
    data: {}
  },
  byEnvironment: {
    loading: false,
    hasMore: false,
    loadingMore: false,
    data: {}
  },
  byIntegration: {
    loading: false,
    hasMore: false,
    loadingMore: false,
    data: {}
  }
};

const activity = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setActivitiesTab(state, action) {
      setDeep(state, ["filters", "tab"], action.payload);
      return state;
    },
    setActivityFilterNames(state, action) {
      setDeep(
        state,
        ["filters", action.payload?.context],
        action.payload?.filterNames
      );
      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadProjectActivities.pending, state =>
        setDeep(state, ["byProject", "loading"], true)
      )
      .addCase(loadProjectActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          activities
        } = action.payload;
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byProject", "hasMore"], hasMore);
        setDeep(
          state,
          [
            "byProject",
            "data",
            organizationDescriptionId,
            projectDescriptionId
          ],
          normalize(activities || [], "id")
        );
      })
      .addCase(loadProjectActivities.rejected, (state, { payload }) => {
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byProject", "loadingMore"], false);
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byEnvironment", "loadingMore"], false);
        setDeep(state, ["byIntegration", "loading"], false);
        setDeep(state, ["byIntegration", "loadingMore"], false);
        setDeep(state, ["error"], payload as Error);
      })
      .addCase(loadEnvironmentActivities.pending, state => {
        setDeep(state, ["byEnvironment", "loading"], true);
      })
      .addCase(loadEnvironmentActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          activities
        } = action.payload;
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byEnvironment", "hasMore"], hasMore);
        setDeep(
          state,
          [
            "byEnvironment",
            "data",
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId
          ],
          normalize(activities || [], "id")
        );
      })
      .addCase(loadEnvironmentActivities.rejected, (state, { payload }) => {
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byProject", "loadingMore"], false);
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byEnvironment", "loadingMore"], false);
        setDeep(state, ["byIntegration", "loading"], false);
        setDeep(state, ["byIntegration", "loadingMore"], false);
        setDeep(state, ["error"], payload as Error);
      })
      .addCase(loadIntegrationActivities.pending, state => {
        setDeep(state, ["byIntegration", "loading"], true);
      })
      .addCase(loadIntegrationActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          integrationDescriptionId,
          activities
        } = action.payload;
        setDeep(state, ["byIntegration", "loading"], false);
        setDeep(state, ["byIntegration", "hasMore"], hasMore);
        setDeep(
          state,
          [
            "byIntegration",
            "data",
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId
          ],
          normalize(activities || [], "id")
        );
      })
      .addCase(loadIntegrationActivities.rejected, (state, { payload }) => {
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byProject", "loadingMore"], false);
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byEnvironment", "loadingMore"], false);
        setDeep(state, ["byIntegration", "loading"], false);
        setDeep(state, ["byIntegration", "loadingMore"], false);
        setDeep(state, ["error"], payload as Error);
      })
      .addCase(loadMoreEnvironmentActivities.pending, state => {
        setDeep(state, ["byEnvironment", "loadingMore"], true);
      })
      .addCase(loadMoreEnvironmentActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          activities
        } = action.payload;
        const currentActivities =
          state.byEnvironment?.data?.[organizationDescriptionId]?.[
            projectDescriptionId
          ]?.[environmentDescriptionId];
        const loadedActivities = normalize(activities, "id");
        const updatedActivities = Object.values({
          ...currentActivities,
          ...loadedActivities
        });
        setDeep(state, ["byEnvironment", "loadingMore"], false);
        setDeep(state, ["byEnvironment", "hasMore"], hasMore);
        setDeep(
          state,
          [
            "byEnvironment",
            "data",
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId
          ],
          normalize(updatedActivities, "id")
        );
      })
      .addCase(loadMoreEnvironmentActivities.rejected, (state, { payload }) => {
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byProject", "loadingMore"], false);
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byEnvironment", "loadingMore"], false);
        setDeep(state, ["byIntegration", "loading"], false);
        setDeep(state, ["byIntegration", "loadingMore"], false);
        setDeep(state, ["error"], payload as Error);
      })
      .addCase(loadMoreProjectActivities.pending, state => {
        setDeep(state, ["byProject", "loadingMore"], true);
      })
      .addCase(loadMoreProjectActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          activities
        } = action.payload;

        const currentActivities =
          state.byProject?.data?.[organizationDescriptionId]?.[
            projectDescriptionId
          ];
        const loadedActivities = normalize(activities, "id");
        const updatedActivities = Object.values({
          ...currentActivities,
          ...loadedActivities
        });
        setDeep(state, ["byProject", "loadingMore"], false);
        setDeep(state, ["byProject", "hasMore"], hasMore);
        setDeep(
          state,
          [
            "byProject",
            "data",
            organizationDescriptionId,
            projectDescriptionId
          ],
          normalize(updatedActivities, "id")
        );
      })
      .addCase(loadMoreProjectActivities.rejected, (state, { payload }) => {
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byProject", "loadingMore"], false);
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byEnvironment", "loadingMore"], false);
        setDeep(state, ["byIntegration", "loading"], false);
        setDeep(state, ["byIntegration", "loadingMore"], false);
        setDeep(state, ["error"], payload as Error);
      })
      .addCase(loadMoreIntegrationActivities.pending, state => {
        setDeep(state, ["byIntegration", "loadingMore"], true);
      })
      .addCase(loadMoreIntegrationActivities.fulfilled, (state, action) => {
        const {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          integrationDescriptionId,
          activities
        } = action.payload;

        const currentActivities =
          state.byIntegration?.data?.[organizationDescriptionId]?.[
            projectDescriptionId
          ]?.[integrationDescriptionId] || {};
        const loadedActivities = normalize(activities, "id");
        const updatedActivities = Object.values({
          ...currentActivities,
          ...loadedActivities
        });
        setDeep(state, ["byIntegration", "loadingMore"], false);
        setDeep(state, ["byIntegration", "hasMore"], hasMore);
        setDeep(
          state,
          [
            "byIntegration",
            "data",
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId
          ],
          normalize(updatedActivities, "id")
        );
      })
      .addCase(loadMoreIntegrationActivities.rejected, (state, { payload }) => {
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byProject", "loadingMore"], false);
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byEnvironment", "loadingMore"], false);
        setDeep(state, ["byIntegration", "loading"], false);
        setDeep(state, ["byIntegration", "loadingMore"], false);
        setDeep(state, ["error"], payload as Error);
      })
      .addCase(loadActivitySuccess.pending, state => {
        setDeep(state, ["byEnvironment", "loading"], true);
        setDeep(state, ["byProject", "loading"], true);
        setDeep(state, ["byIntegration", "loading"], true);
      })
      .addCase(loadActivitySuccess.rejected, state => {
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byIntegration", "loading"], false);
      })
      .addCase(loadActivitySuccess.fulfilled, (state, { payload = [] }) => {
        payload.forEach(payload => {
          const {
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId,
            integrationDescriptionId,
            activity
          } = payload;

          const byProject = updateSubscriptionActivity({
            state: state as ActivityState,
            activityPath: [
              "byProject",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              activity.id
            ],
            activity: activity,
            context: ACTIVITY_CONTEXT.Project
          });
          const byEnvironment = updateSubscriptionActivity({
            state: byProject,
            activityPath: [
              "byEnvironment",
              "data",
              organizationDescriptionId,
              projectDescriptionId,
              environmentDescriptionId,
              activity.id
            ],
            activity: activity,
            context: ACTIVITY_CONTEXT.Environment
          });

          if (integrationDescriptionId) {
            state = updateSubscriptionActivity({
              state: byEnvironment,
              activityPath: [
                "byIntegration",
                "data",
                organizationDescriptionId,
                projectDescriptionId,
                integrationDescriptionId,
                activity.id
              ],
              activity: activity,
              context: ACTIVITY_CONTEXT.Integration
            });
          }
        });
        setDeep(state, ["byProject", "loading"], false);
        setDeep(state, ["byEnvironment", "loading"], false);
        setDeep(state, ["byIntegration", "loading"], false);
      });
  }
});

export const { setActivitiesTab, setActivityFilterNames } = activity.actions;
export default activity.reducer;
