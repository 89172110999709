import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type { EnvironmentDomain, Project } from "@packages/client";

export interface addEnvironmentDomainProps {
  name: string;
  environmentId: string;
  type?: string;
  replacement_for?: string;
}

export const addEnvironmentDomainThunk = createAsyncThunk<
  EnvironmentDomain | undefined,
  { data: addEnvironmentDomainProps; project: Project },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/environment/domain/add",
  async ({ data, project }, { rejectWithValue }) => {
    try {
      const result = await project.addEnvironmentDomain(
        data.name,
        data.environmentId,
        data.replacement_for
      );
      const environmentDomain = await result.getEntity();
      return environmentDomain;
    } catch (err: any) {
      logger(
        { errorMessage: err.message, projectId: project.id },
        { action: "projectAddEnvironmentDomain" }
      );
      return rejectWithValue({ error: err });
    }
  }
);
