import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import localForage from "localforage";

import consoleConfig from "console_config";
import { ACTIVITY_TYPES } from "Constants/constants";
import getDefaultBlankWizardSteps from "Constants/wizard/blankSetup";
import getDefaultTemplateWizardSteps from "Constants/wizard/templateSetup";
import { WizardStep } from "Constants/wizard/wizardStepTypes";
import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import client from "Libs/platform";
import { getGitTemplateName, isJson } from "Libs/utils";
import { selectProjectActivitiesByType } from "Reducers/activity";
import { selectReducedWizardEnabled } from "Reducers/featureFlags/featureFlags.selectors";
import { subscriptionSelector } from "Reducers/subscription";
import { AsyncThunkOptionType } from "Reducers/types";

import type { Activity, Project, SetupConfig } from "@packages/client";

type WizardStatus = {
  [key: string]: {
    [key: string]: {
      isOpen?: boolean;
    };
  };
};
type RegistryData = {
  data?: {
    [key: string]: {
      data: unknown;
    };
  };
};

type CompletedIds = {
  routes?: boolean;
  application?: boolean;
  configure?: boolean;
  remote?: boolean;
  yaml?: boolean;
  services?: boolean;
  push?: boolean;
};

type WizardData = {
  [organizationId: string]: {
    [projectId: string]: {
      loading: boolean;
      start?: boolean;
      isOpen?: boolean;
      data?: WizardStep[];
      currentStep: number;
      completed?: CompletedIds;
      finish?: boolean;
      animate?: boolean;
      errors?: unknown;
    };
  };
};

type ProjectWizardState = {
  data?: WizardData;
  registry: {
    loading: boolean;
    data?: RegistryData;
    errors?: unknown;
  };
  config: {
    loading: boolean;
    errors?: unknown;
    data?: unknown;
    forceOpenProjectWizard?: boolean;
  };
};

const initialState: ProjectWizardState = {
  registry: {
    loading: false
  },
  config: {
    loading: false
  }
};

type APIError = {
  code: number;
};

function isAPIError(err: unknown): err is APIError {
  return (err as APIError).code !== undefined;
}

const checkWizard = async () => {
  const wizardStatus =
    (await localForage.getItem<WizardStatus>("wizardStatus")) || {};
  return wizardStatus;
};

const setWizard = async (
  organizationId: string,
  projectId: string,
  status: boolean | undefined
) => {
  const wizardStatus = await checkWizard();
  setDeep(wizardStatus, [organizationId, projectId, "isOpen"], status);

  localForage.setItem("wizardStatus", wizardStatus);
};

const getTemplateSteps = async (organization, repository?: string) => {
  const template = getGitTemplateName(repository);

  if (!template) return [];
  const result = await organization
    .getWizardSteps({ template })
    .catch(err => logger(err));
  return result?.steps;
};

const getProjectActivities = async (project: Project, types: string[]) => {
  try {
    const activities = await project.getActivities(types);

    return types.reduce<Record<string, Activity[] | undefined>>((acc, type) => {
      acc[type] = activities?.filter(elt => elt.type === type);
      return acc;
    }, {});
  } catch (err) {
    logger(err);
  }
};

export const loadRegistry = createAsyncThunk(
  "app/wizard/registry",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const registry = (await client.getSetupRegistry()) as RegistryData;
      dispatch(loadConfig({ registry }));
      return registry;
    } catch (err) {
      const errorMessage = isJson(err)
        ? err
        : "An error occurred accessing setup/registry ";

      logger(errorMessage, {
        action: "setup_registry"
      });
      return rejectWithValue({ errors: errorMessage });
    }
  }
);

export const loadConfig = createAsyncThunk(
  "app/wizard/config",
  async ({ registry }: { registry: RegistryData }, { rejectWithValue }) => {
    const getSetupConfig = async (
      service: string
    ): Promise<
      { success: true; value?: SetupConfig } | { success: false; error: string }
    > => {
      try {
        const config = await client.getSetupConfig({ service });
        return { value: config, success: true };
      } catch (err: unknown) {
        const defaultMessage = `An error occurred accessing setup/config: ${service}`;
        if (isAPIError(err) && ![404, 403].includes(err.code)) {
          const errorMessage = isJson(err) ? err : defaultMessage;
          logger(errorMessage, {
            action: "setup_config"
          });
        }
        return { error: defaultMessage, success: false };
      }
    };

    const configItems: Record<string, SetupConfig | undefined> = {};
    const keys = Object.keys(registry);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const config = await getSetupConfig(key);

      if (config.success) {
        configItems[key] = config.value;
      } else {
        return rejectWithValue(config.error);
      }
    }

    return configItems;
  }
);

export const replaceWithCustomeAppName = (wizardSteps: WizardStep[]) => {
  const customName = consoleConfig.CUSTOM_APP_NAME;
  if (!customName) return wizardSteps;

  const PSH = /([^./][\s]*)(Platform\.sh|platformsh):?(\.|)/gi;
  return wizardSteps.map(step => {
    step.bodyText = step?.bodyText?.replaceAll(PSH, `$1${customName}$3`);
    step.label = step.label?.replaceAll(PSH, `$1${customName}$3`);
    step.title = step.title?.replaceAll(PSH, `$1${customName}$3`);
    step.copyCode = step.copyCode?.map(({ label, code }) => ({
      code,
      label: label?.replaceAll(PSH, `$1${customName}$3`)
    }));
    return step;
  });
};

export const loadProjectWizard = createAsyncThunk<
  {
    wizardSteps: WizardStep[];
    completedIds: CompletedIds;
    isOpen?: boolean;
  },
  {
    organizationId: string;
    projectId: string;
  },
  AsyncThunkOptionType
>(
  "app/project/wizard/load",
  async (
    { organizationId, projectId },
    { getState, extra, rejectWithValue }
  ) => {
    const forceOpenProjectWizard =
      getState().projectWizard?.config?.forceOpenProjectWizard;
    const organization =
      getState().organization?.orgByDescriptionField?.[organizationId];

    const isReducedWizardEnabled = selectReducedWizardEnabled(getState());

    const completedIds: CompletedIds = {};

    const project = getState().project.data?.[organizationId]?.[projectId];

    if (typeof project === "undefined") {
      return rejectWithValue("Project not found");
    }

    const deployment = getState().deployment?.data;

    const currentDeployment =
      deployment?.[project.default_branch || "master"]?.current;

    const webApps = currentDeployment?.webapps;
    if (webApps) {
      completedIds.application = true;
    }

    const services = currentDeployment?.services;
    if (services) {
      completedIds.services = true;
    }

    const routes = currentDeployment?.routes;
    if (routes) {
      completedIds.routes = true;
    }

    if (webApps && services && routes) {
      completedIds.yaml = true;
    }

    const pushActivity = selectProjectActivitiesByType([
      ACTIVITY_TYPES.ENVIRONMENT.PUSH
    ])(getState(), { organizationId, projectId });

    const projectActivities = await getProjectActivities(project, [
      ACTIVITY_TYPES.ENVIRONMENT.PUSH,
      ACTIVITY_TYPES.ENVIRONMENT.INITIALIZE
    ]);

    if (pushActivity?.length > 0) {
      completedIds.push = true;
      completedIds.remote = true;
    } else {
      if (
        projectActivities?.[ACTIVITY_TYPES.ENVIRONMENT.PUSH] &&
        (projectActivities as Record<string, Activity[]>)?.[
          ACTIVITY_TYPES.ENVIRONMENT.PUSH
        ].length > 0
      ) {
        completedIds.push = true;
        completedIds.remote = true;
      }
    }

    const mainBranch = project.default_branch;
    let wizardSteps = getDefaultBlankWizardSteps(extra.getIntl(), mainBranch);

    wizardSteps = isReducedWizardEnabled
      ? getDefaultTemplateWizardSteps(extra.getIntl(), isReducedWizardEnabled)
      : wizardSteps;

    if (
      projectActivities?.[ACTIVITY_TYPES.ENVIRONMENT.INITIALIZE] &&
      (projectActivities as Record<string, Activity[]>)?.[
        ACTIVITY_TYPES.ENVIRONMENT.INITIALIZE
      ].length > 0
    ) {
      const subscription = subscriptionSelector(getState(), {
        organizationId,
        projectId,
        id: project.subscription_id
      });
      const customSteps = await getTemplateSteps(
        organization,
        subscription?.project_options?.initialize?.repository
      );
      if (customSteps?.length) {
        wizardSteps = customSteps;
      } else {
        wizardSteps = getDefaultTemplateWizardSteps(
          extra.getIntl(),
          isReducedWizardEnabled
        );
        completedIds.configure = true;
      }
    }

    // The user already clicked on the button but the loading was not done
    let isOpen =
      getState().projectWizard.data?.[organizationId]?.[projectId]?.isOpen;

    // If the opening status of the wizard is not yet managed by Redux,
    // test if the status is present on the local storage
    // or force the opening if the project is new.

    if (typeof isOpen === "undefined") {
      const wizardStatus = await checkWizard();

      isOpen = wizardStatus?.[organizationId]?.[projectId]?.isOpen;
    }

    return {
      wizardSteps: replaceWithCustomeAppName(wizardSteps),
      completedIds,
      isOpen: isOpen || forceOpenProjectWizard
    };
  }
);

export const toggleProjectWizard = createAsyncThunk<
  boolean,
  { organizationId: string; projectId: string },
  AsyncThunkOptionType
>(
  "app/project/wizard/toggle",
  ({ organizationId, projectId }, { getState }) => {
    const isOpen =
      getState().projectWizard.data?.[organizationId]?.[projectId]?.isOpen ||
      false;
    const newState = !isOpen;
    setWizard(organizationId, projectId, newState);
    return newState;
  }
);

export const openProjectWizard = createAsyncThunk<
  boolean,
  { organizationId: string; projectId: string },
  AsyncThunkOptionType
>("app/project/wizard/open", ({ organizationId, projectId }, { getState }) => {
  const isOpen =
    getState().projectWizard?.data?.[organizationId]?.[projectId]?.isOpen ||
    false;

  if (!isOpen) {
    setWizard(organizationId, projectId, true);
    return false;
  }
  return true;
});

const wizard = createSlice({
  name: "app/project/wizard",
  initialState,
  reducers: {
    setForceOpen: (state, { payload }) =>
      setDeep(state, ["config", "forceOpenProjectWizard"], payload),
    nextWizardStep: (
      state,
      action: PayloadAction<{ organizationId: string; projectId: string }>
    ) => {
      const { organizationId, projectId } = action.payload;
      const wizardState = state.data?.[organizationId]?.[projectId];

      const currentStep = wizardState!.currentStep;

      const nextStep = currentStep + 1;
      const completedIds = wizardState?.completed;

      if (nextStep)
        setDeep(
          state,
          ["data", organizationId, projectId, "currentStep"],
          nextStep
        );
      setDeep(state, ["data", organizationId, projectId, "start"], false);
      if (wizardState?.data && nextStep)
        setDeep(
          state,
          ["data", organizationId, projectId, "finish"],
          wizardState?.data?.length < nextStep
        );
      setDeep(
        state,
        ["data", organizationId, projectId, "completed"],
        completedIds
      );
    },
    prevWizardStep: (state, action) => {
      const { organizationId, projectId } = action.payload;
      const wizardState = state?.data?.[organizationId]?.[projectId];
      const currentStep = wizardState?.currentStep;
      const prevStep = currentStep && currentStep - 1;

      if (prevStep)
        setDeep(
          state,
          ["data", organizationId, projectId, "currentStep"],
          prevStep
        );
      setDeep(
        state,
        ["data", organizationId, projectId, "start"],
        prevStep === 0
      );
      setDeep(state, ["data", organizationId, projectId, "finish"], false);
    },
    goToWizardStep: (state, action) => {
      const { organizationId, projectId, step } = action.payload;
      if (step)
        setDeep(
          state,
          ["data", organizationId, projectId, "currentStep"],
          step
        );
      setDeep(state, ["data", organizationId, projectId, "start"], false);
      setDeep(state, ["data", organizationId, projectId, "finish"], false);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadProjectWizard.pending, (state, action) => {
        const { organizationId, projectId } = action.meta.arg;
        setDeep(state, ["data", organizationId, projectId, "loading"], true);
        setDeep(state, ["data", organizationId, projectId, "start"], false);
      })
      .addCase(loadProjectWizard.fulfilled, (state, action) => {
        const { organizationId, projectId } = action.meta.arg;
        setDeep(state, ["data", organizationId, projectId, "loading"], false);
        setDeep(
          state,
          ["data", organizationId, projectId, "data"],
          action.payload.wizardSteps
        );
        setDeep(state, ["data", organizationId, projectId, "currentStep"], 0);
        setDeep(
          state,
          ["data", organizationId, projectId, "completed"],
          action.payload.completedIds
        );
        setDeep(
          state,
          ["data", organizationId, projectId, "isOpen"],
          action.payload.isOpen
        );
        setDeep(state, ["data", organizationId, projectId, "start"], true);
        setDeep(state, ["data", organizationId, projectId, "finish"], false);
        setDeep(state, ["data", organizationId, projectId, "errors"], false);
      })
      .addCase(loadProjectWizard.rejected, (state, action) => {
        const { organizationId, projectId } = action.meta.arg;
        setDeep(state, ["data", organizationId, projectId, "loading"], false);
        setDeep(
          state,
          ["data", organizationId, projectId, "errors"],
          action.payload
        );
      })

      // TOGGLE WIZARD
      .addCase(toggleProjectWizard.fulfilled, (state, action) => {
        const { organizationId, projectId } = action.meta.arg;
        setDeep(
          state,
          ["data", organizationId, projectId, "isOpen"],
          action?.payload
        );
        setDeep(state, ["data", organizationId, projectId, "start"], true);
        setDeep(state, ["data", organizationId, projectId, "finish"], false);
        setDeep(state, ["data", organizationId, projectId, "currentStep"], 0);
      })

      // OPEN WIZARD
      .addCase(openProjectWizard.pending, (state, action) => {
        const {
          organizationId,
          projectId
        }: { organizationId: string; projectId: string } = action.meta.arg;

        delete state.data?.[organizationId]?.[projectId]?.animate;
      })
      .addCase(openProjectWizard.fulfilled, (state, action) => {
        const {
          organizationId,
          projectId
        }: { organizationId: string; projectId: string } = action.meta.arg;
        setDeep(state, ["data", organizationId, projectId, "isOpen"], true);
        setDeep(
          state,
          ["data", organizationId, projectId, "animate"],
          action.payload
        );

        if (!action.payload) {
          setDeep(state, ["data", organizationId, projectId, "start"], true);
          setDeep(state, ["data", organizationId, projectId, "finish"], false);
          setDeep(state, ["data", organizationId, projectId, "currentStep"], 0);
        }
      })

      // REGISTRY
      .addCase(loadRegistry.pending, state =>
        setDeep(state, ["registry", "loading"], true)
      )
      .addCase(loadRegistry.fulfilled, (state, action) => {
        setDeep(state, ["registry", "data"], action.payload);
        setDeep(state, ["registry", "loading"], false);
      })
      .addCase(loadRegistry.rejected, (state, action) => {
        setDeep(state, ["registry", "errors"], action?.error);
        setDeep(state, ["registry", "loading"], false);
      })

      // CONFIG
      .addCase(loadConfig.pending, state =>
        setDeep(state, ["config", "loading"], true)
      )
      .addCase(loadConfig.fulfilled, (state, action) => {
        setDeep(state, ["config", "data"], action.payload);
        setDeep(state, ["config", "loading"], false);
      })
      .addCase(loadConfig.rejected, (state, action) => {
        setDeep(state, ["config", "errors"], action.payload);
        setDeep(state, ["config", "loading"], false);
      });
  }
});

export const { setForceOpen, nextWizardStep, prevWizardStep, goToWizardStep } =
  wizard.actions;

export const projectWizardSelector = (
  { projectWizard }: { projectWizard: ProjectWizardState },
  { organizationId, projectId }: { organizationId: string; projectId: string }
) => projectWizard?.data?.[organizationId]?.[projectId];

export const configDataSelector = ({
  projectWizard
}: {
  projectWizard: ProjectWizardState;
}) => projectWizard?.config?.data;

export const configLoadingSelector = ({
  projectWizard
}: {
  projectWizard: ProjectWizardState;
}) => projectWizard?.config.loading || false;

export const registryDataSelector = ({
  projectWizard
}: {
  projectWizard: ProjectWizardState;
}) => projectWizard?.registry?.data;

export const registryLoadingSelector = ({
  projectWizard
}: {
  projectWizard: ProjectWizardState;
}) => projectWizard?.registry?.loading || false;

export default wizard.reducer;
