import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { environmentSelector } from "Reducers/environment";
import { AsyncThunkOptionType } from "Reducers/types";

import { ResumeEnvironmentActionProp } from "../";

import type { Activity } from "@packages/client";

export const resume = createAsyncThunk<
  Activity,
  ResumeEnvironmentActionProp,
  AsyncThunkOptionType
>(
  "app/environment/actions/resume",
  async (
    { organizationId, projectId, environmentId },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment = environmentSelector(getState(), {
        organizationId: organizationId,
        projectId: projectId,
        environmentId: environmentId
      });

      if (!environment) return rejectWithValue("Environment not found");

      const resume = await environment.resume();
      return resume;
    } catch (error) {
      if (![404, 403].includes((error as { code: number })?.code)) {
        logger(error, {
          action: "resume",
          meta: {
            organizationId,
            environmentId,
            projectId
          }
        });
      }
      return rejectWithValue(error);
    }
  }
);
