import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";
import { getOrganizationId } from "Libs/utils";
import { setUserAttributesDrift } from "Reducers/app";
import { currentTestTrialSelector } from "Reducers/testMode";
import { AsyncThunkOptionType } from "Reducers/types";

import { loadOrganization } from ".";

import type { OrganizationProfile, APIObject } from "@packages/client";
import type { PublicProperties } from "Libs/publicProperties";
import type { RootState } from "Store/configureStore";

type CanCreateAction = "billing_details" | "ticket" | "retry" | "permission";

type CanCreateType =
  | "ticket"
  | "phone"
  | "credit-card"
  | "project-provisioning";

type CanCreateResponse = {
  can_create: boolean;
  message: string;
  required_action: {
    type?: CanCreateType;
    action?: CanCreateAction;
    credentials: {
      client_secret: string;
      public_key: string;
      automatic_payment_methods: boolean;
    } | null;
  };
};

export type OrganizationProfileProperties = Partial<
  PublicProperties<OrganizationProfile>
> &
  Partial<{
    canCreate: boolean;
    requiredAction: Partial<{
      type: CanCreateType;
      action: CanCreateAction;
      message: string;
      credentials: {
        client_secret: string;
        public_key: string;
        automatic_payment_methods: boolean;
      } | null;
    }>;
  }>;

export const getOrganizationProfile = createAsyncThunk<
  OrganizationProfileProperties,
  { organizationId: string },
  AsyncThunkOptionType
>(
  "app/organization/profile",
  async ({ organizationId }, { getState, rejectWithValue, dispatch }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const getOrganizationSubscriptionCanCreate = async () => {
        const orgId = getOrganizationId(getState, organizationId);
        const canCreate = orgId
          ? ((await client.getOrganizationSubscriptionCanCreate(
              orgId
            )) as CanCreateResponse)
          : undefined;

        let retryCallbackId;

        if (canCreate?.required_action?.action === "retry") {
          retryCallbackId = setTimeout(
            () =>
              getOrganizationSubscriptionCanCreate().then(result =>
                dispatch(
                  updateOrganizationRequiredAction({
                    organizationId,
                    requiredAction: result?.required_action,
                    canCreate: result?.can_create,
                    // @ts-expect-error the client js doesn't have the credential types
                    credentials: result?.credentials ?? null
                  })
                )
              ),
            2000
          );
        } else {
          clearTimeout(retryCallbackId);
        }

        return canCreate;
      };

      const canCreate = await getOrganizationSubscriptionCanCreate();

      let orgId = getOrganizationId(getState, organizationId);

      if (!orgId && organizationId !== "projects" && organizationId !== "-") {
        await dispatch(loadOrganization(organizationId));
        orgId = getOrganizationId(getState, organizationId);
      }

      const profile = orgId
        ? await client
            .getOrganizationProfile(orgId)
            // We do this because if the user doesn't have enough permissions to call the profile endpoint we still want this to now throw
            .catch(() => {
              return undefined;
            })
        : undefined;
      setUserAttributesDrift({
        organizationProfile: profile
      });
      return {
        ...profile,
        canCreate: canCreate?.can_create,
        requiredAction: {
          ...canCreate?.required_action,
          message: canCreate?.message
        }
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOrganizationProfile = createAsyncThunk<
  OrganizationProfile,
  { organizationId: string; data: APIObject },
  AsyncThunkOptionType
>(
  "app/organization/profile/update",
  async ({ organizationId, data }, { getState, rejectWithValue }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    try {
      const profile = await client.updateOrganizationProfile(
        getOrganizationId(getState, organizationId) || organizationId,
        data
      );

      return profile;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

type OrganizationProfileState = {
  update?: unknown;
  data?: Record<
    string,
    {
      loading: boolean;
      data: OrganizationProfileProperties;
      errors?: string;
    }
  >;
};

const initialState: OrganizationProfileState = {};

const organizationProfile = createSlice({
  name: "organizationProfile",
  initialState,
  reducers: {
    clearUpdatedOrganizationProfile: state => {
      state.update = undefined;
    },
    updateOrganizationRequiredAction: (
      state,
      action: PayloadAction<{
        organizationId: string;
        requiredAction:
          | {
              type?: CanCreateType;
              action?: CanCreateAction;
            }
          | undefined;
        canCreate: boolean | undefined;
      }>
    ) => {
      setDeep(
        state,
        ["data", action.payload.organizationId, "data", "requiredAction"],
        action.payload.requiredAction
      );
      setDeep(
        state,
        ["data", action.payload.organizationId, "data", "canCreate"],
        action.payload.canCreate
      );
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getOrganizationProfile.pending, (state, { meta }) => {
        setDeep(state, ["data", meta.arg.organizationId, "loading"], true);
      })
      .addCase(getOrganizationProfile.fulfilled, (state, action) => {
        const { organizationId } = action.meta.arg;
        setDeep(state, ["data", organizationId, "loading"], false);
        setDeep(state, ["data", organizationId, "data"], action.payload);
      })
      .addCase(getOrganizationProfile.rejected, (state, action) => {
        const { organizationId } = action.meta.arg;
        setDeep(state, ["data", organizationId, "loading"], false);
        setDeep(
          state,
          ["data", organizationId, "errors"],
          action.error.message
        );
      })
      .addCase(updateOrganizationProfile.pending, (state, { meta }) => {
        setDeep(state, ["data", meta.arg.organizationId, "loading"], true);
      })
      .addCase(
        updateOrganizationProfile.fulfilled,
        (state, { meta, payload }) => {
          const { organizationId } = meta.arg;
          setDeep(state, ["data", organizationId, "loading"], false);
          setDeep(state, ["data", organizationId, "data"], payload);
        }
      )
      .addCase(
        updateOrganizationProfile.rejected,
        (state, { meta, payload }) => {
          const { organizationId } = meta.arg;
          setDeep(state, ["data", organizationId, "loading"], false);
          setDeep(
            state,
            ["data", organizationId, "errors"],
            payload.message ?? payload.detail
          );
        }
      );
  }
});

export const {
  clearUpdatedOrganizationProfile,
  updateOrganizationRequiredAction
} = organizationProfile.actions;
export default organizationProfile.reducer;

export const organizationProfileSelector = (
  state: RootState,
  props: { organizationId: string }
) => {
  return state.organizationProfile?.data?.[props.organizationId]?.data;
};
export const organizationProfileErrorSelector = (
  state: RootState,
  props: { organizationId: string }
) => {
  return state.organizationProfile?.data?.[props.organizationId]?.errors;
};

export const selectIsLoadingOrganizationProfile = (
  state: RootState,
  organizationId: string
) => state.organizationProfile?.data?.[organizationId]?.loading;

export const isUpdatingOrganizationProfileSelector = (
  state: RootState,
  organizationId: string
) => state.organizationProfile?.data?.[organizationId]?.loading;

export const organizationProfileUpdateErrorSelector = (
  state: RootState,
  organizationId: string
) => state.organizationProfile?.data?.[organizationId]?.errors;

export const updatedOrganizationProfileSelector = (
  state: RootState,
  organizationId: string
) => organizationProfileSelector(state, { organizationId });

export const organizationProfileCurrenTrialSelector = createSelector(
  currentTestTrialSelector,
  organizationProfileSelector,
  (testTrial, profile) => testTrial || profile?.current_trial
);
