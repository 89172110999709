import { createSlice } from "@reduxjs/toolkit";

import { addDomainThunk } from "./thunks/addDomain.thunk";
import { deleteDomainThunk } from "./thunks/deleteDomain.thunk";
import { getDomainsThunk } from "./thunks/getDomains.thunk";
import { updateDefaultDomain } from "./thunks/updateDefaultDomain.thunk";

import type { Domain } from "@packages/client";

export type DomainSettingsState = Readonly<{
  [projectId: string]: {
    loading: boolean;
    status?: "added" | "deleted" | "pending" | "rejected" | "updated";
    errors?: any;
    data?: Domain[];
  };
}>;

const initialState: DomainSettingsState = {};

const domainsSlice = createSlice({
  name: "app/project/domains",
  initialState,
  reducers: {
    resetStatus(state, { payload }) {
      const { projectId } = payload;
      state[projectId] = {
        ...state[projectId],
        ...{ errors: undefined, status: undefined }
      };
    }
  },
  extraReducers: builder => {
    // Get Domains
    builder
      .addCase(getDomainsThunk.pending, (state, { meta }) => {
        const { project } = meta.arg;
        state[project.id] = {
          ...state[project.id],
          ...{ errors: undefined, loading: true, status: undefined }
        };
      })
      .addCase(getDomainsThunk.fulfilled, (state, { meta, payload }) => {
        const { project } = meta.arg;
        state[project.id] = {
          ...state[project.id],
          ...{ loading: false, data: payload }
        };
      })
      .addCase(getDomainsThunk.rejected, (state, { meta, payload }) => {
        const { project } = meta.arg;
        state[project.id] = {
          ...state[project.id],
          ...{
            loading: false,
            errors: payload?.error
          }
        };
      });
    // Add Domain
    builder
      .addCase(addDomainThunk.pending, (state, { meta }) => {
        const { project } = meta.arg;
        state[project.id] = { ...state[project.id], ...{ status: "pending" } };
      })
      .addCase(addDomainThunk.fulfilled, (state, { meta, payload }) => {
        const { project } = meta.arg;
        const domains = state[project.id]?.data;
        if (payload) domains?.push(payload);
        state[project.id] = {
          ...state[project.id],
          ...{
            status: "added",
            data: domains
          }
        };
      })
      .addCase(addDomainThunk.rejected, (state, { meta, payload }) => {
        const { project } = meta.arg;
        state[project.id] = {
          ...state[project.id],
          ...{ status: "rejected", errors: payload?.error }
        };
      });

    // Update Default Domain
    builder
      .addCase(updateDefaultDomain.pending, (state, { meta }) => {
        const { projectId } = meta.arg;
        state[projectId] = { ...state[projectId], ...{ status: "pending" } };
      })
      .addCase(updateDefaultDomain.fulfilled, (state, { meta }) => {
        const { projectId } = meta.arg;
        state[projectId] = { ...state[projectId], ...{ status: "updated" } };
      })
      .addCase(updateDefaultDomain.rejected, (state, { meta, payload }) => {
        const { projectId } = meta.arg;
        state[projectId] = {
          ...state[projectId],
          ...{ status: "rejected", errors: payload?.error }
        };
      });

    // Delete Domain
    builder
      .addCase(deleteDomainThunk.pending, (state, { meta }) => {
        const { projectId } = meta.arg;
        state[projectId] = { ...state[projectId], ...{ status: "pending" } };
      })
      .addCase(deleteDomainThunk.fulfilled, (state, { meta, payload }) => {
        const { domain, projectId } = meta.arg;
        state[projectId] = {
          ...state[projectId],
          ...{
            status: "deleted",
            data: state[projectId]?.data
              ?.filter(elt => elt.id !== domain?.id)
              .map(elt => (elt.id === payload?.id ? payload : elt))
          }
        };
      })
      .addCase(deleteDomainThunk.rejected, (state, { meta, payload }) => {
        const { projectId } = meta.arg;
        state[projectId] = {
          ...state[projectId],
          ...{
            status: "rejected",
            errors: payload?.error
          }
        };
      });
  }
});

export const { resetStatus } = domainsSlice.actions;
export const domainsReducer = domainsSlice.reducer;
