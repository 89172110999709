import { createSlice } from "@reduxjs/toolkit";

import { createEnvironmentTypeAccessThunk } from "./thunks/createEnvironmentTypeAccess.thunk";
import { getEnvironmentTypeAccessesThunk } from "./thunks/getEnvironmentTypeAccesses.thunk";
import { updateEnvironmentTypeAccessThunk } from "./thunks/updateEnvironmentTypeAccess.thunk";

import type { EnvironmentType } from "@packages/client";

export type EnvironmentTypeState = Readonly<{
  data: EnvironmentType[];
  loading: boolean;
  errors?: unknown;
}>;

const initialState: EnvironmentTypeState = {
  loading: false,
  data: []
};

const projectEnvironmentTypes = createSlice({
  name: "projectEnvironmentTypes",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEnvironmentTypeAccessesThunk.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getEnvironmentTypeAccessesThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data = action.payload;
      }
    );

    builder.addCase(
      getEnvironmentTypeAccessesThunk.rejected,
      (state, action) => {
        state.loading = false;
        state.errors = action.payload;
      }
    );

    builder.addCase(
      createEnvironmentTypeAccessThunk.fulfilled,
      (state, action) => {
        const { payload, meta } = action;
        const environmentArgumentTypeId = meta.arg.environmentTypeId;
        if (payload) {
          const environmentType = state.data?.find(
            x => x.id === environmentArgumentTypeId
          );
          if (environmentType) {
            environmentType.accesses.push(payload);
          }
        }
      }
    );

    builder.addCase(
      createEnvironmentTypeAccessThunk.rejected,
      (state, action) => {
        state.errors = action.payload;
      }
    );

    builder.addCase(
      updateEnvironmentTypeAccessThunk.fulfilled,
      (state, action) => {
        const { payload, meta } = action;
        const environmentTypeId = meta.arg.environmentTypeId;
        const isRemovingAccess = !payload;

        if (isRemovingAccess) {
          state.data = state.data?.filter(x => x.id !== environmentTypeId);
        } else {
          const environmentType = state.data?.find(
            x => x.id === environmentTypeId
          );
          if (environmentType) {
            const accessIndex = environmentType.accesses.findIndex(
              a => a.id === payload?.id
            );
            if (accessIndex !== -1) {
              environmentType.accesses[accessIndex] = payload;
            } else {
              environmentType.accesses.push(payload);
            }
          }
        }
      }
    );

    builder.addCase(
      updateEnvironmentTypeAccessThunk.rejected,
      (state, action) => {
        state.errors = action.payload;
      }
    );
  }
});

export default projectEnvironmentTypes.reducer;
