import localForage from "localforage";

import config from "console_config";

import type {
  AnnouncementsResponse,
  Announcement,
  AnnouncementsState
} from ".";

const endpoint = config.URL_ANNOUNCEMENTS;
export const FORAGE_KEY = "readAnnouncements";

const transformArtworkURLs = (response: AnnouncementsResponse) => {
  if (!response.items) {
    return;
  }

  const urlRoot = response.production_url;

  return response.items.map(announcement => ({
    ...announcement,
    artwork: announcement.artwork && `${urlRoot}${announcement.artwork}`,
    ...(announcement.artwork && { tileTheme: announcement.tileTheme }),
    steps: announcement.steps.map(step => ({
      ...step,
      artwork: step.artwork && `${urlRoot}${step.artwork}`,
      ...(step.artwork && {
        tileTheme: step.tileTheme || announcement.tileTheme
      })
    }))
  }));
};

const hasURLFlag = () => window.location.search.includes("show-drafts=");

const filterDrafts = (
  announcements: ReturnType<typeof transformArtworkURLs>
) => {
  const showDrafts = hasURLFlag();
  return announcements?.filter(
    announcement => announcement.publish || showDrafts
  );
};

export const fetchAnnouncements = () =>
  fetch(`${endpoint}?h=${window.location.hostname}`, {
    headers: {
      Accept: "application/json"
    }
  })
    .then(response => response.json() as Promise<AnnouncementsResponse>)
    .then(transformArtworkURLs)
    .then(filterDrafts)
    .then(announcements => announcements?.slice(0, 15) || []);

export const getReadAnnouncements = async () =>
  (await localForage.getItem<number[]>(FORAGE_KEY)) || [];

export const flagReadAnnouncements = (
  announcements: Awaited<ReturnType<typeof fetchAnnouncements>>,
  read: Awaited<ReturnType<typeof getReadAnnouncements>>
) =>
  announcements.map(announcement => ({
    ...announcement,
    read: announcement.steps.length === 0 || read.includes(announcement.id),
    active: false
  }));

export const updateAnnouncementHandler = (
  state: AnnouncementsState,
  id: number,
  props: Partial<Announcement> = {}
): AnnouncementsState["announcements"] =>
  state.announcements.map(announcement => {
    if (announcement.id === id) {
      return {
        ...announcement,
        ...props
      };
    }
    return announcement;
  });
