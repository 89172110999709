import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";

import { PROJECT_ID_FIELD } from "Constants/constants";
import { setDeep } from "Libs/objectAccess";
import {
  UpdateProjectReturnPayload,
  ProjectStateType,
  LoadOneProjectPayloadReturn,
  loadProjectSuccess,
  loadAllProjects,
  subscribe,
  loadOneProject,
  loadOneProjectHelper,
  reloadProject,
  updateProject,
  ReloadProjectReturnPayload,
  getOrganizationDescriptionIdFromProject,
  loadExtendedAccess,
  OrgIdWithProjectIds
} from "Reducers/project/project";

import type { Project } from "@packages/client";

const initialState: ProjectStateType = {
  all: [],
  data: {},
  list: {},
  extendedAccess: [],
  loadingExtendedAccess: false,
  loadedList: false,
  loading: false,
  loadingList: false,
  orgByProjectId: {},
  projectLoadingError: "",
  projectUpdateError: ""
};
// For Loading, Updating, and Reloading
const projectSuccessHandler = (
  payload:
    | LoadOneProjectPayloadReturn
    | UpdateProjectReturnPayload
    | ReloadProjectReturnPayload,
  state: WritableDraft<ProjectStateType>
) => {
  const { project, projectMe, organizations } = payload;

  const orgName =
    getOrganizationDescriptionIdFromProject(projectMe, organizations) ?? "";

  const projectId = project[PROJECT_ID_FIELD];
  const updatedLocalProject: Project = project.updateLocal({
    ...project,
    owner_info: projectMe?.owner_info,
    organization_id: projectMe?.organization_id,
    plan_uri: projectMe?.plan_uri,
    region_label: projectMe?.region_label,
    plan: projectMe?.plan,
    subscription_id: projectMe?.subscription_id
  });

  state.loading = false;
  state.projectLoadingError = "";
  state.data = {
    [orgName]: {
      [projectId]: updatedLocalProject
    }
  };
  state.orgByProjectId = {
    [projectId]: orgName
  };
};

const projectReducer = createSlice({
  name: "app/project/index",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadExtendedAccess.pending, state => {
        state.loadingExtendedAccess = true;
      })
      .addCase(loadExtendedAccess.rejected, state => {
        state.loadingExtendedAccess = false;
      })
      .addCase(loadExtendedAccess.fulfilled, (state, action) => {
        state.loadingExtendedAccess = false;
        state.extendedAccess = action.payload.extendedAccess;
      });

    builder
      // LOAD PROJECT SUCCESS
      .addCase(loadProjectSuccess.fulfilled, (state, action) => {
        const { project, organizationId } = action.payload;

        const projectId: string = project[PROJECT_ID_FIELD];
        const updatedLocalProject = project.updateLocal({
          ...project,
          owner_info: project.owner_info,
          organization_id: project.organization_id,
          plan_uri: project.plan_uri,
          region_label: project.region_label,
          plan: project.plan,
          subscription_id: project?.subscription_id
        });

        setDeep(
          state,
          ["data", organizationId, projectId],
          updatedLocalProject
        );

        state.loading = false;

        setDeep(state, ["orgByProjectId", projectId], organizationId);

        state.projectLoadingError = "";
      })
      // LOAD ALL PROJECTS
      .addCase(loadAllProjects.pending, state => {
        state.loadingList = true;
      })
      .addCase(loadAllProjects.fulfilled, (state, action) => {
        const { projects, organizations } = action.payload;

        const newList = projects.reduce<OrgIdWithProjectIds>(
          (organizationsProjects, project) => {
            const orgName =
              getOrganizationDescriptionIdFromProject(project, organizations) ??
              "";

            if (!organizationsProjects[orgName]) {
              organizationsProjects[orgName] = {};
            }

            organizationsProjects[orgName][project[PROJECT_ID_FIELD]] = project;

            return organizationsProjects;
          },
          {}
        );

        state.loadingList = false;
        state.loadedList = true;
        state.list = newList;
        state.all = projects;
      })
      .addCase(loadAllProjects.rejected, (state, action) => {
        state.loadingList = false;
        state.loading = false;
        state.projectLoadingError = action.payload;
      })
      // SUBSCRIBE
      .addCase(subscribe.rejected, (state, action) => {
        state.projectLoadingError = action.payload?.error;
      })
      // LOAD ONE PROJECT
      .addCase(loadOneProject.pending, state => {
        state.projectLoadingError = "";
        state.loading = true;
      })
      // --> takes the place of "loadOneProject.fulfilled" IF "loadOneProject.fulfilled" returned a payload
      .addCase(loadOneProjectHelper.fulfilled, (state, action) => {
        projectSuccessHandler(action.payload, state);
      })
      .addCase(loadOneProject.rejected, (state, action) => {
        state.loading = false;
        state.projectLoadingError = action.payload;
      })
      // RELOAD PROJECTS
      .addCase(reloadProject.pending, state => {
        state.loading = true;
      })
      .addCase(reloadProject.fulfilled, (state, action) => {
        projectSuccessHandler(action.payload, state);
      })
      .addCase(reloadProject.rejected, (state, action) => {
        state.loading = false;
        state.projectLoadingError = action.payload;
      })
      // UPDATE PROJECT
      .addCase(updateProject.pending, state => {
        state.loading = true;
        state.projectUpdateError = "";
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        projectSuccessHandler(action.payload, state);
      })
      .addCase(updateProject.rejected, (state, { payload }) => {
        state.loading = false;
        state.projectUpdateError = payload;
      });
  }
});

export default projectReducer.reducer;
