import { createAsyncThunk } from "@reduxjs/toolkit";

import { entities } from "Libs/platform";
import { getCommonError } from "Reducers/sliceFactory";
import { AsyncThunkOptionType } from "Reducers/types";

import { GetGitCommitParam } from "../types";

import type { Commit } from "@packages/client";

const getGitCommit = createAsyncThunk<
  Commit | undefined,
  GetGitCommitParam,
  AsyncThunkOptionType
>(
  "git/getGitCommit",
  async ({ projectId, envHeadCommit }, { rejectWithValue }) => {
    try {
      if (!projectId || !envHeadCommit) return;
      const data = await entities.Commit.get(projectId, envHeadCommit);
      return data;
    } catch (error) {
      return rejectWithValue(getCommonError(error));
    }
  }
);

export default getGitCommit;
