import { createAsyncThunk } from "@reduxjs/toolkit";

import { AsyncThunkOptionType } from "Reducers/types";

import type { Variable } from "@packages/client";

type GetVariableThunkPropType = {
  environmentId: string;
  projectId: string;
  organizationId: string;
};

export const getVariables = createAsyncThunk<
  Variable[],
  GetVariableThunkPropType,
  AsyncThunkOptionType<{ error: string }>
>(
  "app/project/environment/variables",
  async ({ environmentId, projectId }, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      //TODO needs to relax none required args in client
      const variables = await client.getEnvironmentVariables(
        projectId,
        encodeURIComponent(environmentId)
      );
      return variables;
    } catch (error: any) {
      return rejectWithValue({ error: error.message });
    }
  }
);
