import client, { entities } from "Libs/platform";
import { isJson } from "Libs/utils";
import { getAccountInfo } from "Reducers/app";
import { selectCurrentUserId } from "Reducers/app/selectors";
import { createAppAsyncThunk } from "Store/createAppAsyncThunk";

import { loadUserProfile } from "./loadUserProfile.thunk";

import type { AuthUser } from "@packages/client";

type UpdateProfileThunkProfileProps = {
  id: string;
  email?: string;
  picture?: File;
  ui_colorscheme?: string;
  marketing?: boolean;
};

const updateAccountsUserProfile = (props: UpdateProfileThunkProfileProps) => {
  const actions: Array<
    | ReturnType<typeof client.updateProfilePicture>
    | ReturnType<typeof entities.AccountsProfile.update>
  > = [];

  if (props.picture) {
    const formData = new FormData();
    formData.append("file", props.picture);
    actions.push(client.updateProfilePicture(props.id, formData));
  }

  if (props.email)
    actions.push(entities.AuthUser.updateEmailAddress(props.id, props.email));

  if (props.ui_colorscheme !== undefined || props.marketing !== undefined)
    actions.push(
      entities.AccountsProfile.update(props.id, {
        ui_colorscheme: props.ui_colorscheme,
        marketing: props.marketing
      })
    );

  return Promise.all(actions);
};

type UpdateAuthProfileProps = {
  id: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  country?: string;
};

const updateAuthUserProfile = (props: UpdateAuthProfileProps) => {
  if (
    props.first_name === undefined &&
    props.last_name === undefined &&
    props.username === undefined &&
    props.country === undefined
  )
    return undefined;

  const authUser = {
    first_name: props.first_name,
    last_name: props.last_name,
    username: props.username,
    country: props.country
  } as AuthUser;

  return entities.AuthUser.update(props.id, authUser);
};

type UpdateUserProfileProps = UpdateAuthProfileProps &
  UpdateProfileThunkProfileProps;

export const updateUserProfile = createAppAsyncThunk(
  "app/profile/update",
  async (
    props: UpdateUserProfileProps,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      await Promise.all([
        updateAccountsUserProfile(props),
        updateAuthUserProfile(props)
      ]);

      // Updating the current logged in user is going to cause problems because
      // the /me information is going to be off until the sync of accounts and
      // auth. Reloading just /me does not do the trick because it takes a bit
      // to sync so /users/me has to be loaded as well and merged into /me. This
      // avoid having stale data if we are upating the current logged in user
      const currentUserId = selectCurrentUserId(getState());
      if (currentUserId === props.id) dispatch(getAccountInfo());

      // In some cases this is not needed
      dispatch(loadUserProfile(props.id));
    } catch (error) {
      let err = error;
      if (isJson(err)) err = JSON.parse(err);
      return rejectWithValue(err as { error: string });
    }
  }
);
