import { createAsyncThunk } from "@reduxjs/toolkit";
import localForage from "localforage";

import logger from "Libs/logger";

import {
  FORAGE_KEY,
  getReadAnnouncements,
  updateAnnouncementHandler
} from "../";

import type { MarkAsReadPropsId, AnnouncementsState } from "../";
import type { AsyncThunkOptionType } from "Reducers/types";

export const markAnnouncementAsRead = createAsyncThunk<
  AnnouncementsState["announcements"],
  MarkAsReadPropsId,
  AsyncThunkOptionType
>(
  "announcements/mark_as_read",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const items = [...(await getReadAnnouncements()), id];
      await localForage.setItem(FORAGE_KEY, items);

      const updatedAnnouncements = updateAnnouncementHandler(
        getState().announcements,
        id,
        { read: true }
      );

      return updatedAnnouncements;
    } catch (error) {
      logger(error, {
        action: "ANNOUNCEMENTS_MARK_AS_READ"
      });
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
