import React from "react";

import { AddIcon } from "../../Icons";
import { Button } from "..";
import type { ButtonProps, RouterLinkProps, AnchorProps } from "..";
import { capitalize } from "../../__internal/_capitalize";

export type AddButtonProps = ButtonProps | RouterLinkProps | AnchorProps;

export const AddButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  AddButtonProps
>(({ children, ...props }, ref) => (
  // Typecasing ref as `any` allows us to avoid repeating the type
  // discrimination currently located on the Button component. This does not
  // affect the external types since PolymorphicProps is being used on the
  // forwardRef typing already
  <Button {...props} ref={ref as any} content="mixed">
    <AddIcon />
    {children
      ? typeof children === "string"
        ? capitalize(children)
        : children
      : "Add"}
  </Button>
));

AddButton.displayName = "AddButton";
