import * as Sentry from "@sentry/react";

import consoleConfig from "console_config";

// eslint-disable-next-line n/no-process-env
const isProduction = process.env.NODE_ENV === "production";
const ID_SENTRY_DNS = consoleConfig.ID_SENTRY_DNS;

const isLogSentryReport =
  isProduction && ID_SENTRY_DNS && consoleConfig.ENABLE_SENTRY;

if (isLogSentryReport) {
  Sentry.init({
    denyUrls: [/http?s:\/\/www\.googletagmanager\.com/i],
    beforeSend: function (event, hint) {
      // Groups errors that are similar but have different stack traces
      // Fall back to default grouping if other values do not exist
      const fingerprint =
        event.contexts?.Context?.action ||
        event.contexts?.Context?.sourceError ||
        hint.originalException ||
        "{{ default }}";
      event.fingerprint = [fingerprint];

      return event;
    },
    dsn: ID_SENTRY_DNS,
    tracesSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.breadcrumbsIntegration({ console: false })
    ]
  });
}

/**
 * Logs an error and sends it to Sentry, if enabled.
 *
 * @param {Error|Object} ex exception to log. It has to be an Error or an object containing a title or message properties
 * @param {Object} context any extra metadata
 */
function logException(ex, context = {}) {
  if (isLogSentryReport) {
    let exception = ex;
    let extra = {
      ...context
    };

    if (!(ex instanceof Error)) {
      const message =
        typeof ex === "object" ? ex.message || ex.title || ex.errMessage : ex;
      extra = {
        ...extra,
        sourceError: ex
      };
      exception = new Error(message);
    }
    Sentry.setTag("vendorId", consoleConfig.CONFIG_ID);
    Sentry.setContext("Context", extra);
    Sentry.captureException(exception);
  }

  /*eslint no-console:0*/
  window.console && console.error && console.error(ex);
}

export default logException;
