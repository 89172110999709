import { apiProxyRequest as request } from "Api/apiProxyRequest";
import { OrganizationProject } from "Api/organizations/projects/OrganizationProjects.types";

export const getOrganizationProject = async (
  organizationId: string,
  projectId: string
) =>
  await request<OrganizationProject>(
    `/organizations/${organizationId}/projects/${projectId}`,
    "GET"
  );
